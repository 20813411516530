import PropTypes from "prop-types";
import { Container, Paper, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useAuth } from "src/utils/auth";
import { SnackBarHandler } from "src/atoms";
import { AdminsOnly, NavAdminCenterMenu } from "src/organisms";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";

/**
 * Admin/profile pages template
 * @param {Element} children content of the pages
 * @param {String} snackState info, success, warning, error
 * @param {String} snackMsg text of the snack msg
 * @param {Boolean} snackOpen whether is snack msg opened/closed
 * @param {Function} setSnackOpen function to open/close informational snack message
 * @param {String} title title of the page
 * @returns {Component} Admin/profile pages template
 */
export function AdminPanel({
	children,
	snackState,
	snackMsg,
	snackOpen,
	setSnackOpen,
	title,
	adminsOnly
}) {
	const { token } = useAuth();
	const mobile = useMediaQuery("(max-width:1200px)");
	const navigate = useNavigate();
	const decoded = token && jwt_decode(token);
	const [role] = useState(decoded?.role);

	useEffect(() => {
		if (token === null || token === undefined) {
			navigate(`/auth/signin`, {
				replace: false,
			});
		}
	}, [navigate, token]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				my: "50px",
			}}
		>
			<Paper
				elevation={24}
				sx={{
					bgcolor: "rgba(255, 255, 255, 0.75)",
					borderRadius: "15px",
					p: "30px",
					gap: 8,
					minHeight: "60vh",
					width: "95%",
					maxWidth: "1600px",
					my: "50px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "flex-start",
					}}
					flexDirection={`${mobile ? `column` : `row`}`}
				>
					<NavAdminCenterMenu />
					{role !== "admin" && role !== "lecturer" && adminsOnly ? (
						<AdminsOnly />
					) : (
						<Box
							sx={{
								display: "flex",
								width: "100%",
								justifyContent: "space-evenly",
								flexDirection: "column",
								maxHeight: "700px",
							}}
						>
							<Typography
								variant="h2"
								color="initial"
								fontSize="28px"
								marginBottom="10px"
							>
								{title}
							</Typography>
							<Container
								sx={{ overflow: "hidden", overflowY: "scroll" }}
							>
								{children}
							</Container>
						</Box>
					)}
				</Box>
			</Paper>
			<SnackBarHandler
				snackState={snackState}
				snackMsg={snackMsg}
				snackOpen={snackOpen}
				setSnackOpen={setSnackOpen}
			/>
		</Box>
	);
}

AdminPanel.propTypes = {
	children: PropTypes.any,
	setSnackOpen: PropTypes.any,
	snackMsg: PropTypes.any,
	snackOpen: PropTypes.any,
	snackState: PropTypes.any,
};
