import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SPONSORS_QUERY = gql`
	query sponsors {
		sponsors {
			sponsor_id
			name
			img
			position
			height
			link
		}
	}
`;

/**
 * Homepage section with list of sponsors.
 * Each sponsor has its logo with specified height and can link to its web page.
 * Uses react-slick library to easily implement automatic slider.
 * @returns {Component} react-slick Slider
 */
export function HomepageSponsors() {
	var sliderSettings = {
		dots: true,
		infinite: true,
		speed: 2000,
		slidesToShow: 3,
		slidesToScroll: 1,
		initialSlide: 0,
		autoplay: true,
		autoplaySpeed: 5000,
		pauseOnHover: false,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};

	const sponsors_query = useQuery(SPONSORS_QUERY);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				mt: "10px",
				mb: "60px",
				scrollMarginTop: "120px",
				maxWidth: "90%",
				mx: "auto",
			}}
			id="Sponzori"
		>
			<Typography
				variant="h2"
				color="initial"
				textAlign="center"
				marginBottom="50px"
			>
				Podporují nás
			</Typography>

			<Slider {...sliderSettings}>
				{sponsors_query.data &&
					sponsors_query.data.sponsors.map((sponsor) => (
						<a
							key={sponsor.sponsor_id}
							alt="sponsor link"
							target="_blank"
							href={sponsor.link}
							rel="noreferrer"
						>
							<img
								alt={sponsor.name}
								src={sponsor.img}
								height={`${sponsor.height}px`}
							></img>
						</a>
					))}
			</Slider>
		</Box>
	);
}
