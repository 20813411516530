import { useState } from "react";
import {
	List,
	ListItem,
	IconButton,
	Drawer,
	Divider,
	Box,
} from "@mui/material/";
import MenuIcon from "@mui/icons-material/Menu";
import { NavbarLink } from "./NavbarLink";

const NAV_MAP = [
	{ text: "Domů", link: "Domu" },
	{ text: "Kurzy", link: "Kurzy" },
	{ text: "Aktuality", link: "Aktuality" },
	{ text: "O nás", link: "O nas" },
	{ text: "Lektoři", link: "Lektori" },
	{ text: "Galerie", link: "Galerie" },
	{ text: "Podporují nás", link: "Sponzori" },
	{ text: "Kontakty", link: "Kontakty" },
];

/**
 * Hamburger menu used on mobile devices to show page navigation on the left side of the page
 * @returns {Component} Drawer
 */
export function HamburgerMenu() {
	const [openDrawer, setOpenDrawer] = useState(false);

	return (
		<Box className="hideOnLarge">
			<Drawer
				anchor="left"
				onClose={() => setOpenDrawer(false)}
				open={openDrawer}
				PaperProps={{
					sx: {
						bgcolor: "#dec000",
						color: "black",
					},
				}}
				key="drawer"
			>
				<List
				>
					{NAV_MAP.map((navItem) => (
						<ListItem key={`${navItem.text}`} sx={{px: 0}} >
								<NavbarLink
									text={`${navItem.text}`}
									link={`${navItem.link}`}
                  width="100%"
									hamburger={true}
								/>
							<Divider
								variant="fullWidth"
								key={`divider-/${navItem}`}
							/>
						</ListItem>
					))}
				</List>
			</Drawer>
			{/* Since this is inside our toolbar we can push it to the end of the toolbar */}
			<IconButton
				onClick={() => setOpenDrawer(!openDrawer)}
				disableRipple
				sx={{
					color: "black",
					mx: "20px",
				}}
			>
				<MenuIcon
					sx={{
						color: "black",
						fontSize: "36px",
						fontWeight: "bold",
					}}
				/>
			</IconButton>
		</Box>
	);
}
