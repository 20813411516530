import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { AdminPanel } from "src/templates";
import {
	DataGrid,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const USERS = gql`
	query users {
		users {
			user_id
			first_name
			last_name
			email
			phone_number
			street
			house_number
			zip_code
			school
			gender
			partners_name
			partners_email
		}
	}
`;

/**
 * Admin content
 * Simple table to quickly show all users in table with posibility to export table to CSV and filter data and columns.
 * @returns {Component} MUI DataGrid
 */
export function UserManagement() {
	const [usersList, setUsersList] = useState([]);

	const { data } = useQuery(USERS);

	const columns = [
		{
			field: "last_name",
			headerName: "Příjmení",
			width: 150,
			editable: true,
		},
		{
			field: "first_name",
			headerName: "Jméno",
			width: 150,
			editable: true,
		},
		{
			field: "email",
			headerName: "E-mail",
			width: 200,
			editable: true,
		},
		{
			field: "phone_number",
			headerName: "Telefonní číslo",
			sortable: false,
			width: 120,
		},
		{
			field: "address",
			headerName: "Trvalé bydliště",
			sortable: false,
			width: 200,
			valueGetter: (params) =>
				`${params.row.street || ""} ${params.row.house_number || ""} ${
					params.row.city || ""
				} ${params.row.zip_code || ""}`,
		},
		{
			field: "school",
			headerName: "Škola",
			sortable: true,
			width: 200,
		},
		{
			field: "gender",
			headerName: "Pohlaví",
			sortable: true,
			width: 90,
			valueGetter: (params) =>
				`${
					params.row.gender === "male"
						? "Muž"
						: params.row.gender === "female"
						? "Žena"
						: "Pár"
				}`,
		},
		{
			field: "partners_name",
			headerName: "Partner",
			sortable: true,
			width: 120,
		},
		{
			field: "partners_email",
			headerName: "E-mail partnera",
			sortable: true,
			width: 200,
		},
	];

	useEffect(() => {
		if (data) {
			setUsersList(data.users);
		}
	}, [data]);

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<GridToolbarExport
					csvOptions={{
						fileName: "Uživatelé taneční studio Ká",
						delimiter: ";",
						utf8WithBom: true,
					}}
				/>
			</GridToolbarContainer>
		);
	}

	return (
		<AdminPanel title="Správa uživatelů" adminsOnly={true}>
			<Box sx={{ height: "500px" }}>
				<DataGrid
					components={{ Toolbar: CustomToolbar }}
					sx={{
						"button, MuiSwitch-root": { color: "secondary.main" },
					}}
					getRowId={(row) => row.user_id}
					rows={usersList}
					columns={columns}
					pageSize={10}
					rowsPerPageOptions={[10]}
					disableSelectionOnClick
					experimentalFeatures={{ newEditingApi: true }}
				/>
			</Box>
		</AdminPanel>
	);
}
