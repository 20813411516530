import { useEffect } from "react";

import { Box, Container, Button, Paper } from "@mui/material";
import gql from "graphql-tag";
import { useParams } from "react-router";
import { useMutation } from "@apollo/client";

const REJECT_MEMBER_MUTATION = gql`
	mutation refuseAltReservation($hash: String!) {
		refuseAltReservationRequest(hash: $hash) 
	}
`;
/**
 * Page to reject request to alternative event of ballroom dancing.
 * Page takes hash from URL and sends it to backend.
 * After validation the request to sign user to alternative date of ballroom dance event is rejected.
 * @returns {Component} Paper with containers
 */
export function RejectAltReservationPage() {
	const { hash } = useParams();

  const [rejectAltResRequest, {loading, data, error}] = useMutation(
    REJECT_MEMBER_MUTATION,
    {
      onCompleted: (data)=>{
        console.log(data.refuseAltReservationRequest)
      },
      onError: (error)=>{
        console.log(error.message)
      }
    },
  );

  useEffect(() => {
    rejectAltResRequest({ variables: { hash: hash } });
  }, [hash, rejectAltResRequest]);

	return (
		<Paper
			elevation={24}
			sx={{
				width: "100%",
				maxWidth: "600px",
				p: "50px",
				px: "10px",
				borderRadius: "20px",
				alignSelf: "center",
				mx: "auto",
				bgcolor: "Snow",
        my: "80px"
			}}
		>
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<Container
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{loading && (
            <div>
              <h1>Ověřování...</h1>
            </div>
          )}

          {error && (
            <div>
              <h1>Žádost se nepodařilo zamítnout</h1>
              <p>Neplatný odkaz nebo byla žádost již vyřízena</p>
              <Button
                variant="contained"
                size="large"
                color="primary"
                sx={{ mt: '1%' }}
                href="/"
              >
                Zpět na hlavní stránku
              </Button>
            </div>
          )}

					{data && (
            <div>
              <h1>Žádost úspěšně zamítnuta</h1>
              <p></p>
              <Button
                variant="contained"
                size="large"
                color="primary"
                sx={{ mt: '1%' }}
                href="/"
              >
                Zpět na hlavní stránku
              </Button>
            </div>
          )}
				</Container>
			</Box>
		</Paper>
	);
}
