import PropTypes from "prop-types"
import { gql, useMutation } from "@apollo/client";
import { DateTimePicker } from '@mui/x-date-pickers';
import '@mui/lab';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@mui/material";
import cs from "date-fns/locale/cs";
import { Form, Formik } from "formik";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";

import * as yup from "yup";
import { FormikField } from "src/molecules";

const EDIT_EVENT = gql`
	mutation updateEvent(
		$event_id: Int!
		$event_start: String!
		$duration: Int!
		$capacity: Int!
		$min_participation: Int!
		$comment: String
	) {
		updateEvent(
			event_id: $event_id
			event_start: $event_start
			duration: $duration
			capacity: $capacity
			min_participation: $min_participation
			comment: $comment
		)
	}
`;

const schema = yup.object().shape({
	duration:  yup.number().required("Doba trvání je povinný údaj"),
	capacity:  yup.number().required("Kapacita je povinný údaj"),
	min_participation:  yup.number().required("Minimální účast je povinný údaj"),
});

/**
 * Dialog to edit events data like date, time, comment etc.
 * @param {Boolean} open opened/closed dialog
 * @param {Function} setOpen open/close dialog
 * @param {Function} setSnackState function to set snack state Success, Warning, Error, Info
 * @param {Function} setSnackMsg function to set snack msg
 * @param {Function} setSnackOpen function to open/close informational snack message
 * @param {Function} refetch refetch new data from database
 * @param {Object} data selected event data
 * @returns {Component} Dialog
 */
export function EditEventDialog({
	open,
	setOpen,
	setSnackState,
	setSnackMsg,
	setSnackOpen,
	refetch,
	data,
}) {
	const [date, setDate] = useState(new Date(null));

	useEffect(() => {
		const czechDate = moment(data.event_start, "DD. MM. YYYY hh:mm");
		setDate(czechDate.toDate());
	}, [data.event_start, data.event_date]);

	const [editEventRequest] = useMutation(EDIT_EVENT, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.updateEvent);
			setSnackOpen(true);
			handleClose();
			refetch();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleEditEvent = useCallback(
		(values) => {
			editEventRequest({
				variables: {
					event_id: parseInt(data.event_id),
					event_start: `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(
						2,
						"0"
					)}-${date.getDate().toString().padStart(2, "0")}${" "}
          ${date.getHours().toString()
						.padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`,
					capacity: values.capacity,
					duration: values.duration,
					min_participation: values.min_participation,
					comment: values.comment,
				},
			});
		},
		[data.event_id, date, editEventRequest]
	);

	const handleClose = () => {
		setOpen(false);
		refetch();
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="xs"
			fullWidth={true}
		>
			<Formik
				onSubmit={handleEditEvent}
				initialValues={{
					duration: data.duration,
					capacity: data.capacity,
					min_participation: data.min_participation,
					comment: data.comment
				}}
				validationSchema={schema}
				validateOnBlur={false}
				enableReinitialize={true}
			>
				<Form>
					<DialogTitle id="alert-dialog-title">
						{"Upravit lekci"}
					</DialogTitle>
					<DialogContent>
						<LocalizationProvider
							dateAdapter={AdapterDateFns}
							locale={cs}
						>
							<DateTimePicker
								mask="__. __. ____ HH:mm"
								label="Datum"
								value={date}
								onChange={(newValue) => {
									setDate(newValue);
								}}
								renderInput={(params) => (
									<TextField
										color="secondary"
										size="small"
										sx={{
											mt: "15px",
										}}
										{...params}
									/>
								)}
							/>
						</LocalizationProvider>
						<FormikField
							id="duration"
							name="duration"
							label="Doba trvání (v minutách)"
							type="number"
							variant="outlined"
							as="TextField"
							color="secondary"
							size="small"
							sx={{ mt: "15px" }}
						/>
						<FormikField
							id="capacity"
							name="capacity"
							label="Kapacita"
							type="number"
							variant="outlined"
							as="TextField"
							color="secondary"
							size="small"
							sx={{ mt: "15px" }}
						/>
						<FormikField
							id="min_participation"
							name="min_participation"
							label="Minimální účast"
							type="number"
							variant="outlined"
							as="TextField"
							color="secondary"
							size="small"
							sx={{ mt: "15px" }}
						/>
						<FormikField
							id="comment"
							name="comment"
							label="Komentář"
							type="text"
							variant="outlined"
							as="TextField"
							color="secondary"
							size="small"
							sx={{ mt: "15px" }}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							color="secondary"
							variant="contained"
							autoFocus
							type="submit"
						>
							Uložit
						</Button>
						<Button onClick={handleClose} color="secondary">
							Zrušit
						</Button>
					</DialogActions>
				</Form>
			</Formik>
		</Dialog>
	);
}

EditEventDialog.propTypes = {
	data: PropTypes.shape({
		capacity: PropTypes.any,
		comment: PropTypes.any,
		duration: PropTypes.any,
		event_date: PropTypes.any,
		event_id: PropTypes.any,
		event_start: PropTypes.any,
		min_participation: PropTypes.any,
		updateEvent: PropTypes.any
	}),
	open: PropTypes.any,
	refetch: PropTypes.func,
	setOpen: PropTypes.func,
	setSnackMsg: PropTypes.func,
	setSnackOpen: PropTypes.func,
	setSnackState: PropTypes.func
}
