import PropTypes from "prop-types"
import { Button, Link, Typography, Paper, Box } from "@mui/material";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";

/**
 * Component in News list page to display preview of the news which is displayed when user wants to see list of all news.
 * It's wider than in homepage with cover on the left.
 * @param {String} title news title
 * @param {String} description news description
 * @param {String} cover base64 cover image
 * @param {String} date date of news creation
 * @param {Number} newsId id of the news 
 * @returns {Component} Paper
 */
export function NewsListCard({
	title,
	description,
	cover,
	date,
	newsId,
}) {
	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	return (
		<Paper
			elevation={8}
			sx={{
				display: "flex",
				flexDirection: "row",
				borderRadius: "5px",
				alignItems: "flex-start",
				p: "10px",
				maxWidth: "1200px",
				width: "95%",
				px: "20px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					backgroundImage: `url(${cover})`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center",
					minWidth: "200px",
					height: "200px",
					mr: "20px",
					borderRadius: "5px",
				}}
				className="hideOnSmall"
			></Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
					alignSelf: "stretch",
				}}
			>
				<Box sx={{ display: "flex", justifyContent: "space-between" }}>
					<Typography variant="h6" color="initial" fontWeight="bold">
						{title}
					</Typography>
				</Box>
				<Typography variant="subtitle1" color="initial">
					{date}
				</Typography>
				<Typography
					variant="body1"
					color="initial"
					sx={{
						overflow: "hidden",
						textOverflow: "ellipsis",
						display: "-webkit-box",
						WebkitLineClamp: "3",
						LineClamp: "3",
						WebkitBoxOrient: "vertical",
						"&>*": {
							margin: "0px",
						},
					}}
					dangerouslySetInnerHTML={createMarkup(
						draftToHtml(JSON.parse(description))
					)}
				></Typography>
				<Button
					variant="contained"
					color="secondary"
					component={Link}
					href={`/clanky/${newsId}`}
					sx={{ ml: "auto", mt: "auto" }}
				>
					Celý článek
				</Button>
			</Box>
		</Paper>
	);
}

NewsListCard.propTypes = {
	cover: PropTypes.any,
	date: PropTypes.any,
	description: PropTypes.any,
	newsId: PropTypes.any,
	title: PropTypes.any
}
