import React from "react";
import { AppBar, Toolbar, Box, useMediaQuery } from "@mui/material";
import { HamburgerMenu, NavbarLink } from "src/atoms";
import { useAuth } from "src/utils/auth";
import { NavDropDownUser, NavSignButtons } from "src/organisms";

/**
 * Navigation Bar/Header
 * @returns {Component} MUI AppBar
 */
export function Navigation() {
	const { token, signout } = useAuth();
	const anchorRef = React.useRef(null);
	const matches = useMediaQuery("(min-width:600px)");

	return (
		<AppBar
			position="sticky"
			sx={{
				pl: "5px",
				pr: "10px",
				display: "flex",
				bgcolor: "primary.main",
			}}
		>
			<Toolbar disableGutters={true}>
			<HamburgerMenu />
				<Box className={"hideOnSmall"}>
					<a href="/">
						<img alt="logo dancers" src="/img/logo_black.svg" />
					</a>
				</Box>
				<Box
					sx={{
						my: "auto",
						display: "flex",
						justifyContent: "space-around",
						flexGrow: "1",
					}}
					className={"hideOnSmall"}
				>
					<NavbarLink text="Kurzy" link="Kurzy" />
					<NavbarLink text="Aktuality" link="Aktuality" />
					<NavbarLink text="O nás" link="O nas" />
					<NavbarLink text="Lektoři" link="Lektori" />
					<NavbarLink text="Galerie" link="Galerie" />
					<NavbarLink text="Podporují nás" link="Sponzori" />
					<NavbarLink text="Kontakt" link="Kontakty" />
				</Box>
				<Box
					sx={{
						my: "auto",
						ml: "auto",
						display: "flex",
						justifyContent: "flex-end",
						py: '20px'
					}}
				>
					{token ? (
						<NavDropDownUser
							anchorRef={anchorRef}
							token={token}
							signout={signout}
						/>
					) : (
						<NavSignButtons
							anchorRef={anchorRef}
							matches={matches}
						/>
					)}
				</Box>
			</Toolbar>
		</AppBar>
	);
}
