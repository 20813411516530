import { Box } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
	HomepageAboutUs,
	HomepageContact,
	HomepageCourses,
	HomepageGallery,
	HomepageLecturers,
	HomepageNews,
	HomepageServices,
	HomepageSponsors,
} from "src/organisms";

/**
 * Component to show whole Homepage
 * @returns {Component} Homepage
 */
export function Homepage() {
	let { state } = useLocation();

	useEffect(() => {
		setTimeout(() => {
			if (state && state.ref) {
				const pointToScroll = document.getElementById(state.ref);
				pointToScroll.scrollIntoView({ behaviour: "smooth" });
				window.history.replaceState({}, document.title);
			}
		}, 300);
	}, [state]);

	return (
		<Box id="Domu" sx={{ scrollMarginTop: "200px" }}>
			<HomepageCourses />
			<HomepageNews />
			<HomepageAboutUs />
			<HomepageServices />
			<HomepageLecturers />
			<HomepageGallery />
			<HomepageSponsors />
			<HomepageContact />
		</Box>
	);
}
