import PropTypes from "prop-types"
import React from 'react';
import { useField } from 'formik';

import { Field } from './Field';

/**
 * Input Field enriched with formik functions and data. Without it, error messages etc. must be set manually.
 * @param {String} name Name of input field
 * @param {String} as TextField, FormControl, Checkbox, Select
 * @param {Class} props other properties/classes defined in Component 
 * @returns {Component} Field
 */
export function FormikField({ name, as, ...props }) {
  const [field, meta] = useField(name);

  const error = meta.touched && meta.error;

  return <Field {...field} error={error} {...props} as={as} />;
}

FormikField.propTypes = {
  as: PropTypes.any,
  name: PropTypes.any
}
