import PropTypes from "prop-types"
import { Link, MenuItem } from "@mui/material";
/**
 * Component for Items in header navigation DropDown menu. 
 * @param {String} href link to the navigated page 
 * @param {Element} children Usually text, could be other component or element
 * @param {Class} props other properties/classes defined in Component
 * @returns {Component} MenuItem
 */
export function DropDownMenuItem({ href, children, ...props }) {
	return (
		<MenuItem
			component={Link}
			href={href}
			onMouseEnter={(e) =>
				(e.target.style.backgroundColor = "rgb(255, 255, 255)")
			}
			onMouseLeave={(e) => (e.target.style.backgroundColor = "#FFD700")}
			{...props}
			sx={{ 
        borderRadius: "5px",
        transitionDuration: '500ms'
      }}
		>
			{children}
		</MenuItem>
	);
}

DropDownMenuItem.propTypes = {
	children: PropTypes.element,
	href: PropTypes.string
}
