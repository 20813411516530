import {
	Button,
	Link,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { AdminPanel } from "src/templates";
import { ArrowForwardIos } from "@mui/icons-material";
import { gql, useQuery } from "@apollo/client";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";
import { convertFromRaw } from "draft-js";

const CONTENT_LIST = gql`
	query allContent {
		allContent {
			content_id
			name
			text
		}
	}
`;

/**
 * Admin section content with table of editable web page content.
 * Editable content is defined in DB and its ID is assigned to HTML content on webpage.
 * Every content has its own assigned space.
 * @return {Component} Table with editable web content.
 */
export function ContentManagement() {
	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	const { data } = useQuery(CONTENT_LIST);

	return (
		<AdminPanel title="Webový obsah" adminsOnly={true}>
			<TableContainer sx={{ maxHeight: "600px" }}>
				<Table
					sx={{
						borderCollapse: "separate",
						overflow: "hidden",
						overflowY: "scroll",
						overflowX: "scroll",
					}}
					aria-label="simple table"
					size="small"
				>
					<TableHead>
						<TableRow>
							<TableCell>Název</TableCell>
							<TableCell>Text</TableCell>
							<TableCell align="right"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data &&
							data.allContent !== null &&
							data.allContent.map((content, index) => (
								<TableRow key={index}>
									<TableCell align="left">
										<div
											dangerouslySetInnerHTML={createMarkup(
												draftToHtml(
													JSON.parse(content.name)
												)
											)}
										></div>
									</TableCell>
									<TableCell
										sx={{
											whiteSpace: "nowrap",
											maxWidth: "400px",
											overflow: "hidden",
											textOverflow: "ellipsis",
										}}
									>
										{convertFromRaw(
											JSON.parse(content.text)
										).getPlainText()}
									</TableCell>
									<TableCell align="center">
										<Button
											variant="contained"
											size="small"
											component={Link}
											endIcon={<ArrowForwardIos />}
											aria-label="go to detail"
											href={`webovy-obsah/${content.content_id}`}
										>
											Upravit
										</Button>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</AdminPanel>
	);
}
