import PropTypes from "prop-types"
import React from "react";
import {
	IconButton,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogContentText,
	DialogActions,
	Typography,
	Tooltip,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import ModalImage from "react-modal-image";

/**
 * Dialog to show information about proper dressing up to ballroom dancing.
 * @param {Element} icon Icon element
 * @param {String} tooltip tooltip text
 * @param {String} text rich text inside the dialog
 * @param {String} title title of the dialog
 * @param {String} img base64 img
 * @param {String} imgThumb base64 img thumbnail
 * @returns {Component} Dialog
 */
export function DressInfoDialog({
	icon,
	tooltip,
	text,
	title,
	img,
	imgThumb,
}) {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<>
			<Tooltip title={tooltip}>
				<IconButton
					color="inherit"
					aria-label="icon button"
					onClick={() => handleOpen()}
					size="small"
					sx={[
						{
							borderRadius: "100%",
							border: "2px black solid",
							p: "10px",
							mx: "5px",
						},
						{
							"&:hover": {
								color: "#FFD700",
								backgroundColor: "#0D4281 !important",
							},
						},
					]}
				>
					{icon}
				</IconButton>
			</Tooltip>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" sx={{ display: "flex" }}>
					<Typography variant="h4" color="initial">
						{title}
					</Typography>
					<IconButton sx={{ ml: "auto" }} onClick={handleClose}>
						<Close />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{text}
					</DialogContentText>
					<ModalImage
						className="dressInfoImage"
						small={imgThumb}
						large={img}
						alt="Správné oblečení"
						loading="lazy"
					/>
				</DialogContent>
				<DialogActions></DialogActions>
			</Dialog>
		</>
	);
}

DressInfoDialog.propTypes = {
	icon: PropTypes.any,
	img: PropTypes.any,
	imgThumb: PropTypes.any,
	text: PropTypes.any,
	title: PropTypes.any,
	tooltip: PropTypes.any
}
