import {
	Paper,
	Typography,
	Button,
	Stack,
	IconButton,
	Card,
	CardContent,
	CardActions,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { AdminPanel, NewArticleForm } from "src/templates";
import {
	ArrowForwardIos,
	CheckCircleOutline,
	Delete,
	HighlightOffOutlined,
	Upload,
} from "@mui/icons-material";
import gql from "graphql-tag";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";
import draftToHtml from "draftjs-to-html";
import DOMPurify from "dompurify";
import { DeleteDialog } from "../Dialogs/DeleteDialog";
import { EditNewsDialog } from "../Dialogs/EditNewsDialog";

const HISTORY_LIST = gql`
	query news {
		news {
			news_id
			title
			date
		}
	}
`;

const SELECTED_ARTICLE = gql`
	query newsById($news_id: Int!) {
		newsById(news_id: $news_id) {
			news_id
			title
			date
			article
			cover
			published
			timestamp
		}
	}
`;

const DELETE_ARTICLE = gql`
	mutation deleteArticle($news_id: Int!) {
		deleteArticle(news_id: $news_id)
	}
`;

const UPLOAD_COVER = gql`
	mutation editCover($news_id: Int!, $cover: String!) {
		editCover(news_id: $news_id, cover: $cover)
	}
`;

const SWITCH_PUBLICATION = gql`
	mutation publishArticle($news_id: Int!, $published: Boolean!) {
		publishArticle(news_id: $news_id, published: $published)
	}
`;
/**
 * Admin section to create, edit or delete articles.
 * Consists of 3 parts: Form to Create new article with picture and its preview, 
 * List of all old articles to select and editable detail of an article.
 * @return {Component} Form, List and Preview
 */
export function NewsManagement() {
	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	const [selectedArticle, setSelectedArticle] = useState();

	let filesBase64 = [];

	const historyListQuery = useQuery(HISTORY_LIST);

	const [selectedArticleQuery, { data, refetch }] =
		useLazyQuery(SELECTED_ARTICLE);

	const handleArticlePick = useCallback(
		(news_id) => {
			setSelectedArticle(news_id);
			selectedArticleQuery({
				variables: {
					news_id: news_id,
				},
			});
		},
		[selectedArticleQuery]
	);

	const [uploadImageRequest] = useMutation(UPLOAD_COVER, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.editCover);
			setSnackOpen(true);
			refetch();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const uploadImage = useCallback(
		(news_id, cover) => {
			uploadImageRequest({
				variables: {
					news_id: news_id,
					cover: cover,
				},
			});
		},
		[uploadImageRequest]
	);

	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	const [deleteArticleRequest] = useMutation(DELETE_ARTICLE, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.deleteArticle);
			setSnackOpen(true);
			historyListQuery.refetch();
			setTimeout(
				handleArticlePick(historyListQuery.data.news[0].news_id),
				5
			);
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const [switchPublicationRequest] = useMutation(SWITCH_PUBLICATION, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.publishArticle);
			setSnackOpen(true);
			historyListQuery.refetch();
			setTimeout(
				handleArticlePick(historyListQuery.data.news[0].news_id),
				5
			);
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const deleteArticle = useCallback(
		(news_id) => {
			deleteArticleRequest({
				variables: {
					news_id: news_id,
				},
			});
		},
		[deleteArticleRequest]
	);

	const handleSwitchPublication = useCallback(
		(news_id, published) => {
			switchPublicationRequest({
				variables: {
					news_id: news_id,
					published: published,
				},
			});
		},
		[switchPublicationRequest]
	);

	const handleChange = (e) => {
		let allfiles = [];

		for (let i = 0; i < e.target.files.length; i++) {
			if (e.target.files[i].size > 5e5) {
				setSnackState("error");
				setSnackMsg(
					"Prosím nahrajte obrázek s velikostí menší než 0,5 MB."
				);
				setSnackOpen(true);
				return false;
			}
			allfiles.push(e.target.files[i]);
		}

		getBase64(e.target.files[0]);
	};

	const getBase64 = async (file) => {
		return new Promise((resolve) => {
			let baseURL = "";
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				baseURL = reader.result;
				filesBase64.push(baseURL);
				resolve(baseURL);
			};
		}).then(async (res) => {
			uploadImage(selectedArticle, res);
		});
	};

	return (
		<AdminPanel
			title="Aktuality"
			snackState={snackState}
			snackMsg={snackMsg}
			snackOpen={snackOpen}
			setSnackOpen={setSnackOpen}
			adminsOnly={true}
		>
			<Box sx={{ display: "flex", flexDirection: "row" }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						width: "50%",
					}}
				>
					<NewArticleForm historyListQuery={historyListQuery} />
					<Box>
						<Typography variant="h6" color="initial">
							Historie
						</Typography>
						<Stack
							spacing={2}
							sx={{
								ml: "10px",
								overflow: "hidden",
								overflowY: "scroll",
								maxHeight: "200px",
								p: "5px",
							}}
						>
							{historyListQuery.data &&
								historyListQuery.data.news.map(
									(article, index) => (
										<Paper
											key={index}
											elevation={4}
											sx={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												bgcolor:
													selectedArticle ===
														article.news_id &&
													"primary.main",
											}}
										>
											<Typography
												variant="subtitle1"
												color="initial"
												sx={{
													ml: "10px",
													minWidth: "90px",
												}}
											>
												{article.date}
											</Typography>
											<Typography
												variant="body1"
												color="initial"
												sx={{ ml: "10px" }}
											>
												{article.title}
											</Typography>
											<IconButton
												sx={{ ml: "auto" }}
												onClick={() => {
													handleArticlePick(
														article.news_id
													);
												}}
											>
												<ArrowForwardIos />
											</IconButton>
										</Paper>
									)
								)}
						</Stack>
					</Box>
				</Box>
				{data && (
					<Card sx={{ width: "45%", ml: "5%", mb: "auto" }}>
						<Box
							sx={{
								width: "100%",
								height: "150px",
								backgroundImage: `url(${data.newsById.cover})`,
								backgroundPosition: "center",
								backgroundSize: "cover",
								backgroundRepeat: "no-repeat",
								position: "relative",
							}}
						>
							<Box
								sx={{
									position: "absolute",
									top: "5px",
									right: "5px",
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-evenly",
								}}
							>
								<IconButton
									sx={{
										bgcolor: "secondary.main",
										color: "white",
										borderRadius: "5px",
										mx: "5px",
									}}
									size="small"
									onClick={() => {
										setOpenDeleteDialog(true);
									}}
								>
									<Delete />
								</IconButton>
								<IconButton
									sx={{
										bgcolor: "secondary.main",
										color: "white",
										borderRadius: "5px",
										mx: "5px",
									}}
									size="small"
								>
									<label
										style={{
											width: "100%",
											height: "100%",
											cursor: "pointer",
										}}
										onChange={handleChange}
										htmlFor="editImg"
									>
										<input
											accept="image/*"
											id="editImg"
											type="file"
											hidden
										/>
										<Upload />
									</label>
								</IconButton>
							</Box>
						</Box>

						<CardContent>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									mb: "15px",
									alignItems: "center",
								}}
							>
								{data.newsById.published === true ? (
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
										}}
									>
										<Typography
											variant="subtitle1"
											color="initial"
										>
											Zveřejněn{" "}
										</Typography>
										<CheckCircleOutline color="success" />
									</Box>
								) : (
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
										}}
									>
										<Typography
											variant="subtitle1"
											color="initial"
										>
											Nezveřejněn{" "}
										</Typography>
										<HighlightOffOutlined color="error" />
									</Box>
								)}
								<Button
									variant="contained"
									size="small"
									color="primary"
									onClick={() => {
										handleSwitchPublication(
											data.newsById.news_id,
											!data.newsById.published
										);
									}}
								>
									{data.newsById.published === true
										? "Zrušit zveřejnění"
										: "Zveřejnit"}
								</Button>
							</Box>
							<Typography variant="h5" color="initial">
								{data.newsById.title}
							</Typography>
							<Typography variant="subtitle1" color="initial">
								{data.newsById.date}
							</Typography>
							<Typography
								variant="body1"
								color="initial"
								dangerouslySetInnerHTML={createMarkup(
									draftToHtml(
										JSON.parse(data.newsById.article)
									)
								)}
							></Typography>
						</CardContent>
						<CardActions>
							<Button
								variant="contained"
								color="primary"
								size="large"
								sx={{ ml: "auto", mt: "auto" }}
								onClick={() => {
									handleArticlePick(selectedArticle);
									setOpenEditDialog(true);
								}}
							>
								Upravit
							</Button>
						</CardActions>
					</Card>
				)}
			</Box>
			{data && (
				<EditNewsDialog
					open={openEditDialog}
					setOpen={setOpenEditDialog}
					data={data}
					setSnackState={setSnackState}
					setSnackMsg={setSnackMsg}
					setSnackOpen={setSnackOpen}
					refetch={refetch}
				/>
			)}
			{data && (
				<DeleteDialog
					open={openDeleteDialog}
					setOpen={setOpenDeleteDialog}
					id={selectedArticle}
					deleteFunction={() => {
						deleteArticle(data.newsById.news_id);
					}}
				/>
			)}
		</AdminPanel>
	);
}
