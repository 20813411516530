import { NewsCard } from "src/molecules";
import { Grid, Typography, Button, Link } from "@mui/material";
import { Box } from "@mui/system";
import { route } from "src/Routes";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const NEWS_LIST = gql`
	query homepageNews {
		homepageNews {
			news_id
			title
			date
			article
			cover
		}
	}
`;
/**
 * Homepage section with list of articles.
 * Each article has photo, name, date and description.
 * Lists 4 newest articles, the rest can be viewed after clicking on button "view all".
 * Whole article button shows detail of the article with full text.
 * @returns {Component} MUI Grid of News Cards - Homepage News
 */
export function HomepageNews() {
	const NewsQuery = useQuery(NEWS_LIST);

	return (
		<Box
			sx={{
				my: "50px",
				mx: "auto",
				width: "95%",
				maxWidth: "1800px",
				scrollMarginTop: "120px",
			}}
			id="Aktuality"
		>
			<Typography variant="h2" color="initial">
				Aktuality
			</Typography>
			<Grid
				container
				spacing={4}
				sx={{
					mb: "20px",
					display: "flex",
					alignItems: "stretch",
				}}
				id="CommunityGrid"
			>
				{NewsQuery.data &&
					NewsQuery.data.homepageNews.map((article) => (
						<NewsCard
							key={article.news_id}
							title={article.title}
							description={article.article}
							date={article.date}
							cover={article.cover}
							newsId={article.news_id}
						/>
					))}
			</Grid>
			<Button
				variant="contained"
				color="primary"
				sx={{
					filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
					width: "fit-content",
					ml: "auto",
					display: "flex",
				}}
				component={Link}
				href={route.newsPage()}
			>
				Další články
			</Button>
		</Box>
	);
}
