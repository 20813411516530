import {
	Button,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";
import { AdminPanel } from "src/templates";
import { useNavigate, useParams } from "react-router-dom";
import {
	ArrowBackIos,
	ArrowForwardIos,
	CheckCircleOutline,
	HighlightOff,
	PivotTableChart,
	QrCode,
} from "@mui/icons-material";
import { convertFromRaw } from "draft-js";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import {
	ConfirmEventNotHeldDialog,
	EditEventDialog,
	NewEventDialog,
	PrintQRCodesDialog,
} from "src/organisms";
import { useCallback } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";

const COURSE_EVENTS_LIST = gql`
	query seasonEvents($season_id: Int!) {
		eventsBySeasonId(season_id: $season_id) {
			event_id
			event_start
			event_end
			event_date
			duration
			capacity
			min_participation
			reservation_count
			held
			isIn7Hours
			comment
			men_count
			women_count
			pairs_count
		}
	}
`;

const COURSE_INFO = gql`
	query courseById($course_id: Int!) {
		courseById(course_id: $course_id) {
			course_id
			name
		}
	}
`;

const SWITCH_HELD_EVENT = gql`
	mutation switchHeldEvent($event_id: Int!, $held: Boolean!) {
		switchHeldEvent(event_id: $event_id, held: $held)
	}
`;

const DAY_MAP = [
	"neděle",
	"pondělí",
	"úterý",
	"středa",
	"čtvrtek",
	"pátek",
	"sobota",
];

/**
 * Admin section content with events of selected season.
 * Admin and lecturers can edit capacity, date, comment and other parameters of event, or
 * add a new event.
 * They also can export whole season to excel table and print QR codes for certain period of time to PDF. 
 * By clicking on Held icon in table lecturer and admin can cancel an event and sign out other users.
 * @returns {Component} Table with events of selected season 
 */
export function CourseEventsManagement() {
	const exportTableRef = useRef(null);

	const { course_id, season_id } = useParams();
	const history = useNavigate();

	const [openConfirmHeldDialog, setOpenConfirmHeldDialog] = useState(false);
	const [editEventDialogOpen, setEditEventDialogOpen] = useState(false);
	const [newEventDialogOpen, setNewEventDialogOpen] = useState(false);
	const [editEventData, setEditEventData] = useState({});
	const [printQRCodesDialog, setPrintQRCodesDialog] = useState(false);

	const [held, setHeld] = useState();
	const [event_id, setEvent_id] = useState();

	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const { data: eventsData, refetch: refetchEvents } = useQuery(
		COURSE_EVENTS_LIST,
		{
			variables: {
				season_id: parseInt(season_id),
			},
		}
	);
	const { data: courseData } = useQuery(COURSE_INFO, {
		variables: {
			course_id: parseInt(course_id),
		},
	});

	const [setEventIsHeldRequest] = useMutation(SWITCH_HELD_EVENT, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.switchHeldEvent);
			setSnackOpen(true);
			refetchEvents();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleEventIsHeld = useCallback(
		(event_id, held) => {
			setEventIsHeldRequest({
				variables: {
					event_id: event_id,
					held: held,
				},
			});
		},
		[setEventIsHeldRequest]
	);

	return (
		<AdminPanel
			snackState={snackState}
			snackMsg={snackMsg}
			snackOpen={snackOpen}
			setSnackOpen={setSnackOpen}
			adminsOnly={true}
		>
			<Box sx={{ display: "flex", flexDirection: "row", mb: "20px" }}>
				<IconButton
					sx={{ my: "auto" }}
					size="small"
					disableRipple={true}
					onClick={() => {
						history(-1);
					}}
				>
					<ArrowBackIos />
				</IconButton>
				{courseData && (
					<Typography variant="h2" color="initial" fontSize="28px">
						{convertFromRaw(
							JSON.parse(courseData.courseById.name)
						).getPlainText()}
					</Typography>
				)}
			</Box>
			<TableContainer sx={{ height: "100%" }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						ml: "auto",
						justifyContent: "flex-end",
						"& > span": { mr: "10px" },
					}}
				>
					<Tooltip title="Export QR Kódů">
						<IconButton
							variant="contained"
							color="secondary"
							onClick={() => {
								setPrintQRCodesDialog(true);
							}}
						>
							<QrCode />
						</IconButton>
					</Tooltip>
					<DownloadTableExcel
						filename="Rozvrh hodin"
						sheet="Rozvrh hodin"
						currentTableRef={exportTableRef.current}
					>
						<Tooltip title="Export do excelu">
							<IconButton
								variant="contained"
								color="secondary"
								sx={{
									borderRadius: "5px",
									ml: "10px",
									display: "flex",
								}}
							>
								<PivotTableChart />
							</IconButton>
						</Tooltip>
					</DownloadTableExcel>
					<Button
						variant="contained"
						color="secondary"
						sx={{
							mr: "10px",
							display: "flex",
						}}
						onClick={() => {
							setNewEventDialogOpen(true);
						}}
					>
						Nová lekce
					</Button>
				</Box>

				<Table
					sx={{
						borderCollapse: "separate",
						overflow: "hidden",
						overflowY: "scroll",
						overflowX: "scroll",
					}}
					aria-label="simple table"
					size="small"
					ref={exportTableRef}
				>
					<TableHead>
						<TableRow>
							<TableCell>Datum</TableCell>
							<TableCell align="center">Začátek</TableCell>
							<TableCell align="center">Konec</TableCell>
							<TableCell align="center">Doba trvání</TableCell>
							<TableCell align="center">
								Minimální účast
							</TableCell>
							{courseData &&
								(courseData.courseById.course_id < 2 ? (
									<>
										<TableCell align="center">
											Kapacita ženy
										</TableCell>
										<TableCell align="center">
											Kapacita muži
										</TableCell>
									</>
								) : (
									<TableCell align="center">
										Kapacita
									</TableCell>
								))}
							<TableCell align="center">Koná se</TableCell>
							<TableCell align="center">Komentář</TableCell>
							<TableCell align="right"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{eventsData &&
							eventsData.eventsBySeasonId !== null &&
							eventsData.eventsBySeasonId.map((event, index) => (
								<TableRow key={index}>
									<TableCell align="left">
										{
											DAY_MAP[
												new Date(
													parseInt(event.event_date)
												).getUTCDay()
											]
										}{" "}
										{event.event_start.match(
											/\d{0,2}\.\s\d{0,2}\.\s\d{0,4}/
										)}
									</TableCell>
									<TableCell align="center">
										{
											event.event_start.match(
												/\s(\d*:\d*)/g
											)[0]
										}
									</TableCell>
									<TableCell align="center">
										{
											event.event_end.match(
												/\s(\d*:\d*)/g
											)[0]
										}
									</TableCell>
									<TableCell align="center">
										{event.duration}
									</TableCell>
									<TableCell align="center">
										{event.min_participation}
									</TableCell>
									{courseData &&
										(courseData.courseById.course_id ===
										1 ? (
											<>
												<TableCell align="center">
													{event.women_count}/
													{event.capacity}
												</TableCell>
												<TableCell align="center">
													{event.men_count}/
													{event.capacity}
												</TableCell>
											</>
										) : courseData.courseById.course_id ===
										  2 ? (
											<TableCell align="center">
												{event.pairs_count}/
												{event.capacity}
											</TableCell>
										) : (
											<TableCell align="center">
												{event.reservation_count}/
												{event.capacity}
											</TableCell>
										))}
									<TableCell align="center">
										{event.held === false ? (
											<IconButton
												disabled
												onClick={() => {
													handleEventIsHeld(
														event.event_id,
														true
													);
												}}
											>
												<HighlightOff
													sx={{
														color: "response.error",
													}}
												/>
											</IconButton>
										) : (
											<IconButton
												onClick={() => {
													setOpenConfirmHeldDialog(
														true
													);
													setEvent_id(event.event_id);
													setHeld(false);
												}}
											>
												<CheckCircleOutline
													sx={{
														color: "response.success",
													}}
												/>
											</IconButton>
										)}
									</TableCell>

									<TableCell align="center">
										{event.comment}
									</TableCell>
									<TableCell align="center">
										<Button
											variant="contained"
											size="small"
											endIcon={<ArrowForwardIos />}
											onClick={() => {
												setEditEventDialogOpen(true);
												setEditEventData(event);
											}}
										>
											Upravit
										</Button>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			<EditEventDialog
				open={editEventDialogOpen}
				setOpen={setEditEventDialogOpen}
				data={editEventData}
				refetch={() => {
					refetchEvents();
				}}
				setSnackMsg={setSnackMsg}
				setSnackState={setSnackState}
				setSnackOpen={setSnackOpen}
			/>
			<PrintQRCodesDialog
				open={printQRCodesDialog}
				setOpen={setPrintQRCodesDialog}
				data={eventsData}
				courseName={
					courseData &&
					convertFromRaw(
						JSON.parse(courseData.courseById.name)
					).getPlainText()
				}
			/>
			<NewEventDialog
				open={newEventDialogOpen}
				setOpen={setNewEventDialogOpen}
				refetch={() => {
					refetchEvents();
				}}
				setSnackMsg={setSnackMsg}
				setSnackState={setSnackState}
				setSnackOpen={setSnackOpen}
				course_id={course_id}
				season_id={season_id}
			/>
			<ConfirmEventNotHeldDialog
				open={openConfirmHeldDialog}
				setOpen={setOpenConfirmHeldDialog}
				action={handleEventIsHeld}
				event_id={event_id}
				held={held}
			/>
		</AdminPanel>
	);
}
