import { Route, Routes } from "react-router-dom";
import {
	AnnouncementCenter,
	ContentManagement,
	CourseManagement,
	CourseContentManagement,
	EditCourseContent,
	MyReservations,
	NewsManagement,
	PhotoManagement,
	ReservationsManagement,
	UserManagement,
	CourseSeasonsManagement,
	CourseEventsManagement,
	LecturerManagement,
	EditWebContent,
	DanceStudioOrders,
	BallroomRegistrationsManagement,
	SponsorsManagement,
	MyBallroomCourse,
	MyBallroomCourses,
	MyCourseReservationsDetail,
} from "./organisms";
import {
	AcceptAltReservationPage,
	AlbumPage,
	AttendancePage,
	ChangePassword,
	CoursesPage,
	GalleryPage,
	Homepage,
	NewsDetailPage,
	NewsPage,
	PageNotFound,
	PartnerAgreement,
	Profile,
	RejectAltReservationPage,
	RenewPwdMailPage,
	ResetPasswordPage,
	SignInPage,
	SignUpPage,
	StudentAttendancePage,
	UnsubscribePage,
} from "./pages";
import { PodminkyUcasti } from "./pages/Documents/PodminkyUcasti";
import { ProvozniRad } from "./pages/Documents/ProvozniRad";

export const route = {
	home: () => `/`,
	signIn: () => `/auth/signin`,
	signUp: () => `/auth/signup`,
	profile: () => `/profil`,
	passwordChange: () => `/zmena-hesla`,
	courses: () => `/kurzy`,
	myReservations: ()=> `/kurzy-tanecniho-studia`,
	myCourseReservationsDetail: ()=> `/kurzy-tanecniho-studia/:course_id`,
	announcementCenter: ()=> `/centrum-oznameni`,
	courseContentManagement: ()=> `/obsah-kurzy`,
	courseManagement: ()=> `/sprava-kurzu`,
	courseSeasonsManagement: ()=> `/sprava-kurzu/:course_id`,
	courseEventsManagement: ()=> `/sprava-kurzu/:course_id/:season_id`,
	newsManagement: ()=> `/sprava-aktualit`,
	userManagement: ()=> `/sprava-uzivatelu`,
	photoManagement: ()=> `/sprava-galerie`,
	contentManagement: ()=> `/webovy-obsah`,
	editWebContent: ()=> `/webovy-obsah/:content_id`,
	lecturersManagement: ()=> `/obsah-lektori/`,
	sponsorsManagement: ()=> `/obsah-sponzori/`,
	reservationsManagement: ()=> `/sprava-rezervaci`,
	ballRoomRegistrationManagement: ()=> `/prihlasky-spolecenske-tance`,
	danceStudioOrders: ()=> `/objednavky-tanecni-studio`,
	editCourseContent: ()=> `/upravit-kurz/:course_id`,
	podminkyUcasti: ()=> `/podminky-ucasti`,
	provozniRad: ()=> `/provozni-rad`,
	resetPassword: ()=> `/auth/restore-password/:hash`,
	renewPassword: () => `/renewPassword`,
	myBallroomCourses: ()=> `/me-spolecenske-tance`,
	myBallroomCourse: ()=> `/me-spolecenske-tance/:season_id/:course_id/:reservation_id/`,
	acceptAltReservation: () => `/alt-reservation-confirmed/:hash`,
  rejectAltReservation: () => `/alt-reservation-rejected/:hash`,
  attendancePage: () => `/prezencka`,
  studentAttendancePage: () => `/potvrdit-ucast-na-kurzu`,
  newsDetailPage: () => `/clanky/:news_id`,
  newsPage: () => `/clanky`,
	albumPage: ()=> `/album/:album_id`,
	gallery: ()=> `/galerie`,
	unsubscribe: ()=> `/unsubscribe/:email`,
	partnerAgreement: ()=> `/partner-souhlas/:email`,
};

export function PageRouter() {
	return (
			<Routes>
					<Route path={route.home()} exact element={<Homepage/>} ></Route>
					<Route path={route.signIn()} exact element={<SignInPage/>} ></Route>
					<Route path={route.signUp()} exact element={<SignUpPage/>} ></Route>
					<Route path={route.profile()} exact element={<Profile/>} ></Route>
					<Route path={route.unsubscribe()} exact element={<UnsubscribePage/>} ></Route>
					<Route path={route.partnerAgreement()} exact element={<PartnerAgreement/>} ></Route>
					<Route path={route.courses()} exact element={<CoursesPage/>} ></Route>
					<Route path={route.myReservations()} exact element={<MyReservations/>} ></Route>
					<Route path={route.myCourseReservationsDetail()} exact element={<MyCourseReservationsDetail/>} ></Route>
					<Route path={route.announcementCenter()} exact element={<AnnouncementCenter/>} ></Route>
					<Route path={route.reservationsManagement()} exact element={<ReservationsManagement/>} ></Route>
					<Route path={route.ballRoomRegistrationManagement()} exact element={<BallroomRegistrationsManagement/>} ></Route>
					<Route path={route.courseContentManagement()} exact element={<CourseContentManagement/>} ></Route>
					<Route path={route.courseManagement()} exact element={<CourseManagement/>} ></Route>
					<Route path={route.lecturersManagement()} exact element={<LecturerManagement/>} ></Route>
					<Route path={route.sponsorsManagement()} exact element={<SponsorsManagement/>} ></Route>
					<Route path={route.courseSeasonsManagement()} exact element={<CourseSeasonsManagement/>} ></Route>
					<Route path={route.courseEventsManagement()} exact element={<CourseEventsManagement/>} ></Route>
					<Route path={route.newsManagement()} exact element={<NewsManagement/>} ></Route>
					<Route path={route.contentManagement()} exact element={<ContentManagement/>} ></Route>
					<Route path={route.editWebContent()} exact element={<EditWebContent/>} ></Route>
					<Route path={route.userManagement()} exact element={<UserManagement/>} ></Route>
					<Route path={route.danceStudioOrders()} exact element={<DanceStudioOrders/>} ></Route>
					<Route path={route.myBallroomCourses()} exact element={<MyBallroomCourses/>} ></Route>
					<Route path={route.myBallroomCourse()} exact element={<MyBallroomCourse/>} ></Route>
					<Route path={route.editCourseContent()} exact element={<EditCourseContent/>} ></Route>
					<Route path={route.podminkyUcasti()} exact element={<PodminkyUcasti/>} ></Route>
					<Route path={route.provozniRad()} exact element={<ProvozniRad/>} ></Route>
					<Route path={route.resetPassword()} exact element={<ResetPasswordPage/>} ></Route>
					<Route path={route.renewPassword()} exact element={<RenewPwdMailPage/>} ></Route>
					<Route path={route.passwordChange()} exact element={<ChangePassword/>} ></Route>
					<Route path={route.acceptAltReservation()} exact element={<AcceptAltReservationPage/>} ></Route>
					<Route path={route.rejectAltReservation()} exact element={<RejectAltReservationPage/>} ></Route>
					<Route path={route.attendancePage()} exact element={<AttendancePage/>} ></Route>
					<Route path={route.studentAttendancePage()} exact element={<StudentAttendancePage/>} ></Route>
					<Route path={route.newsDetailPage()} exact element={<NewsDetailPage/>} ></Route>
					<Route path={route.albumPage()} exact element={<AlbumPage/>} ></Route>
					<Route path={route.photoManagement()} exact element={<PhotoManagement/>} ></Route>
					<Route path={route.newsPage()} exact element={<NewsPage/>} ></Route>
					<Route path={route.gallery()} exact element={<GalleryPage/>} ></Route>
					<Route path="*" element={<PageNotFound/>} ></Route>
			</Routes>
	);
}
