import { Paper, Typography } from "@mui/material";

/**
 * Block to inform users they don't have the privileges to view selected content.
 * @returns {Component} MUI Paper
 */
export function AdminsOnly() {
  return (
			<Paper
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					p: "25px",
					maxWidth: '750px',
					width: '95%',
					borderRadius: "15px",
					mx: "auto",
					mt: "50px",
				}}
			>
				<Typography variant="h4" color="initial">Nemáte dostatečná oprávnění</Typography>
				<Typography variant="body1" color="initial">Tato sekce je pouze pro lektory a administrátory.</Typography>
				
			</Paper>
  );
}
