import PropTypes from "prop-types"
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Button, Link, Stack, Typography } from "@mui/material";
import { FormikField } from "src/molecules/";
import { route } from "src/Routes";
import { Banner } from "src/atoms";

const schema = yup.object().shape({
	password: yup.string().required().label("Heslo"),
	passwordAgain: yup
		.string()
		.required()
		.oneOf([yup.ref("password"), null], "Hesla musí být stejná")
		.label("Potvrzení hesla"),
});

/**
 * Form to change old password
 * @returns {Component} Form
 */
export function ResetPasswordForm({
	errorMessage,
	onSubmit,
	successMessage,
	initialValues,
}) {
	return (
		<Formik
			onSubmit={(values, { resetForm }) => {
				onSubmit(values);
				resetForm();
			}}
			initialValues={initialValues}
			validationSchema={schema}
			validateOnBlur={false}
			enableReinitialize={true}
		>
			<Form>
				<Stack
					direction="column"
					spacing={2}
					justifyContent="center"
					sx={{ width: [200, 300, 400] }}
				>
					{successMessage ? (
						<>
							<Banner title={successMessage} severity="success" />
							<Button
								href={route.signIn()}
								component={Link}
								variant="contained"
							>
								Zpět na přihlášení
							</Button>
						</>
					) : (
						<>
							{errorMessage && (
								<Banner title={errorMessage} severity="error"/>
							)}
							<Typography
								variant="h3"
								color="initial"
								sx={{
									fontSize: "14pt",
									fontWeight: "bold",
									textAlign: "center",
								}}
							>
								Zadejte nové heslo
							</Typography>
							<FormikField
								// required
								name="password"
								label="Heslo"
								variant="standard"
								type="password"
								as="TextField"
							/>
							<FormikField
								// required
								name="passwordAgain"
								label="Potvrzení hesla"
								variant="standard"
								type="password"
								as="TextField"
							/>
							<Button type="submit" variant="contained">
								Změnit heslo
							</Button>
						</>
					)}
				</Stack>
			</Form>
		</Formik>
	);
}

ResetPasswordForm.propTypes = {
	errorMessage: PropTypes.any,
	initialValues: PropTypes.any,
	onSubmit: PropTypes.func,
	successMessage: PropTypes.any
}
