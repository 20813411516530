import { Typography, Grid, Link, Box } from "@mui/material";
import { route } from "src/Routes";

/**
 * Footer of the page
 * @returns {Component} Footer
 */
export function Footer() {
	return (
		<Box
			component="footer"
			sx={{
				py: 2,
				px: 1,
				mt: 'auto',
				bgcolor: "primary.main",
			}}
		>
            
			<Grid
				container
				direction="row"
				alignContent="stretch"
				wrap="wrap"
				spacing={2}
			>
				<Grid item xs={6} sx={{ my: "auto" }}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Link underline="none" color="inherit" href={route.podminkyUcasti()}>
								Podmínky účasti
							</Link>
						</Grid>
						<Grid item xs={12}>
							<Link underline="none" color="inherit" href={route.provozniRad()}>
								Provozní řád
							</Link>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2" color="text.secondary">
								<Link
									underline="none"
									color="inherit"
									href="http://tsk.coalpub.cz/"
								>
									{"Copyright © "}
									{new Date().getFullYear()}
									{" - Taneční studio Ká - Letohrad"}
								</Link>{" "}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2" color="text.secondary">
								{"Autor: "}
								<Link
									underline="none"
									color="inherit"
									href="https://coalsoft.cz/"
								>
									Coalsoft s.r.o.
								</Link>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs='auto'

          sx={{
            mb: 'auto',
            ml: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end'
          }}
				>
					<Typography sx={{alignSelf: 'start', fontWeight: 'bold'}} variant="body2" color="initial">Taneční studio "Ká", z.s.</Typography>
					<Typography sx={{alignSelf: 'start'}} variant="body2" color="initial">Kateřina Motyčková</Typography>
					<Typography sx={{alignSelf: 'start'}} variant="body2" color="initial">F.V.Heka 874</Typography>
					<Typography sx={{alignSelf: 'start'}} variant="body2" color="initial">561 51 Letohrad</Typography>
					<Typography sx={{alignSelf: 'start'}} variant="body2" color="initial">IČO: 05273951</Typography>
					<Typography sx={{alignSelf: 'start'}} variant="body2" color="initial">Bankovní spojení: Fio banka</Typography>
					<Typography sx={{alignSelf: 'start'}} variant="body2" color="initial">účet č. 2801094173/2010</Typography>
				</Grid>
			</Grid>
		</Box>
	);
}
