import PropTypes from "prop-types"
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Grid,
	Typography,
} from "@mui/material";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";
import { useNavigate } from "react-router-dom";

/**
 * Card for displaying courses in homepage. Enables user to smoothly navigate to the course detail.
 * @param {String} img base64 cover
 * @param {String} courseName course name
 * @param {String} description course short description
 * @param {Number} id course id
 * @returns {Component} Grid item with Card inside
 */
export function CourseCard({ img, courseName, description, id }) {
	const navigate = useNavigate();

	function scrollView(link) {
		navigate(`/kurzy`, {
			replace: false,
			state: {
				ref: link,
			},
		});
	}

	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	return (
		<Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
			<Card
				className="grow communityCard"
				elevation={16}
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					borderRadius: "20px",
					mx: "20px",
				}}
			>
				<CardMedia
					className="hideOnSmall"
					component="img"
					title="ball_dance_course_cover"
					image={"/img/" + img}
					height="300px"
				/>
				<CardContent
					sx={{
						width: "100%",
						bgcolor: "primary.main",
						display: "flex",
						alignItems: "center",
					}}
				>
					<Typography
						variant="h6"
						color="initial"
						fontWeight="bold"
						sx={{"&>*":{
							margin: "0px"
						}}}
						dangerouslySetInnerHTML={createMarkup(
							draftToHtml(JSON.parse(courseName))
						)}
					>
					</Typography>
				</CardContent>
				<CardContent sx={{ width: "100%" }}>
					<Typography
						variant="body1"
						color="initial"
						sx={{"&>*":{
							margin: "0px"
						}}}
						dangerouslySetInnerHTML={createMarkup(
							draftToHtml(JSON.parse(description))
						)}
					>
					</Typography>
				</CardContent>
				<CardActions sx={{ mt: "auto" }}>
					<Button
						onClick={() => {
							scrollView(id);
						}}
						variant="contained"
						color="secondary"
						sx={{ mx: "auto" }}
					>
						Více o kurzu
					</Button>
				</CardActions>
			</Card>
		</Grid>
	);
}

CourseCard.propTypes = {
	courseName: PropTypes.any,
	description: PropTypes.any,
	id: PropTypes.any,
	img: PropTypes.any
}
