import { useMutation } from "@apollo/client";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import gql from "graphql-tag";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SnackBarHandler } from "src/atoms";
import { FormikField } from "src/molecules";
import { route } from "src/Routes";
import { useAuth } from "src/utils/auth";
import * as yup from "yup";

const SIGNIN_MUTATION = gql`
	mutation SignIn($email: String!, $password: String!) {
		signin(email: $email, password: $password) {
			user {
				user_id
				email
			}
			token
		}
	}
`;

const schema = yup.object().shape({
	email: yup.string().email().required().label("E-mail"),
	password: yup.string().required().label("Password"),
});


/**
 * Page to sign in to the web page.
 * @returns {Component} Page to sign in
 */
export function SignInPage() {
	const [email] = useState("");
	const [password] = useState("");

	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const auth = useAuth();
	const navigate = useNavigate();

	const initialValues = {
		email: email,
		password: password,
	};

	const [signinRequest] = useMutation(SIGNIN_MUTATION, {
		onCompleted: ({ signin: { user, token } }) => {
			auth.signin({ token, user });
			navigate(route.profile());
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleSubmit = useCallback(
		(values) => {
			signinRequest({
				variables: {
					email: values.email,
					password: values.password,
				},
			});
		},
		[signinRequest]
	);

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					py: "25px",
					maxWidth: '750px',
					width: '95%',
					backgroundImage: "url(/img/dance_in_light.jpeg)",
					filter: "drop-shadow(25px 25px 25px rgba(0, 0, 0, 0.25))",
					borderRadius: "15px",
					mx: "auto",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center",
					my: "50px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					<Box
						component="img"
						alt="logo"
						src="/img/logo_white.svg"
					></Box>
					<Box>
						<Typography variant="h3" color="white">
							Přihlášení
						</Typography>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						px: "25px",
						py: '40px',
						width: '75%',
						position: "static",
						background: "rgba(255, 255, 255, 0.8)",
						borderRadius: "25px",
					}}
				>
						<Formik
							onSubmit={handleSubmit}
							initialValues={initialValues}
							validationSchema={schema}
							validateOnBlur={false}
						>
							<Form>
								<Stack
									direction="column"
									spacing={2}
									color="secondary"
									justifyContent="center"
								>
									<FormikField
										id="email"
										name="email"
										label="E-mail"
										type="email"
										variant="standard"
										as="TextField"
										color="secondary"
									/>
									<FormikField
										id="password"
										name="password"
										label="Heslo"
										type="password"
										variant="standard"
										as="TextField"
										color="secondary"
									/>
									<Button type="submit" variant="contained">
										Přihlásit se
									</Button>
									<h4>
										{" "}
										Ještě nemáte účet?{" "}
										<a href={route.signUp()}>
											Zaregistrujte se.
										</a>
									</h4>
									<a href={route.renewPassword()}>Zapomenuté heslo</a>
								</Stack>
							</Form>
						</Formik>
				</Box>
			</Box>
			<SnackBarHandler
				snackState={snackState}
				snackMsg={snackMsg}
				snackOpen={snackOpen}
				setSnackOpen={setSnackOpen}
			/>
		</Box>
	);
}
