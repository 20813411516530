import PropTypes from "prop-types"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Paper,
	TextField,
} from "@mui/material";
import cs from "date-fns/locale/cs";
import { useState } from "react";
import { useRef } from "react";

import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";

/**
 * Dialog to print QR codes with dates in certain period of time.
 * @param {Function} refetch refetch new data from database
 * @param {Function} setOpen open/close dialog
 * @param {Boolean} open opened/closed dialog
 * @param {Object} data QR code data
 * @param {String} courseName name of course
 * @returns {Component} Dialog
 */
export function PrintQRCodesDialog({
	open,
	setOpen,
	refetch,
	data,
	courseName,
}) {
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const printQrRef = useRef();
	let codes = [];

	if (data) {
		codes = data.eventsBySeasonId.filter((event) => {
			if (startDate !== "" && event.event_date < startDate) {
				return false;
			}
			if (endDate !== "" && event.event_date > endDate) {
				return false;
			}
			return true;
		});
	}

	const handleClose = () => {
		setOpen(false);
		refetch();
	};

	return (
        <Dialog
			open={open}
			onClose={handleClose}
			maxWidth="md"
			fullWidth={true}
		>
			<DialogTitle id="alert-dialog-title">
				{"Výtisk QR kódů lekcí"}
			</DialogTitle>
			<DialogContent>
				<LocalizationProvider dateAdapter={AdapterDateFns} locale={cs}>
					<DatePicker
						mask="__. __. ____ HH:mm"
						label="Začátek období"
						value={startDate}
						onChange={(newValue) => {
							setStartDate(newValue);
						}}
						renderInput={(params) => (
							<TextField
								color="secondary"
								size="small"
								sx={{
									mt: "15px",
								}}
								{...params}
							/>
						)}
					/>
				</LocalizationProvider>
				<LocalizationProvider dateAdapter={AdapterDateFns} locale={cs}>
					<DatePicker
						mask="__. __. ____ HH:mm"
						label="Konec období"
						value={endDate}
						onChange={(newValue) => {
							setEndDate(newValue);
						}}
						renderInput={(params) => (
							<TextField
								color="secondary"
								size="small"
								sx={{
									mt: "15px",
								}}
								{...params}
							/>
						)}
					/>
				</LocalizationProvider>
				<Box
					sx={{
						textAlign: "center",
						flexDirection: "row",
						flexWrap: "wrap",
						display: "flex",
					}}
					ref={printQrRef}
				>
					<Box sx={{ width: "100%" }}>{courseName}</Box>
					{codes.map((event) => (
						<Paper
							sx={{
								p: "15px",
								m: "10px",
								alignItems: "center",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<QRCode
								value={event.event_id.toString()}
								size={125}
							/>
							{event.event_start.match(
								/\d{0,2}\.\s\d{0,2}\.\s\d{0,4}/
							)}
						</Paper>
					))}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					color="secondary"
					variant="contained"
					autoFocus
					onClick={useReactToPrint({
						content: () => printQrRef.current,
					})}
				>
					Potvrdit
				</Button>
				<Button onClick={handleClose} color="secondary">
					Zrušit
				</Button>
			</DialogActions>
		</Dialog>
    );
}

PrintQRCodesDialog.propTypes = {
	courseName: PropTypes.any,
	data: PropTypes.shape({
		eventsBySeasonId: PropTypes.array
	}),
	open: PropTypes.any,
	refetch: PropTypes.func,
	setOpen: PropTypes.func
}
