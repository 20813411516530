import PropTypes from "prop-types"
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Link, Typography } from "@mui/material";
import { QrReader } from "react-qr-reader";
import { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";
import { convertFromRaw } from "draft-js";

const EVENT_INFO = gql`
	query event($event_id: Int!) {
		eventById(event_id: $event_id) {
			event_id
			name
			event_start
			event_end
		}
	}
`;

const QR_ATTENDANCE = gql`
	mutation setQRAttendance($user_id: Int!, $event_id: Int!) {
		setQrAttendance(user_id: $user_id, event_id: $event_id)
	}
`;

/**
 * Dialog for students to scan QR code of the lecture and confirming their attendance in doing so.
 * @param {String} title dialog title
 * @param {Function} setOpen open/close dialog
 * @param {Boolean} open opened/closed dialog
 * @param {Number} user_id user id
 * @param {Function} setSnackState function to set snack state Success, Warning, Error, Info
 * @param {Function} setSnackMsg function to set snack msg
 * @param {Function} setSnackOpen function to open/close informational snack message
 * @returns {Component} Dialog
 */
export function QrCodeScanDialogStudent({
	title,
	open,
	setOpen,
	user_id,
	setSnackMsg,
	setSnackState,
	setSnackOpen,
}) {
	const [qrData, setQrData] = useState();
	const [event_id, setEventId] = useState();

	const handleClose = () => {
		setOpen(false);
		setTimeout(() => {
			setQrData();
			setEventId();
		}, 500);
	};

	let skip;
	if (event_id) {
		skip = false;
	} else {
		skip = true;
	}

	const getEventInfoQuery = useQuery(EVENT_INFO, {
		variables: {
			event_id: event_id,
		},
		skip: skip,
	});

	const [QrAttendanceRequest] = useMutation(QR_ATTENDANCE, {
		onCompleted: (data) => {
			setSnackMsg(data.setQrAttendance);
			setSnackState("success");
			setSnackOpen(true);
			handleClose();
		},
		onError: (error) => {
			setSnackMsg(error.msg);
			setSnackState("error");
			setSnackOpen(true);
		},
	});

	const handleQrAttendance = useCallback(() => {
		QrAttendanceRequest({
			variables: {
				user_id: parseInt(user_id),
				event_id: parseInt(event_id),
			},
		});
	}, [QrAttendanceRequest, event_id, user_id]);

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogTitle
					id="alert-dialog-title"
					sx={{ whiteSpace: "pre-line" }}
				>
					{title}
				</DialogTitle>
				{user_id !== "" && user_id !== null && user_id !== undefined ? (
					<DialogContent>
						{!qrData ? (
							<QrReader
								constraints={{ facingMode: "environment" }}
								onResult={(result, error) => {
									if (!!result) {
										setEventId(parseInt(result?.text));
										setQrData(result?.text);
										getEventInfoQuery.refetch();
									}
									if (!!error) {
									}
								}}
								style={{ width: "100%" }}
							/>
						) : (
							<>
								{getEventInfoQuery.data && (
									<>
										<Typography
											variant="body1"
											color="initial"
											fontSize="1.1em"
										>
											<strong>Naskenoval jste: </strong>
										</Typography>
										<Typography
											variant="body1"
											color="initial"
										>
											{convertFromRaw(
												JSON.parse(
													getEventInfoQuery.data
														.eventById.name
												)
											).getPlainText()}{" "}
										</Typography>
										<Typography
											variant="body1"
											color="initial"
										>
											{getEventInfoQuery.data.eventById.event_start.match(
												/\d{0,2}\.\s\d{0,2}\.\s\d{0,4}/
											)}
											{
												getEventInfoQuery.data.eventById.event_start.match(
													/\s(\d{0,2}:\d{0,2})/
												)[0]
											}
											{" - "}
											{
												getEventInfoQuery.data.eventById.event_end.match(
													/\s(\d{0,2}:\d{0,2})/
												)[0]
											}
										</Typography>
									</>
								)}
							</>
						)}
					</DialogContent>
				) : (
					<DialogContent>
						<Typography variant="body1" color="initial">
							Nejste přihlášen.
						</Typography>
					</DialogContent>
				)}

				<DialogActions>
					{getEventInfoQuery.data && (
						<Button
							color="secondary"
							variant="contained"
							onClick={() => {
								handleQrAttendance();
							}}
							autoFocus
						>
							Potvrdit účast
						</Button>
					)}
					<Button
						component={Link}
						color="inherit"
						variant="text"
						onClick={() => {
							handleClose();
						}}
					>
						Zavřít
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

QrCodeScanDialogStudent.propTypes = {
	open: PropTypes.any,
	setOpen: PropTypes.func,
	setSnackMsg: PropTypes.func,
	setSnackOpen: PropTypes.func,
	setSnackState: PropTypes.func,
	title: PropTypes.any,
	user_id: PropTypes.string
}
