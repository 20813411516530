import PropTypes from "prop-types"
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

/**
 * Component in header navigation bar. It is used to smoothly navigate to the desired part of the homepage.
 * @param {String} text Text of the link used in navigation bar
 * @param {String} link Href link to desired part of the homepage
 * @param {String} width custom width if needed (hamburger menu)
 * @param {Boolean} hamburger asks whether it is mobile width to change the fontSize
 * @returns {Component} Button
 */
export function NavbarLink({text, link, width, hamburger}) {
	const navigate = useNavigate();

	function scrollView () {
		const pointToScroll = document.getElementById(link);
		if(window.location.pathname === "/"){
			pointToScroll.scrollIntoView({ behaviour: 'smooth'});
		}else{
			navigate(`/`, {
				replace: true, 
				state: {
					ref: link
				}
			});
		}
	}

	return (
		<Button onClick={()=>{scrollView()}} size="large" variant="text" color="inherit" sx={{width: width}} >
			<Typography sx={{fontWeight: 'bold', fontSize: hamburger ? 'inherit' : '1.2em'}} variant="body1" color="initial">{text}</Typography>		
		</Button>
	);
}

NavbarLink.propTypes = {
	hamburger: PropTypes.any,
	link: PropTypes.any,
	text: PropTypes.any,
	width: PropTypes.any
}
