import { useQuery } from "@apollo/client";
import { Box, Stack, Typography } from "@mui/material";
import gql from "graphql-tag";
import { NewsListCard } from "src/molecules";

const NEWS_LIST = gql`
	query news {
		publishedNews {
			news_id
			date
			title
			article
			cover
		}
	}
`;

/**
 * Page to display previews of all articles
 * @returns {Component} Stack of NewsListCards
 */
export function NewsPage() {
	const newsQuery = useQuery(NEWS_LIST);

	return (
		<Box sx={{display: "flex",
    flexDirection: "column",
    maxWidth: '1200px',
    width: '95%',
    mx: '5%',
		mb: '50px'}}>
			<Typography variant="h2" color="initial" sx={{my: '30px'}}>
				Aktuality
			</Typography>
			<Stack spacing={4}>
				{newsQuery.data &&
					newsQuery.data.publishedNews.map((article) => (
						<NewsListCard
							key={article.news_id}
							title={article.title}
							description={article.article}
							date={article.date}
							cover={article.cover}
							newsId={article.news_id}
						/>
					))}
			</Stack>
		</Box>
	);
}
