import PropTypes from "prop-types"
import {
	LocalizationProvider,
	CalendarPickerSkeleton,
	PickersDay,
	StaticDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from "@mui/material";
import cs from "date-fns/locale/cs";
import { useEffect, useState } from "react";

/**
 * Component for rendering a calendar with colored up dates that have events in it.
 * After switching to another month, sets new values and fetches new events in it. 
 * @param {Date} dateValue selected Date object
 * @param {Function} setDateValue useState setter for selected date
 * @param {Function} getDaysOfEvents function to query days with events in selected month
 * @param {Date} month date of the currently viewed month
 * @param {Function} setMonthDate useState setter for month Date
 * @param {String} queryName Helps to fetch correct data and use this component more universaly.
 * @return {Component} LocalizationProvider => StaticDatePicker
 */
export function ReservationDatePicker({
	dateValue,
	setDateValue,
	getDaysOfEvents,
	month,
	setMonthDate,
	queryName,
}) {
	const [highlightedDays, setHighlightedDays] = useState([]);

	/**
	 * Used to highlight days with events
	 */
	useEffect(() => {
		setHighlightedDays([]);
		if (getDaysOfEvents.data) {
			setHighlightedDays(getDaysOfEvents.data[queryName].event_days);
		}
	}, [getDaysOfEvents.data, month, queryName]);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} locale={cs}>
			<StaticDatePicker
				mask="__.__.____"
				displayStaticWrapperAs="desktop"
				openTo="day"
				width="1000px"
				orientation="landscape"
				value={dateValue}
				onChange={(newValue) => {
					setDateValue(newValue);
				}}
				renderInput={(params) => <TextField {...params} />}
				onMonthChange={(date) => setMonthDate(new Date(date))}
				renderLoading={() => <CalendarPickerSkeleton />}
				renderDay={(day, _value, DayComponentProps) => {
					const isSelected =
						!DayComponentProps.outsideCurrentMonth &&
						highlightedDays.indexOf(day.getDate()) >= 0;
					return (
						<PickersDay
							sx={
								isSelected
									? {
											backgroundColor: "secondary.main",
											fontSize: "16px",
											color: "white",
									  }
									: { fontSize: "16px" }
							}
							{...DayComponentProps}
						/>
					);
				}}
			/>
		</LocalizationProvider>
	);
}

ReservationDatePicker.displayName = 'ReservationDatePicker';

ReservationDatePicker.propTypes = {
	dateValue: PropTypes.any,
	getDaysOfEvents: PropTypes.shape({
		data: PropTypes.any
	}),
	month: PropTypes.any,
	queryName: PropTypes.any,
	setDateValue: PropTypes.func,
	setMonthDate: PropTypes.func
}
