import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";
import { Card, CardMedia } from "@mui/material";

const CONTENT_INFO = gql`
	query contentById($content_id: Int!) {
		contentById(content_id: $content_id) {
			content_id
			name
			text
		}
	}
`;

/**
 * Homepage section with rich text.
 * Displays editable content.
 * Because of the layout of page, the group picture was added manually to this section.
 * @returns {Component} Box with Typography and MUI Card
 */
export function HomepageServices() {
	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	const { data } = useQuery(CONTENT_INFO, {
		variables: {
			content_id: 6,
		},
	});

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				my: "50px",
				scrollMarginTop: "120px",
				width: "75%",
				mx: "auto",
			}}
			id="O nas"
			className="spreadWidthOnSmall"
		>
			<Box sx={{ width: "75%", mx: "auto" }}>
				<Typography
					variant="h2"
					sx={{ mb: "15px", textAlign: "center" }}
				>
					Nabízíme
				</Typography>
				{data && (
					<Typography
						variant="body1"
						fontSize="1.2em"
						dangerouslySetInnerHTML={createMarkup(
							draftToHtml(JSON.parse(data.contentById.text))
						)}
					></Typography>
				)}
				<Card
					sx={{
						borderRadius: "25px",
						width: '100%',
						mx: 'auto',
						maxHeight: '400px',
						maxWidth: 'fit-content'
					}}
				>
					<CardMedia
						component="img"
						image="/img/group.png"
						alt="group photo"
						sx={{
							backgroundSize: "contain",
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat",
							objectFit: 'contain',
							maxHeight: '400px'
						}}
					/>
				</Card>
			</Box>
		</Box>
	);
}
