import PropTypes from "prop-types"
import React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Button, Link, Stack } from '@mui/material';

import { Banner } from 'src/atoms/';
import { FormikField } from 'src/molecules/';
import { route } from 'src/Routes';

const initialValues = {
  email: '',
};

const schema = yup.object().shape({
  email: yup.string().email().required().label('E-mail'),
});

/**
 * Form to fill the mail where will be sent the password reset link.
 * @returns {Component} Form
 */
export function RenewPasswordMailForm({ errorMessage, onSubmit, successMessage }) {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={schema}
      validateOnBlur={false}
    >
      <Form>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          sx={{ width: [200, 300, 400] }}
        >
          {successMessage ? (
            <>
              <h1>Zkontrolujte svůj e-mail</h1>
              <Banner title={successMessage} severity="success"/>
              <Button
                href={route.signIn()}
                component={Link}
                variant="contained"
              >
                Zpět na přihlášení
              </Button>
            </>
          ) : (
            <>
              <h1>Obnovení hesla</h1>
              <h4>
                Zadejte Váš e-mail a my Vám odešleme odkaz pro obnovení hesla
              </h4>
              {errorMessage && <Banner title={errorMessage} severity="error"/>}
              <FormikField
                id="email"
                name="email"
                label="E-mail"
                type="email"
                variant="standard"
                as="TextField"
              />
              <Button type="submit" variant="contained">
                Odeslat
              </Button>
            </>
          )}
        </Stack>
      </Form>
    </Formik>
  );
}

RenewPasswordMailForm.propTypes = {
  errorMessage: PropTypes.any,
  onSubmit: PropTypes.any,
  successMessage: PropTypes.any
}
