import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Box, Skeleton, Typography } from "@mui/material";
import ModalImage from "react-modal-image";

const PHOTO_LIST = gql`
	query albumsPhotos($album_id: Int!) {
		albumsPhotos(album_id: $album_id) {
			photo_id
			photo
			name
		}
	}
`;
const ALBUM = gql`
	query albumById($album_id: Int!) {
		albumById(album_id: $album_id) {
			album_id
			name
		}
	}
`;

/**
 * Page with list of photos from selected album.
 * Photos can be viewed in Fullscreen mode and downloaded.
 * @returns {Component} ImageList => ImageListItems
 */
export function AlbumPage() {
	const { album_id } = useParams();

	const { data } = useQuery(PHOTO_LIST, {
		variables: {
			album_id: parseInt(album_id),
		},
	});

	const albumQuery = useQuery(ALBUM, {
		variables: {
			album_id: parseInt(album_id),
		},
	});

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					py: "25px",
					maxWidth: "1000px",
					width: "95%",
					mx: "auto",
					my: "50px",
				}}
			>
				<Typography variant="h2" color="initial" sx={{textAlign: 'left', mr: 'auto'}}>{albumQuery.data && albumQuery.data.albumById.name}</Typography>
				<ImageList
					sx={{ width: "100%", mx: "auto" }}
					variant="standard"
					cols={4}
					rowHeight={250}
				>
					{data ? (
						data.albumsPhotos.map((item) => (
							<ImageListItem
								key={item.photo_id}
							>
								<ModalImage
									className="galleryImage"
									small={item.photo}
									large={item.photo}
									alt={item.name}
									loading="lazy"
								/>
							</ImageListItem>
						))
					) : (
						<Skeleton
							variant="rectangular"
							width={210}
							height={118}
						/>
					)}
				</ImageList>
			</Box>
		</Box>
	);
}
