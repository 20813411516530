import PropTypes from "prop-types"
import { Box, Link, Typography } from "@mui/material";

/**
 * Album card made of boxes and typography to be displayed in Homepage gallery
 * @param {String} title Title of the cover image
 * @param {String} cover base64 image
 * @param {Number} id album id for later navigation
 * @returns {Component} Album card
 */
export function AlbumCard({ title, cover, id }) {
	return (
		<Box
			sx={{
				height: "300px",
				width: "300px",
				display: "flex",
				alignItems: "flex-start",
				flexDirection: "column",
				margin: "50px 0px",
        backgroundImage: `url(${cover})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
				flexWrap: 'wrap',
				backgroundPosition: 'center'
			}}
			component={Link}
			href={`/album/${id}`}
		>
			<Box
				sx={{
					bgcolor: "rgba(255, 255, 255, 0.75)",
					mt: "auto",
          width: '100%',
          height: '50px',
          display: 'flex'
				}}
			>
				<Typography variant="body1" color="initial" sx={{fontWeight: '400', fontSize: '18px', textAlign: 'left', my: 'auto', ml: '5%'}}>{title}</Typography>
			</Box>
		</Box>
	);
}

AlbumCard.propTypes = {
	cover: PropTypes.any,
	id: PropTypes.any,
	title: PropTypes.any
}
