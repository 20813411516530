import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";
import gql from "graphql-tag";

const CONTENT_INFO = gql`
	query contentById($content_id: Int!) {
		contentById(content_id: $content_id) {
			content_id
			name
			text
		}
	}
`;

/**
 * Component for adding text formatted text to BallParty text dialog via inserted HTML.
 * Dealt with problem of text not being loaded before the component was rendered and forced text to be part of the HTML code.
 * There were made other basically same components which should be combined to one universal later on.
 * @returns {Component} Typography
 */
export function BallPartyText() {
	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	const { data } = useQuery(CONTENT_INFO, {
		variables: {
			content_id: 4,
		},
	});

	return (
		<>
			{data && (
				<Typography
					variant="body1"
					color="initial"
					textAlign="justify"
					dangerouslySetInnerHTML={createMarkup(
						draftToHtml(JSON.parse(data.contentById.text))
					)}
				></Typography>
			)}
		</>
	);
}
