import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { Box, ImageListItemBar, Link, Skeleton, Typography } from "@mui/material";

const GALLERY_LIST = gql`
	query albums {
		albums {
			album_id
			name
			created_at
			photos {
				name
				photo
			}
		}
	}
`;

/**
 * Gallery page with listed all albums to select on and go to its detail.
 * @returns {Component} ImageList with ImageListItems
 */
export function GalleryPage() {
	const loop = [0, 1, 2, 3, 4, 5];

	const { data } = useQuery(GALLERY_LIST);

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					py: "25px",
					maxWidth: "750px",
					width: "95%",
					mx: "auto",
					my: "50px",
				}}
			>
        <Typography variant="h2" color="initial" sx={{textAlign: 'left', mr: 'auto'}}>Galerie</Typography>
				<ImageList
					sx={{ width: "100%", mx: "auto" }}
					variant="standard"
					cols={6}
					rowHeight={250}
				>
					{data ? (
						data.albums.map((item) => (
							<ImageListItem
								key={item.album_id}
								cols={2}
								gap={2}
								component={Link}
								href={`/album/${item.album_id}`}
							>
								<img
									src={
										item.photos[0].photo !== null
											? item.photos[0].photo
											: `/img/logo_black.svg`
									}
									alt={item.name}
									className="galleryImage"
									loading="lazy"
								/>
								<ImageListItemBar
									title={item.name}
									subtitle={item.created_at}
									sx={{ background: "rgba(0, 0, 0, 0.85)" }}
								/>
							</ImageListItem>
						))
					) : (
						<>
							{loop.map((item) => (
								<ImageListItem cols={2} key={item}>
									<Skeleton
										variant="rectangular"
										height={250}
									/>
								</ImageListItem>
							))}
						</>
					)}
				</ImageList>
			</Box>
		</Box>
	);
}
