import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";

const CONTENT_INFO = gql`
	query contentById($content_id: Int!) {
		contentById(content_id: $content_id) {
			content_id
			name
			text
		}
	}
`;

/**
 * Rich text document with terms of attendance on dance studio courses
 * @returns {Component} Document Typography
 */
export function PodminkyUcasti() {
	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	const {data} = useQuery(CONTENT_INFO, {
		variables: {
			content_id: 13,
		},
	});

	return (
		<Box sx={{ maxWidth: "1250px", mx: "auto", mt: "25px", mb: "100px" }}>
			<Box sx={{ mx: "20px" }}>
				<Typography variant="h2" color="initial" fontSize="48px">
					Podmínky účasti v kurzech a na lekcích
				</Typography>
				{data && (
						<Typography
							variant="body1"
							fontSize="18px"
							color="initial"
							sx={{ textAlign: "justify" }}
							dangerouslySetInnerHTML={createMarkup(
								draftToHtml(JSON.parse(data.contentById.text))
							)}
						></Typography>
					)}
			</Box>
		</Box>
	);
}
