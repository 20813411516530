import { Button, Link, Paper, Typography } from "@mui/material";
import { route } from "src/Routes";

/**
 * Block to inform users they have to sign in to view selected content.
 * @returns {Component} MUI Paper
 */
export function SignInRequest() {
  return (
			<Paper
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					p: "25px",
					maxWidth: '750px',
					width: '95%',
					borderRadius: "15px",
					mx: "auto",
					mt: "50px",
				}}
			>
				<Typography variant="h4" color="initial">Nejste přihlášen(a)</Typography>
				<Typography variant="body1" color="initial">Do této sekce je přístup pouze po přihlášení.</Typography>
				<Button component={Link} variant="contained" color="secondary" href={route.signIn()}>Přihlásit se</Button>
			</Paper>
  );
}
