import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./utils/auth";
import { EnhancedApolloProvider } from "./utils/apollo";
import { PageWrapper } from "./templates/PageWrapper";
import { CssBaseline } from "@mui/material";
import { GlobalTheme } from "./utils/theme";

/**
 * App wrapper for theme, css, and basic template application
 * @returns {Object}
 */
export default function App() {
	return (
		<BrowserRouter>
			<AuthProvider>
				<EnhancedApolloProvider>
					<GlobalTheme>
						<CssBaseline />
						<PageWrapper></PageWrapper>
					</GlobalTheme>
				</EnhancedApolloProvider>
			</AuthProvider>
		</BrowserRouter>
	);
}
