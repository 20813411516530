import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useState } from "react";
import jwt_decode from "jwt-decode";
import { useAuth } from "src/utils/auth";
import { AdminPanel, ResetPasswordForm } from "src/templates";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const CHANGE_PASSWORD_MUTATION = gql`
	mutation changePassword(
		$user_id: Int!
		$password: String!
		$passwordAgain: String!
	) {
		changePassword(
			user_id: $user_id
			password: $password
			passwordAgain: $passwordAgain
		) {
			email
		}
	}
`;

/**
 * Profile page to change users password.
 * @returns {Component} Page with change password form
 */
export function ChangePassword() {
	const { token } = useAuth();
	const decoded = token && jwt_decode(token);
	const [userId] = useState(decoded?.user_id);

	const [initialValues, setInitialValues] = useState({
		password: "",
		passwordAgain: "",
	});
	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const [changePasswordRequest] = useMutation(CHANGE_PASSWORD_MUTATION, {
		onCompleted: () => {
			setSnackState("success");
			setSnackMsg("Vaše heslo bylo změněno.");
			setSnackOpen(true);
			setInitialValues({
				password: "",
				passwordAgain: "",
			});
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleChangePasswordFormSubmit = useCallback(
		(values) => {
			changePasswordRequest({
				variables: {
					user_id: parseInt(userId),
					password: values.password,
					passwordAgain: values.passwordAgain,
				},
			});
		},
		[changePasswordRequest, userId]
	);

	return (
		<AdminPanel snackState={snackState} snackMsg={snackMsg} snackOpen={snackOpen} setSnackOpen={setSnackOpen}>
			<Box
				sx={{
					display: "flex",
					width: "100%",
					justifyContent: "space-evenly",
					flexDirection: "column",
				}}
			>
				<Typography
					variant="h2"
					color="initial"
					fontSize="28px"
					marginBottom="20px"
				>
					Změna hesla
				</Typography>
				<Box sx={{ mx: "auto", mt: "40px" }}>
					<ResetPasswordForm
						initialValues={initialValues}
						onSubmit={handleChangePasswordFormSubmit}
					/>
				</Box>
			</Box>
		</AdminPanel>
	);
}
