import PropTypes from "prop-types"
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Grid,
	Link,
	Typography,
	CardMedia,
} from "@mui/material";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";

/**
 * Card of the news for short preview in homepage. Enables to navigate to the news detail.
 * @param {String} title title of the news
 * @param {Object} description short rich text description of the news.
 * @param {String} cover base64 image cover of the news
 * @param {String} date date of the creation of the news
 * @param {Number} newsId news id
 * @returns {Component} Grid item -> Card
 */
export function NewsCard({ title, description, cover, date, newsId }) {
	
	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};
	
	return (
		<Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
			<Card
				className="grow communityCard"
				elevation={8}
				sx={{
					display: "flex",
					flexDirection: "column",
					borderRadius: "20px",
					height: "100%",
				}}
			>
				<CardMedia
					title={title}
					component="img"
					height="140"
					sx={{objectFit: cover ? "cover" : "contain"}}
					image={cover ? cover : `/img/logo_black.svg`}
				/>
				<CardContent sx={{ width: "100%", my: "10px", py: '0' }}>
					<Typography variant="h6" color="initial" fontWeight="bold">
						{title}
					</Typography>
				</CardContent>
				<CardContent sx={{ width: "100%", py: "0" }}>
					<Typography
						variant="subtitle1"
						color="initial"
					>
						{date}
					</Typography>
				</CardContent>
				<CardContent sx={{ width: "100%", py: "0" }}>
					<Typography
						variant="body1"
						color="initial"
						sx={{
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "-webkit-box",
							WebkitLineClamp: "3",
							LineClamp: "3",
							WebkitBoxOrient: 'vertical',
							"&>*":{
								margin: "0px"
							}
						}}
						dangerouslySetInnerHTML={createMarkup(
							draftToHtml(JSON.parse(description))
						)}
					>
					</Typography>
				</CardContent>
				<CardActions
					sx={{
						display: "flex",
						alignItems: "center",
						width: "100%",
						justifyContent: "flex-end",
						mt: "auto",
					}}
				>
					<Button
						variant="contained"
						color="secondary"
						component={Link}
						href={`/clanky/${newsId}`}
					>
						Celý článek
					</Button>
				</CardActions>
			</Card>
		</Grid>
	);
}

NewsCard.propTypes = {
	cover: PropTypes.any,
	date: PropTypes.any,
	description: PropTypes.any,
	newsId: PropTypes.any,
	title: PropTypes.any
}
