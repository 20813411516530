import { SvgIcon } from "@mui/material";
/**
 * Simple custom SVG for dance icon
 * @returns {Component} SvgIcon
 */
export function DanceIcon() {
	return (
		<SvgIcon
			width="20"
			height="23"
			viewBox="0 0 20 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.2222 2.15C12.2222 3.063 11.4778 3.8 10.5556 3.8C9.63333 3.8 8.88889 3.063 8.88889 2.15C8.88889 1.237 9.63333 0.5 10.5556 0.5C11.4778 0.5 12.2222 1.237 12.2222 2.15ZM6.11111 3.8C5.18889 3.8 4.44444 4.537 4.44444 5.45C4.44444 6.363 5.18889 7.1 6.11111 7.1C7.03333 7.1 7.77778 6.363 7.77778 5.45C7.77778 4.537 7.03333 3.8 6.11111 3.8ZM12.2222 11.5L11.3556 9.025H14.6444L17.0444 7.837C17.4556 7.65 17.6222 7.144 17.4111 6.737C17.3641 6.63921 17.2978 6.55173 17.2161 6.47974C17.1343 6.40775 17.0389 6.35269 16.9353 6.31783C16.8317 6.28297 16.7221 6.269 16.613 6.27676C16.5039 6.28452 16.3975 6.31384 16.3 6.363L15.3889 6.814L14.8444 5.89C14.5222 5.175 13.7333 4.768 12.9556 4.944L10.2111 5.527C9.44444 5.692 8.88889 6.385 8.88889 7.177V7.947L6.18889 9.729H6.2C6.11111 9.806 5.98889 9.905 5.92222 10.037L4.93333 11.984L2.95556 12.963C2.54444 13.15 2.37778 13.667 2.58889 14.074C2.68945 14.2691 2.86398 14.4168 3.0743 14.4849C3.28461 14.5529 3.51359 14.5357 3.71111 14.437L6.17778 13.216L7.33333 10.95L8.88889 12.6C7.77778 15.9 0 20.3 0 20.3C0 20.3 4.44444 22.5 10 22.5C15.5556 22.5 20 20.3 20 20.3C20 20.3 14.4444 15.9 12.2222 11.5ZM15.3889 10.499L15.0333 10.675H13.7L13.7667 10.851C14.3444 11.984 15.1889 13.15 16.1111 14.184L15.5222 10.433L15.3889 10.499Z"
			/>
		</SvgIcon>
	);
}
