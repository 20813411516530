import PropTypes from "prop-types"
import { forwardRef } from 'react';

import { Slide, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

/**
 * Snack bar handles different messages and shows them in a colored strip on the page. Accepts severity and message. 
 * @param {String} snackState info, warning, error or success
 * @param {Boolean} snackOpen State of visibility of the message strip
 * @param {Function} setSnackOpen show/hide message strip
 * @param {String} snackMsg Message showed in a strip
 * @returns {Component} Snackbar
 */
export function SnackBarHandler({
  snackState,
  snackOpen,
  setSnackOpen,
  snackMsg,
}) {
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={24} ref={ref} variant="filled" {...props} />;
  });

  const closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={snackOpen}
      autoHideDuration={5000}
      onClose={closeSnackBar}
      TransitionComponent={Slide}
    >
      <Alert
        onClose={closeSnackBar}
        severity={snackState}
      >
        {snackMsg}
      </Alert>
    </Snackbar>
  );
}

SnackBarHandler.propTypes = {
  setSnackOpen: PropTypes.func,
  snackMsg: PropTypes.any,
  snackOpen: PropTypes.any,
  snackState: PropTypes.any
}
