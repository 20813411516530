import { useQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import ModalImage from "react-modal-image";

const NEWS_DETAIL = gql`
	query newsById($news_id: Int!) {
		newsById(news_id: $news_id) {
			news_id
			title
			article
			cover
			date
		}
	}
`;

/**
 * Page with detail of a selected article.
 * Has cover, title, date and description.
 * @returns {Component} Box, ModalImage and Typography
 */
export function NewsDetailPage() {
	const params = useParams();

	const { data } = useQuery(NEWS_DETAIL, {
		variables: {
			news_id: parseInt(params.news_id),
		},
	});

	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-start",
				alignSelf: "flex-start",
				padding: "0px",
				gap: "10px",
				width: "95%",
				maxWidth: "1200px",
				mx: "auto",
				my: "50px",
			}}
		>
			{data && (
				<>				
					<Box sx={{display: 'flex', flexDirection: 'row', mx: 'auto'}}>
					<ModalImage
						className="newsCover"
						small={data.newsById.cover}
						large={data.newsById.cover}
						alt={data.newsById.title}
						loading="lazy"
					/>

					</Box>
					
					<Typography variant="h2" color="initial">
						{data.newsById.title}
					</Typography>
					<Typography
						variant="body1"
						color="initial"
						bgcolor="inherit"
					>
						{data.newsById.date}
					</Typography>
					<Typography
						sx={{
							"& p,span": {
								bgcolor: "inherit !important",
							},
						}}
						variant="body1"
						color="initial"
						dangerouslySetInnerHTML={createMarkup(
							draftToHtml(JSON.parse(data.newsById.article))
						)}
					></Typography>
				</>
			)}
		</Box>
	);
}
