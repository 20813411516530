import PropTypes from "prop-types"
import {
  Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";

/**
 * Dialog to confirm the decision to cancel an event.
 * @param {String} action function triggered after submiting this form
 * @param {Boolean} held if the event is or not active
 * @param {Boolean} open opened/closed dialog
 * @param {Function} setOpen open/close dialog
 * @param {Number} event_id event id
 * @returns {Component} Dialog
 */
export function ConfirmEventNotHeldDialog({ action, open, setOpen, event_id, held }) {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="xs"
      fullWidth={true}
		>
			<DialogTitle id="alert-dialog-title">
				{"Zrušit lekci"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Přejete si zrušit tuto lekci?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color="secondary"
					variant="contained"
					onClick={() => {
						action(event_id, held);
						handleClose();
					}}
					autoFocus
				>
					Potvrdit
				</Button>
				<Button onClick={handleClose} color="secondary">
					Zrušit
				</Button>
			</DialogActions>
		</Dialog>
	);
}

ConfirmEventNotHeldDialog.propTypes = {
	action: PropTypes.func,
	event_id: PropTypes.any,
	held: PropTypes.any,
	open: PropTypes.any,
	setOpen: PropTypes.func
}
