import PropTypes from "prop-types"
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from '@mui/material';
import { route } from 'src/Routes';


/**
 * Simple informational dialog
 * 
 * @param {String} title title of the dialog
 * @param {String} description description of the dialog
 * @param {Boolean} open useState object getter
 * @param {Object} setOpen useState object setter
 * @returns {Component} Dialog
 */
export function InfoDialog({
  title,
  description,
  open,
  setOpen
}) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{whiteSpace: 'pre-line'}}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{whiteSpace: 'pre-line'}}>
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        
        <Button
          component={Link}
					color="secondary"
					variant="contained"
					href={route.profile()}
					autoFocus
				>
					Přejít na profil
				</Button>
        <Button
          component={Link}
					color="inherit"
					variant="text"
					onClick={()=>{handleClose()}}
				>
					Zrušit
				</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

InfoDialog.propTypes = {
  description: PropTypes.any,
  open: PropTypes.any,
  setOpen: PropTypes.func,
  title: PropTypes.any
}
