import { Box, Grid } from "@mui/material";
import { CourseCard } from "src/molecules";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const COURSE_LIST = gql`
	query getCourses {
		courses {
			course_id
			name
			description
			short_description
			cover
		}
	}
`;

/**
 * Homepage section with grid of available courses to select.
 * More button sends user to courses page and scrolls to the desired section.
 * @returns {Component} Grid layout of course cards - homepage courses section
 */
export function HomepageCourses() {
	const courseListQuery = useQuery(COURSE_LIST);

	return (
		<Box >
			<Grid
				container
				sx={{
					height: "100%",
					scrollMarginTop: "100px",
					my: "50px",
				}}
				rowSpacing={4}
				id="Kurzy"
			>
				{courseListQuery.data &&
					courseListQuery.data.courses.map((course) => (
						<CourseCard
							key={course.course_id}
							courseName={course.name}
							id={course.course_id}
							description={course.short_description}
							img={course.cover}
							courseId={course.course_id}
						/>
					))}
			</Grid>
		</Box>
	);
}
