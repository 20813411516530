import PropTypes from "prop-types"
import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

/**
 * Theme provider/color pallette
 * @param {Element} children whole page is wrapped to this pallette
 * @returns {Component} Themeprovider
 */
export function GlobalTheme({ children }) {
  const theme = createTheme({
    palette: {
      primary: {
        light: '#FFCD39',
        main: '#FFD700',
        dark: '#9B7400',
        contrastText: '#000000',
      },
      secondary: {
        light: '#326AAD',
        main: '#0D4281',
        dark: '#063266',
        contrastText: '#ffffff',
      },
      tercial: {
        light: '#F1751A',
        main: '#C55604',
        dark: '#773300',
        contrastText: '#ffffff'
      },
      neutral: {
        light: '#d6d6d6',
        main: '#ffffff',
        dark: '#737373',
        contrastText: '#000000',
      },
      response: {
        success: '#28a745',
        info: '#17a2b8',
        error: '#dc3545',
        contrastText: '#000000',
      },
      wine: {
        main: '#A52626',
        contrastText: '#ffffff',
      },
      basic: {
        main: '#ffffff',
        dark: '#000000'
      },
      type: 'light',
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

GlobalTheme.propTypes = {
  children: PropTypes.any
}
