
import { Box } from "@mui/system";

/**
 * Set of cards with information about lecturers.
 * @param {Class} props other properties/classes defined in parent Component 
 * @returns {Component} Boxes
 */
export function LecturerCard({ ...props }) {
	return (
		<Box className="appCards" id={props.key} sx={{flex: '1 0 21%', mx: '10px', mt: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', flexWrap: 'wrap'}}>
			<Box
				sx={{
					display: "flex",
					borderRadius: "100%",
					flexDirection: "column",
					width: "250px",
					height: "250px",
					backgroundImage: `url(${props.image})`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center",
					boxShadow: "16",
					mb: "50px",
					mx: "auto",
					flexWrap: "wrap"
				}}
			/>
			<Box
				sx={{
					textAlign: "center",
					fontSize: "1.2em",
					fontWeight: "bold",
				}}
			>
				{props.name}
			</Box>
			<Box
				sx={{
					overflow: "hidden",
					textOverflow: "ellipsis",
					display: "-webkit-box",
					WebkitLineClamp: "3",
					LineClamp: "3",
					WebkitBoxOrient: "vertical",
					"&>*": {
						margin: "0px",
					},
					maxWidth: '250px'
				}}
				dangerouslySetInnerHTML={props.description}
			></Box>
		</Box>
	);
}
