import { Box } from "@mui/system";
import { PageRouter } from "src/Routes";
import { Navigation } from "./Navigation";
import { Footer } from ".";

/**
 * Gives the page some global properties and possibility to route through pages
 * @returns {Component} Page wrapper
 */
export function PageWrapper() {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				backgroundColor: "#FAF8F7",
				minHeight: "80vh",
				mx: "auto",
				width: "100%",
			}}
		>
			<Navigation />
			<Box sx={{ minHeight: "inherit" }} component="main">
				<PageRouter />
			</Box>
			<Footer />
		</Box>
	);
}
