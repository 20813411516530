import PropTypes from "prop-types"
import {
	Document,
	Font,
	Page,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";
import { convertFromRaw } from "draft-js";
import n2wordsCZ from 'n2words/lib/i18n/CZ.mjs'

/**
 * Component for generating PDF Invoices. Gets the data, chunks objects into groups of 3 and sets them in the PDF file.
 * It is very similar InvoiceOrders.js. The difference is in names of entity attributes.
 * Invoice has no other special data so it is possible to make one universal component later on.
 * @param {Object} reservations data object imported from parent. Obtains every Invoice which is supposed to be displayed.
 * @returns {Component} Document
 */
export function Invoice({ reservations }) {
	Font.register({
		family: "Roboto",
		fonts: [
			{
				src: "/fonts/RobotoMono-Regular.ttf",
			},
			{
				src: "/fonts/RobotoMono-Italic.ttf",
				fontStyle: "italic",
			},
			{
				src: "/fonts/RobotoMono-Bold.ttf",
				fontStyle: "bold",
				fontWeight: "900",
			},
			{
				src: "/fonts/RobotoMono-BoldItalic.ttf",
				fontStyle: "bold-italic",
				fontWeight: "900",
			},
		],
	});

	const styles = StyleSheet.create({
		body: {
			paddingTop: 10,
			paddingBottom: 10,
			paddingHorizontal: 35,
			fontFamily: "Roboto",
			fontSize: "10px",
		},
		title: {
			marginBottom: "5px",
			fontSize: "12px",
			fontFamily: "Roboto",
			fontStyle: "bold",
		},
		companyInfo: {
			marginLeft: 20,
			fontSize: "10px",
		},
		company: {
			border: "2px solid black",
			borderRadius: "15px",
			padding: 5,
			width: "49%",
			fontSize: "16px",
		},
		DPH: {
			fontFamily: "Roboto",
			fontStyle: "bold-italic",
		},
		info: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
		},
		courseName: {
			marginLeft: 20,
			fontSize: "10px",
		},
		subtitle: {
			fontFamily: "Roboto",
			fontStyle: "bold",
			fontSize: "11px",
		},
		price: {
			fontSize: "10px",
		},
	});

	const chunks = (arr, chunkSize) => {
		const res = [];
		while (arr.length > 0) {
			const chunk = arr.splice(0, chunkSize);
			res.push(chunk);
		}
		return res;
	};

	return (
		<Document>
			{chunks(reservations, 3).map((chunk, index) => (
				<Page style={styles.body} key={index}>
					{chunk.map((reservation, index) => (
						<View key={index} style={{ marginBottom: "5px", marginTop: "20px" }}>
							<Text style={styles.title}>
								Příjmový daňový doklad č.{" "}
								{
									new Date(parseInt(reservation.start_date))
										.toDateString()
										.match(/\d{2}(\d{2})/)[1]
								}
								{parseInt(reservation.reservation_id) < 10
									? "000" + reservation.reservation_id
									: parseInt(reservation.reservation_id) < 100
									? "00" + reservation.reservation_id
									: parseInt(reservation.reservation_id) <
									  1000
									? "0" + reservation.reservation_id
									: reservation.reservation_id}{" "}
								ze dne{" "}
								{new Date(
									parseInt(reservation.start_date)
								).toLocaleDateString("cs")}
							</Text>
							<View style={styles.info}>
								<View style={styles.company}>
									<Text style={styles.subtitle}>Firma:</Text>
									<Text style={styles.companyInfo}>
										Taneční studio "Ká", z.s.
									</Text>
									<Text style={styles.companyInfo}>
										F.V.Heka 874
									</Text>
									<Text style={styles.companyInfo}>
										561 51 Letohrad
									</Text>
									<Text style={styles.companyInfo}>
										IČ: 05273951
									</Text>
									<Text style={styles.companyInfo}>
										DIČ: CZ05273951
									</Text>
									<Text
										style={[styles.companyInfo, styles.DPH]}
									>
										Není plátce DPH
									</Text>
									<Text
										style={[styles.companyInfo]}
									>
										Zapsáno dne 17. 08. 2016 ve spolkovém
										rejstříku vedeném u Krajského soudu v
										Hradci Králové, oddíl L, vložka 10832
									</Text>
								</View>
								<View style={styles.company}>
									<Text style={styles.subtitle}>
										Příjemce:
									</Text>
									<Text style={styles.companyInfo}>
										{reservation.first_name}{" "}
										{reservation.last_name}
									</Text>
									<Text style={styles.companyInfo}>
										{reservation.street}{" "}
										{reservation.house_number}
									</Text>
									<Text style={styles.companyInfo}>
										{reservation.city}{" "}
										{reservation.zip_code}
									</Text>
									<Text style={styles.subtitle}>
										Účel platby:
									</Text>
									<Text style={styles.courseName}>
										Členský příspěvek -{" "}
										{reservation.course_id < 4
											? "Kurz společenského tance"
											: `${convertFromRaw(
													JSON.parse(reservation.name)
											  ).getPlainText()}`}
									</Text>
								</View>
							</View>
							<View
								style={{
									marginTop: "10px",
									marginLeft: "10px",
									fontSize: "16px",
								}}
							>
								<Text style={styles.price}>Cena: 1.600 Kč</Text>
								<Text style={styles.price}>
									Slovy: {n2wordsCZ(1600).replace(/\s/g, '')} korun českých
								</Text>
							</View>
							<View
								style={{
									marginTop: "10px",
									marginBottom: "10px",
									marginLeft: "10px",
									fontSize: "16px",
								}}
							>
								<Text style={styles.price}>Přijal(a):</Text>
							</View>
						</View>
					))}
				</Page>
			))}
		</Document>
	);
}

Invoice.propTypes = {
	reservations: PropTypes.any
}
