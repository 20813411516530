
import { Box, Paper } from "@mui/material";
import { RenewPasswordMailTemplate } from "src/templates/RenewPasswordMailTemplate";

/**
 * Page with renew password template.
 * @returns {Component} Page with renew password template
 */
export function RenewPwdMailPage() {
	return (
		<Box>
			<Paper
				elevation={24}
				sx={{
					width: "100%",
					maxWidth: "600px",
					p: "50px",
					px: "10px",
					borderRadius: "20px",
					alignSelf: "center",
					mx: "auto",
					bgcolor: "Snow",
					mt: "70px",
				}}
			>
				<RenewPasswordMailTemplate />
			</Paper>
      
		</Box>
	);
}
