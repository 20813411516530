import { useCallback, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

import { ResetPasswordForm } from "src/templates";
import { Box, Container, Paper } from "@mui/material";

const RESET_PASSWORD_MUTATION = gql`
	mutation setNewPassword(
		$hash: String!
		$password: String!
		$passwordAgain: String!
	) {
		setNewPassword(
			hash: $hash
			password: $password
			passwordAgain: $passwordAgain
		) {
			email
		}
	}
`;

const initialValues = {
	password: "",
	passwordAgain: "",
};

/**
 * Page to reset password if it was forgotten by user.
 * @returns {Component} Reset password form
 */
export function ResetPasswordPage() {
	const { hash } = useParams();

	const [successMessage, setSuccessMessage] = useState(null);

	const [errorMessage, setErrorMessage] = useState(null);

	const [resetPasswordRequest, resetPasswordRequestState] = useMutation(
		RESET_PASSWORD_MUTATION,
		{
			onCompleted: () => {
				setSuccessMessage("Vaše heslo bylo změněno.");
			},
			onError: (error) => {
				setErrorMessage(error);
			},
		}
	);

	const handleResetPasswordFormSubmit = useCallback(
		(values) => {
			resetPasswordRequest({
				variables: {
					hash: hash,
					password: values.password,
					passwordAgain: values.passwordAgain,
				},
			});
		},
		[hash, resetPasswordRequest]
	);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					borderRadius: "15px",
					mx: "auto",
					mt: "70px",
				}}
			>
				<Paper
					elevation={24}
					sx={{
						width: "100%",
						maxWidth: "600px",
						p: "50px",
						px: "10px",
						borderRadius: "20px",
						alignSelf: "center",
						mx: "auto",
						bgcolor: "Snow",
						my: "auto",
					}}
				>
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<Container
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<h1>Obnovení hesla</h1>

							<ResetPasswordForm
								isLoading={resetPasswordRequestState.loading}
								errorMessage={errorMessage}
								onSubmit={handleResetPasswordFormSubmit}
								successMessage={successMessage}
								initialValues={initialValues}
							/>
						</Container>
					</Box>
				</Paper>
			</Box>
		</>
	);
}
