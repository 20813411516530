import PropTypes from "prop-types"
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AccountBalance, Payments } from "@mui/icons-material";
import { useState } from "react";
import { Box } from "@mui/system";

/**
 * Dialog to choose preffered payment method.
 * @param {String} title opened/closed dialog
 * @param {String} description opened/closed dialog
 * @param {Boolean} open opened/closed dialog
 * @param {Function} setOpen open/close dialog
 * @param {Function} action function after submitting dialog
 * @param {Function} setPayment function to set the payment method
 * @param {String} payment the payment method
 * @param {Function} setSnackState function to set snack state Success, Warning, Error, Info
 * @param {Function} setSnackMsg function to set snack msg
 * @param {Function} setSnackOpen function to open/close informational snack message
 * @returns {Component} Dialog
 */
export function PaymentDialog({
	title,
	description,
	open,
	setOpen,
	action,
	setPayment,
	payment,
	setSnackState,
	setSnackOpen,
	setSnackMsg
}) {
	const handleClose = () => {
		setOpen(false);
	};

	const [cashColor, setCashColor] = useState("neutral");
	const [bankColor, setBankColor] = useState("neutral");

	const handlePayment = (payment) => {
		if (payment === "cash") {
			setCashColor("primary");
			setBankColor("neutral");
		} else {
			setCashColor("neutral");
			setBankColor("primary");
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="sm"
			fullWidth={true}
		>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{description}
				</DialogContentText>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-around",
						mt: "15px",
					}}
				>
					<Button
						onClick={() => {
							setPayment("cash");
							handlePayment("cash");
						}}
						color={cashColor}
						variant="contained"
						sx={{ width: "250px" }}
						endIcon={<Payments />}
					>
						Hotově
					</Button>
					<Button
						color={bankColor}
						variant="contained"
						onClick={() => {
							setPayment("bank_transfer");
							handlePayment("bank_transfer");
						}}
						sx={{ width: "250px" }}
						endIcon={<AccountBalance />}
					>
						Bankovním převodem
					</Button>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						if (payment === "" || payment === null || payment === undefined) {
							setSnackState("error");
							setSnackMsg("Zadejte způsob platby");
							setSnackOpen(true);
						}else{
							action();
							handleClose();
						}
					}}
					color="secondary"
					variant="contained"
				>
					Potvrdit
				</Button>
				<Button onClick={handleClose} color="secondary">
					Zrušit
				</Button>
			</DialogActions>
		</Dialog>
	);
}

PaymentDialog.propTypes = {
	action: PropTypes.func,
	description: PropTypes.any,
	open: PropTypes.any,
	payment: PropTypes.string,
	setOpen: PropTypes.func,
	setPayment: PropTypes.func,
	setSnackMsg: PropTypes.func,
	setSnackOpen: PropTypes.func,
	setSnackState: PropTypes.func,
	title: PropTypes.any
}
