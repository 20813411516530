import PropTypes from "prop-types"
import {
	ClickAwayListener,
	Grow,
	MenuList,
	Paper,
	Popper,
} from "@mui/material";
import { DropDownMenuItem } from "src/atoms/DropDownMenuItem";
import { route } from "src/Routes";

/**
 * Component for DropDown menu in header navigation.
 * @param {Boolean} open open/close state of dropdown menu
 * @param {String} anchorRef link to the desired page
 * @param {Function} handleClose useState setter to close dropdown menu
 * @param {Function} handleLogout function to logout user
 * @param {Object} user object with name and role of user
 * @returns {Component} Popper
 */
export function DropDownMenu({
	open,
	anchorRef,
	handleClose,
	handleLogout,
	user,
}) {
	return (
		<Popper
			open={open}
			anchorEl={anchorRef.current}
			role={undefined}
			transition
			disablePortal
			placement="bottom-end"
			color="secondary"
		>
			{({ TransitionProps, placement }) => (
				<Grow
					{...TransitionProps}
					style={{
						backgroundColor: "#FFD700",
						marginTop: "5px",
						transformOrigin:
							placement === "bottom"
								? "center top"
								: "center bottom",
						width: "100%",
						px: "15px",
					}}
				>
					<Paper elevation={24}>
						<ClickAwayListener onClickAway={handleClose}>
							<MenuList
								id="split-button-menu"
								sx={{ px: "15px" }}
							>
								<DropDownMenuItem href={route.profile()}>
									Profil
								</DropDownMenuItem>
								<DropDownMenuItem href={route.passwordChange()}>
									Změna hesla
								</DropDownMenuItem>
								<DropDownMenuItem href={route.myReservations()}>
									Mé kurzy tanečního studia
								</DropDownMenuItem>
								<DropDownMenuItem
									href={route.myBallroomCourses()}
								>
									Mé kurzy párových tanců
								</DropDownMenuItem>
								<DropDownMenuItem
									href={route.studentAttendancePage()}
								>
									Potvrdit mou účast
								</DropDownMenuItem>
								{(user.role === "admin" || user.role === "lecturer") && (
									<DropDownMenuItem
										href={route.attendancePage()}
									>
										Prezenčka
									</DropDownMenuItem>
								)}
								<DropDownMenuItem onClick={handleLogout}>
									Odhlásit se
								</DropDownMenuItem>
							</MenuList>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper>
	);
}

DropDownMenu.propTypes = {
	anchorRef: PropTypes.shape({
		current: PropTypes.any
	}),
	handleClose: PropTypes.any,
	handleLogout: PropTypes.any,
	open: PropTypes.any,
	user: PropTypes.shape({
		role: PropTypes.string
	})
}
