import PropTypes from "prop-types"
import { gql, useMutation } from "@apollo/client";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Switch,
	TextField,
} from "@mui/material";
import cs from "date-fns/locale/cs";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";

import * as yup from "yup";
import { FormikField } from "src/molecules";

const NEW_EVENT = gql`
	mutation newEvent(
		$course_id: Int!
		$season_id: Int
		$event_start: String!
		$event_end: String!
		$capacity: Int!
		$duration: Int!
		$min_participation: Int
		$comment: String
	) {
		newEvent(
			course_id: $course_id
			season_id: $season_id
			event_start: $event_start
			event_end: $event_end
			capacity: $capacity
			duration: $duration
			min_participation: $min_participation
			comment: $comment
		)
	}
`;

const schema = yup.object().shape({
	duration: yup.number().required("Doba trvání je povinný údaj"),
	capacity: yup.number().required("Kapacita je povinný údaj"),
	min_participation: yup.number().required("Minimální účast je povinný údaj"),
});

/**
 * Dialog to create new event in season.
 * If end date is specified and switch is turned on, user can create multiple events of the same day at once.
 * @param {Boolean} open opened/closed dialog
 * @param {Function} setOpen open/close dialog
 * @param {Function} setSnackState function to set snack state Success, Warning, Error, Info
 * @param {Function} setSnackMsg function to set snack msg
 * @param {Function} setSnackOpen function to open/close informational snack message
 * @param {Function} refetch refetch new data from database
 * @param {Number} course_id course id
 * @param {Number} season_id season id
 * @returns {Component} Dialog
 */
export function NewEventDialog({
	open,
	setOpen,
	setSnackState,
	setSnackMsg,
	setSnackOpen,
	refetch,
	course_id,
	season_id,
}) {
	const [date, setDate] = useState(new Date());
	const [end_date, setEndDate] = useState(new Date());
	const [endDateOpen, setEndDateOpen] = useState(false);

	const [newEventRequest] = useMutation(NEW_EVENT, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.newEvent);
			setSnackOpen(true);
			handleClose();
			refetch();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleNewEvent = useCallback(
		(values) => {
			newEventRequest({
				variables: {
					course_id: parseInt(course_id),
					season_id: season_id !== null ? parseInt(season_id) : null,
					event_start: `${date.getFullYear()}-${(date.getMonth() + 1)
						.toString()
						.padStart(2, "0")}-${date
						.getDate()
						.toString()
						.padStart(2, "0")}${" "}${date
						.getHours()
						.toString()
						.padStart(2, "0")}:${date
						.getMinutes()
						.toString()
						.padStart(2, "0")}`,
					event_end: endDateOpen
						? `${end_date.getFullYear()}-${(end_date.getMonth() + 1)
								.toString()
								.padStart(2, "0")}-${end_date
								.getDate()
								.toString()
								.padStart(2, "0")}`
						: `${date.getFullYear()}-${(date.getMonth() + 1)
								.toString()
								.padStart(2, "0")}-${date
								.getDate()
								.toString()
								.padStart(2, "0")}${" "}${date
								.getHours()
								.toString()
								.padStart(2, "0")}:${date
								.getMinutes()
								.toString()
								.padStart(2, "0")}`,
					capacity: values.capacity,
					duration: values.duration,
					min_participation: values.min_participation,
					comment: null,
				},
			});
		},
		[course_id, date, endDateOpen, end_date, newEventRequest, season_id]
	);

	const handleClose = () => {
		setOpen(false);
		refetch();
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="xs"
			fullWidth={true}
		>
			<Formik
				onSubmit={handleNewEvent}
				initialValues={{
					duration: "",
					capacity: "",
					min_participation: "",
				}}
				validationSchema={schema}
				validateOnBlur={false}
				enableReinitialize={true}
			>
				<Form>
					<DialogTitle id="alert-dialog-title">
						{"Nová lekce"}
					</DialogTitle>
					<DialogContent>
						<Box
							sx={{
								display: "flex",
								width: "100%",
								flexDirection: "column",
							}}
						>
							<FormControl>
								<FormControlLabel
									control={
										<Switch
											checked={endDateOpen}
											onChange={() => {
												setEndDateOpen(!endDateOpen);
												setEndDate(new Date());
											}}
										/>
									}
									label="Zadat období"
								/>
							</FormControl>
							<LocalizationProvider
								dateAdapter={AdapterDateFns}
								locale={cs}
							>
								<DateTimePicker
									mask="__. __. ____ HH:mm"
									label={
										endDateOpen ? "Začátek období" : "Datum"
									}
									value={date}
									onChange={(newValue) => {
										setDate(newValue);
									}}
									renderInput={(params) => (
										<TextField
											required
											color="secondary"
											size="small"
											sx={{
												mt: "15px",
											}}
											{...params}
										/>
									)}
								/>
							</LocalizationProvider>
							{endDateOpen === true && (
								<LocalizationProvider
									dateAdapter={AdapterDateFns}
									locale={cs}
								>
									<DatePicker
										mask="__. __. ____"
										label="Konec období"
										value={end_date}
										onChange={(newValue) => {
											setEndDate(newValue);
										}}
										renderInput={(params) => (
											<TextField
												required
												color="secondary"
												size="small"
												sx={{
													mt: "15px",
												}}
												{...params}
											/>
										)}
									/>
								</LocalizationProvider>
							)}
						</Box>
						<FormikField
							id="duration"
							name="duration"
							label="Doba trvání (v minutách)"
							type="number"
							variant="outlined"
							as="TextField"
							color="secondary"
							size="small"
							sx={{ mt: "15px" }}
						/>
						<FormikField
							id="capacity"
							name="capacity"
							label="Kapacita"
							type="number"
							variant="outlined"
							as="TextField"
							color="secondary"
							size="small"
							sx={{ mt: "15px" }}
						/>
						<FormikField
							id="min_participation"
							name="min_participation"
							label="Minimální účast"
							type="number"
							variant="outlined"
							as="TextField"
							color="secondary"
							size="small"
							sx={{ mt: "15px" }}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							color="secondary"
							variant="contained"
							autoFocus
							type="submit"
						>
							Uložit
						</Button>
						<Button onClick={handleClose} color="secondary">
							Zrušit
						</Button>
					</DialogActions>
				</Form>
			</Formik>
		</Dialog>
	);
}

NewEventDialog.propTypes = {
	course_id: PropTypes.any,
	open: PropTypes.any,
	refetch: PropTypes.func,
	season_id: PropTypes.any,
	setOpen: PropTypes.func,
	setSnackMsg: PropTypes.func,
	setSnackOpen: PropTypes.func,
	setSnackState: PropTypes.func
}
