import { Box } from "@mui/system";
import { Typography, Link, Button } from "@mui/material";
import { AlbumCard } from "src/molecules";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { route } from "src/Routes";

const HOMEPAGE_ALBUMS = gql`
	query homepageAlbums {
		homepageAlbums {
			name
			album_id
			photos {
				photo_id
				photo
			}
		}
	}
`;

/**
 * Album section to display 4 newest albums on Homepage.
 * After clicking on album user is linked to its detail with photos, or he can list all albums.
 * @returns {Component} Flex box of Album cards - Homepage Gallery section
 */
export function HomepageGallery() {
	const { data } = useQuery(HOMEPAGE_ALBUMS);

	return (
		<Box
		sx={{
			my: "50px",
			mx: "auto",
			width: "95%",
			maxWidth: "1800px",
			scrollMarginTop: "120px",
		}}
			id="Galerie"
		>
			<Typography variant="h2" color="initial">
				Galerie
			</Typography>
			<Box
				container
				spacing={2}
				sx={{
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "row",
					alignItems: "center",
					flexWrap: 'wrap'
				}}
				id="CommunityGrid"
			>
				{data &&
					data.homepageAlbums.map((album) => (
						<AlbumCard
							key={album.album_id}
							id={album.album_id}
							title={album.name}
							cover={album.photos[0].photo}
						/>
					))}
			</Box>
			<Button
				variant="contained"
				color="primary"
				sx={{
					filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
					width: "fit-content",
					ml: "auto",
					display: "flex",
				}}
				component={Link}
				href={route.gallery()}
			>
				Další alba
			</Button>
		</Box>
	);
}
