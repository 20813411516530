import PropTypes from "prop-types"
import { useState } from "react";
import classNames from "classnames";
import { Button, IconButton, Box, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { SnackBarHandler } from "./SnackBarHandler";

/**
 * Component used to upload images if there is also needed a preview of uploaded image with possibility to delete the image in preview.
 * Converts file to base64 format.
 * @param {Class} style Parameter to set other css classes
 * @param {Number} id id to set upon the image and input for image control
 * @param {Function} setImage useState setter to save base64 image
 * @param {Class} props other properties defined in Component 
 * @returns {Component} Image upload input
 */
export function ImageUploader({ style, id, setImage, ...props }) {
	const [files, setFile] = useState([]);
  const [url, setUrl] = useState();
	let filesBase64 = [];

	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const handleChange = (e) => {
		let allfiles = [];

		for (let i = 0; i < e.target.files.length; i++) {
			if (e.target.files[i].size > 5e5) {
				setSnackState("error");
				setSnackMsg(
					"Prosím nahrajte obrázek s velikostí menší než 0,5 MB."
				);
				setSnackOpen(true);
				return false;
			}
      setUrl(URL.createObjectURL(
        e.target.files[0]
      ));
			allfiles.push(e.target.files[i]);
		}
    
		updateParentImageArray(allfiles);
		/* if (allfiles.length !== 0) {
			const uploadedImage = allfiles[0];
		} */
	};

	function updateParentImageArray(fileArray) {
		setFile(fileArray);
		fileArray.forEach((file) => {
			getBase64(file);
		});
	}

	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let baseURL = "";
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				baseURL = reader.result;
				filesBase64.push(baseURL);
				resolve(baseURL);
			};
		}).then(async (data) => {
			setImage(data);
		});
	};

	function handleRemove(file) {
		setFile(files.splice(file, 1));
		const newList = files.filter((item) => item !== file);
		updateParentImageArray(newList);
	}

	return (
		<Stack direction="column" spacing={2} justifyContent="center">
			<SnackBarHandler
				snackState={snackState}
				snackMsg={snackMsg}
				snackOpen={snackOpen}
				setSnackOpen={setSnackOpen}
			/>
			<Button
				color="secondary"
				type="button"
				variant="outlined"
        size="large"
				sx={{ mr: 'auto', p: '0' }}
			>
				<label
					style={{
						width: "100%",
						height: "100%",
						cursor: "pointer",
						margin: "0px 5px",
					}}
					onChange={handleChange}
					htmlFor={id}
				>
					<input
						accept="image/*"
						id={id}
						type="file"
						hidden
						className={classNames(props.className)}
					/>
					{files.length > 0 ? (
						<>{files[0].name} </>
					) : (
						<> Nahrát obrázek</>
					)}
				</label>
			</Button>
			{files.length > 0 ? (
				<>
					{files.map((file, key) => (
						<Box
							key={key}
							sx={{
								position: "relative",
								backgroundImage: `url(${url})`,
								width: "100%",
								height: "200px",
								backgroundSize: "cover",
								backgroundRepeat: "no-repeat",
                backgroundPosition: 'center'
							}}
						>
							<IconButton
								sx={{
									position: "absolute",
									top: "0px",
									right: "0px",
								}}
								color="error"
								size="large"
								aria-label="delete"
								id={file.id}
								onClick={() => handleRemove(file)}
							>
								<DeleteIcon fontSize="inherit" />
							</IconButton>
						</Box>
					))}
				</>
			) : null}
		</Stack>
	);
}

ImageUploader.propTypes = {
	id: PropTypes.any,
	setImage: PropTypes.func,
	style: PropTypes.any
}
