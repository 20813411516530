import PropTypes from "prop-types"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box, FormControl, TextField } from "@mui/material";
import cs from "date-fns/locale/cs";
import { useCallback } from "react";
import { Editor } from "react-draft-wysiwyg";

/**
 * Component in article admin section for creating articles.
 * @param {Function}	setNewArticleDate useState setter for date
 * @param {Function}	setNewArticleTitle useState setter for title
 * @param {Function} setNewArticle useState setter for article
 * @param {String}	newArticleTitle title of the article
 * @param {Date}	newArticleDate date object of the article
 * @param {Object}	newArticle editor state => contains formatted text
 * @return {Component} Formik fields
 */
export function ArticleFormFields({
	setNewArticleDate,
	setNewArticleTitle,
  setNewArticle,
	newArticleTitle,
	newArticleDate,
	newArticle,
}) {
	const handleEditorChange = useCallback(
		(state) => {
			setNewArticle(state);
		},
		[setNewArticle]
	);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					mt: "15px",
				}}
			>
				<LocalizationProvider dateAdapter={AdapterDateFns} locale={cs}>
					<DatePicker
						mask="__.__.____"
						label="Datum"
						value={newArticleDate}
						onChange={(newValue) => {
							setNewArticleDate(newValue);
						}}
						renderInput={(params) => (
							<TextField
								color="secondary"
								size="small"
								sx={{
									width: "30%",
								}}
								{...params}
							/>
						)}
					/>
				</LocalizationProvider>
				<TextField
					id="newArticleTitle"
					name="newArticleTitle"
          value={newArticleTitle}
					size="small"
					color="secondary"
					label="Nadpis"
					sx={{ width: "65%" }}
					required
					onChange={(e) => {
						setNewArticleTitle(e.target.value);
					}}
				></TextField>
			</Box>
			<FormControl sx={{ my: "20px", width: "100%" }}>
				<Editor
					id="newArticle"
					name="newArticle"
					label="Popis"
					type="text"
					editorState={newArticle}
					onEditorStateChange={handleEditorChange}
					editorClassName="newsArticleWrapper-class"
					toolbar={{
						options: ["inline", "blockType", "fontSize", "list", "textAlign", "link", "colorPicker", "emoji", "history"],
						inline: {
							superscript: false,
						},
					}}
				/>
			</FormControl>
		</>
	);
}

ArticleFormFields.propTypes = {
	newArticle: PropTypes.any,
	newArticleDate: PropTypes.any,
	newArticleTitle: PropTypes.any,
	setNewArticle: PropTypes.func,
	setNewArticleDate: PropTypes.func,
	setNewArticleTitle: PropTypes.func
}
