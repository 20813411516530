import PropTypes from "prop-types"
import { useLazyQuery, useQuery } from "@apollo/client";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
	Box,
	FormControl,
	Select,
	TextField,
	MenuItem,
	InputLabel,
} from "@mui/material";
import cs from "date-fns/locale/cs";
import { convertFromRaw } from "draft-js";
import gql from "graphql-tag";
import { useCallback, useState } from "react";
import { Editor } from "react-draft-wysiwyg";

const GET_USERS_BY_ROLE = gql`
	query usersByRole($role: Int!) {
		userIdsByRole(role: $role)
	}
`;

const EVENTS_LIST = gql`
	query eventsByDate($date: String) {
		eventsByDate(date: $date) {
			event_id
			course_id
			name
			event_start
			event_date
		}
	}
`;
const COURSES_LIST = gql`
	query courses {
		courses {
			course_id
			name
		}
	}
`;

const SEASONS_LIST = gql`
	query seasonsByCourseId($course_id: Int!) {
		ballroomSeasonsByCourseId(course_id: $course_id) {
			season_id
			season
			course_id
			day
		}
	}
`;

const ATENDEES_LIST = gql`
	query eventReservations($event_id: Int) {
		eventReservations(event_id: $event_id) {
			user_id
			first_name
		}
	}
`;

const COURSE_ATENDEES_LIST = gql`
	query usersByCourse($course_id: Int!) {
		usersByCourse(course_id: $course_id)
	}
`;

const SEASON_ATTENDEES_LIST = gql`
	query usersBySeason($season_id: Int!){
		usersBySeason(season_id: $season_id)
	}
`;

const DAY_MAP = {
	monday: "pondělí",
	tuesday: "úterý",
	wednesday: "středa",
	thursday: "čtvrtek",
	friday: "pátek",
	saturday: "sobota",
	sunday: "neděle",
};

/**
 * 
 * Form fields in announcement center for sending e-mails. Selects recipients and sends Subject, Message and User_Ids list.
 * @public
 * @param {Function} setUser_ids useState setter for selected recipients
 * @param {Function} setAnnouncementSubject  useState setter for subject of an e-mail
 * @param {Function} setMessage useState setter for e-mail message
 * @param {Array} user_ids Selected recipients
 * @param {String} announcementSubject E-mail subject
 * @param {String} message E-mail message
 * @param {Function} setSelectedGroup useState setter for saving information {String} about whom the e-mail was sent.
 * @returns {Component} returns formik fields 
 */

export function AnnouncementFormFields({
	setUser_ids,
	setAnnouncementSubject,
	setMessage,
	user_ids,
	announcementSubject,
	message,
	setSelectedGroup
}) {
	const [selectedRecipients, setSelectedRecipients] = useState("");
	const [event, setEvent] = useState("");
	const [season, setSeason] = useState("");
	const [event_date, setEventDate] = useState(new Date());

	const { data: coursesData } = useQuery(COURSES_LIST);

	const getEventsByDate = useQuery(EVENTS_LIST, {
		variables: {
			date: `${event_date.getFullYear()}-${
				event_date.getMonth() + 1
			}-${event_date.getDate()}`,
		},
	});

	const handleEditorChange = useCallback(
		(state) => {
			setMessage(state);
		},
		[setMessage]
	);

	const [usersByRoleQuery] = useLazyQuery(GET_USERS_BY_ROLE, {
		onCompleted: (data) => {
			setUser_ids(data.usersByRole);
		},
	});

	const [usersByLectureQuery] = useLazyQuery(ATENDEES_LIST, {
		onCompleted: async (data) => {
			let ids = [];
			await data.eventReservations.forEach((reservation) => {
				ids.push(reservation.user_id);
			});
			setUser_ids(ids);
		},
	});
	
	const [usersBySeasonQuery] = useLazyQuery(SEASON_ATTENDEES_LIST, {
		onCompleted: async (data) => {
			let ids = [];
			await data.usersBySeason.forEach((reservation) => {
				ids.push(reservation.user_id);
			});
			setUser_ids(data.usersBySeason);
			console.log(user_ids);
		},
	});


	/**
	 * Query to obtain attendees of selected course
	 * @param {Number} course_id course_id saved in useState
	 * @public
	 */
	const [usersByCourseQuery] = useLazyQuery(COURSE_ATENDEES_LIST, {
		onCompleted: async (data) => {
			setUser_ids(data.usersByCourse);
		},
	});

	const getSeasonsByCourse = useQuery(SEASONS_LIST, {
		variables: {
			course_id: parseInt(event)
		},
	});

	const handleRecipients = useCallback(
		async (recipients) => {
			setSelectedRecipients(recipients);
			if (
				recipients !== "lecture" &&
				recipients !== "course" &&
				recipients !== "season"
			) {
				if(recipients === 3){
					setSelectedGroup("Všichni")
				}else{
					setSelectedGroup("Lektoři")
				}
				await usersByRoleQuery({
					variables: {
						role: recipients,
					},
				});
			}
		},
		[setSelectedGroup, usersByRoleQuery]
	);

	const handleLectureRecipients = useCallback(
		async (event, context) => {
			usersByLectureQuery({
				variables: {
					event_id: parseInt(event),
				},
			});
			setSelectedGroup(document.getElementById("announcementDatePick").value + context);
		},
		[setSelectedGroup, usersByLectureQuery]
	);

	const handleCourseRecipients = useCallback(
		async (id, context) => {
			await usersByCourseQuery({
				variables: {
					course_id: parseInt(id),
				},
			});
			setSelectedGroup(context);
		},
		[setSelectedGroup, usersByCourseQuery]
	);


	const handleSeasonRecipients = useCallback(
		async (id, context) => {
			await usersBySeasonQuery({
				variables: {
					season_id: parseInt(id),
				},
			});
			setSelectedGroup(document.getElementById("course-event-select").textContent + context);
		},
		[setSelectedGroup, usersBySeasonQuery]
	);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					mt: "15px",
				}}
			>
				<Select
					sx={{ width: "30%" }}
					size="small"
					value={selectedRecipients}
					onChange={(e) => {
						handleRecipients(e.target.value);
					}}
				>
					<MenuItem value={3}>Všem</MenuItem>
					<MenuItem value={2}>Lektorům</MenuItem>
					<MenuItem value={"course"}>Kurzu</MenuItem>
					<MenuItem value={"season"}>Sezóně</MenuItem>
					<MenuItem value={"lecture"}>Lekci</MenuItem>
				</Select>
			</Box>
			{selectedRecipients === "lecture" && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						mt: "15px",
					}}
				>
					<LocalizationProvider
						dateAdapter={AdapterDateFns}
						locale={cs}
					>
						<DatePicker
							mask="__.__.____"
							label="Datum konání"
							value={event_date}
							onChange={(newValue) => {
								setEventDate(newValue);
								setEvent("");
							}}
							renderInput={(params) => (
								<TextField
									id="announcementDatePick"
									sx={{ width: "30%" }}
									color="secondary"
									size="small"
									{...params}
								/>
							)}
						/>
					</LocalizationProvider>
					<FormControl
						fullWidth
						color="secondary"
						size="small"
						sx={{ width: "65%" }}
					>
						<InputLabel id="event-select-label">Lekce</InputLabel>
						<Select
							labelId="lecture-event-select-lbl"
							id="lecture-event-select"
							value={event}
							label="Lekce"
							onChange={(e) => {
								handleLectureRecipients(e.target.value, e.explicitOriginalTarget.textContent);
								setEvent(e.target.value);
							}}
						>
							<MenuItem value="">
								<em>Žádný</em>
							</MenuItem>
							{getEventsByDate.data &&
								getEventsByDate.data.eventsByDate.map(
									(event) => (
										<MenuItem
											key={event.event_id}
											value={event.event_id}
										>
											{
												event.event_start.match(
													/\s(\d{0,2}:\d{0,2})/
												)[0]
											}
											{" - "}
											{convertFromRaw(
												JSON.parse(event.name)
											).getPlainText()}
										</MenuItem>
									)
								)}
						</Select>
					</FormControl>
				</Box>
			)}
			{(selectedRecipients === "course" ||
				selectedRecipients === "season") && (
				<FormControl
					fullWidth
					color="secondary"
					size="small"
					sx={{ mt: "15px" }}
				>
					<InputLabel id="input-event-select-label">Kurz</InputLabel>
					<Select
						labelId="course-event-select-lbl"
						id="course-event-select"
						value={event}
						label="Kurz"
						onChange={async (e) => {
							await handleCourseRecipients(e.target.value, e.explicitOriginalTarget.textContent);
							setEvent(e.target.value);
						}}
					>
						<MenuItem value="">
							<em>Žádný</em>
						</MenuItem>
						{coursesData &&
							coursesData.courses.map((course) => (
								<MenuItem
									key={course.course_id}
									value={course.course_id}
								>
									{convertFromRaw(
										JSON.parse(course.name)
									).getPlainText()}
								</MenuItem>
							))}
					</Select>
				</FormControl>
			)}
			{selectedRecipients === "season" && (
				<FormControl
					fullWidth
					color="secondary"
					size="small"
					sx={{ mt: "15px" }}
				>
					<InputLabel id="event-select-label">Sezóna</InputLabel>
					<Select
						labelId="season-event-select-lbl"
						id="season-event-select"
						value={season}
						label="Sezóna"
						onChange={(e) => {
							handleSeasonRecipients(e.target.value, e.explicitOriginalTarget.textContent);
							setSeason(e.target.value);
						}}
					>
						<MenuItem value="">
							<em>Žádná</em>
						</MenuItem>
						{getSeasonsByCourse.data &&
							getSeasonsByCourse.data.ballroomSeasonsByCourseId.map((season) => (
								<MenuItem
									key={season.season_id}
									value={season.season_id}
								>
									{season.season}{" - "}
									{DAY_MAP[season.day]}
								</MenuItem>
							))}
					</Select>
				</FormControl>
			)}
			<TextField
				id="newAnnouncementTitle"
				name="newAnnouncementTitle"
				value={announcementSubject}
				size="small"
				color="secondary"
				label="Předmět"
				sx={{ width: "100%", mt: "20px" }}
				required
				onChange={(e) => {
					setAnnouncementSubject(e.target.value);
				}}
			></TextField>
			<FormControl sx={{ my: "20px", width: "100%" }}>
				<Editor
					id="newArticle"
					name="newArticle"
					label="Zpráva"
					type="text"
					editorState={message}
					onEditorStateChange={handleEditorChange}
					editorClassName="newsArticleWrapper-class"
					toolbarHidden
				/>
			</FormControl>
		</>
	);
}

AnnouncementFormFields.propTypes = {
	/**
	 * Subject of e-mail 
	*/
	announcementSubject: PropTypes.string,
	/**
	 * Message of e-mail 
	*/
	message: PropTypes.string,
	/**
	 * Subject of e-mail useState setter
	*/
	setAnnouncementSubject: PropTypes.func,
	/**
	 * useState setter for e-mail message
	*/
	setMessage: PropTypes.func,
	/**
	 * useState setter for selected group -> saves information about whom the e-mail was sent 
	*/
	setSelectedGroup: PropTypes.func,
	/**
	 * useState setter for user ids by selected group in form
	*/
	setUser_ids: PropTypes.func,
	/**
	 * list of user ids to be sent the e-mail to
	*/
	user_ids: PropTypes.array
}
