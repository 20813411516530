import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const EVENT_INFO = gql`
	query eventInfo($event_id: Int!) {
		eventById(event_id: $event_id) {
			event_id
			event_start
			event_end
			capacity
			duration
			comment
      event_date
		}
	}
`;

const DAY_MAP = [
  "neděle",
	"pondělí",
	"úterý",
	"středa",
	"čtvrtek",
	"pátek",
	"sobota",
];

/**
 * Confirmation information whether the alternative event of ballroom dance season is the one correctly picked. 
 * @param {Number} pickedEventID id of picked alternative event of ballroom dance season.
 * @returns {Component} Box => set of Typography components
 */
export function AlternativeDatePickedSummary({
	pickedEventID,
}) {
	const { data } = useQuery(EVENT_INFO, {
		variables: {
			event_id: parseInt(pickedEventID),
		},
	});

	return (
		<Box sx={{ minWidth: "600px", mt: "20px" }}>
			{data && (
				<>
					<Typography variant="body1" fontSize="20px" color="initial">
						Chcete požádat o zápis na tento termín?
					</Typography>
					<Typography variant="body1" color="initial">
						{DAY_MAP[(new Date(parseInt(data.eventById.event_date))).getUTCDay()]}{" "}
						{data.eventById.event_start}
					</Typography>
					<Typography variant="body1" color="initial">
						Délka hodiny: {data.eventById.duration} minut
					</Typography>
					<Typography variant="body1" color="initial">
						{data.eventById.comment}
					</Typography>
					<Typography variant="body1" color="initial" fontSize="18px" marginTop="20px">
						Po schválení žádosti najdete nový termín zde v rozpisu Vašich hodin.
					</Typography>
				</>
			)}
		</Box>
	);
}

AlternativeDatePickedSummary.propTypes = {
	pickedEventID: PropTypes.any
}
