import PropTypes from "prop-types"
import { Button, ButtonGroup, Grid, Link } from '@mui/material';
import React from 'react';

/**
 * If no one is signed in, button group with sign in/sign up buttons is shown.
 * @param {String} anchorRef React.useRef()
 * @param {Boolean} matches useMediaQuery(min-width:600px)
 * @returns {Component} Grid of Button group
 */
export function NavSignButtons({ anchorRef, matches }) {
  return (
    <Grid
      item
      sx={{
        display: 'flex',
        alignSelf: 'center',
      }}
    >
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        orientation={`${matches ? `horizontal` : `vertical`}`}
      >
        <Button
          variant="contained"
          color="secondary"
          href="/auth/signup"
          component={Link}
        >
          Založit účet
        </Button>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          href="/auth/signin"
        >
          Přihlášení
        </Button>
      </ButtonGroup>
    </Grid>
  );
}

NavSignButtons.propTypes = {
  anchorRef: PropTypes.any,
  matches: PropTypes.any
}
