import {
	Button,
	Container,
	IconButton,
	Link,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useState } from "react";
import jwt_decode from "jwt-decode";
import { useAuth } from "src/utils/auth";
import { AdminPanel } from "src/templates";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { ArrowForwardIos, Cancel } from "@mui/icons-material";
import { SnackBarHandler } from "src/atoms";
import { DeleteDialog } from "../Dialogs/DeleteDialog";

const DAY_MAP = {
	monday: "pondělí",
	tuesday: "úterý",
	wednesday: "středa",
	thursday: "čtvrtek",
	friday: "pátek",
	saturday: "sobota",
	sunday: "neděle",
};

const REMOVE_BALLROOM_RESERVATION = gql`
	mutation reservationRemoval(
		$reservation_id: Int!
		$user_id: Int!
		$season_id: Int!
	) {
		removeBallroomRegistration(
			reservation_id: $reservation_id
			user_id: $user_id
			season_id: $season_id
		)
	}
`;

const MY_ACTIVE_BALLROOM_SEASONS = gql`
	query myBallroomregistrations($user_id: Int!) {
		userActiveBallroomSeasons(user_id: $user_id) {
			season_id
			reservation_id
			course_id
			season
			start_date
			end_date
			day
			time
			capacity
			duration
			active
			start_time
			end_time
			women_count
			men_count
		}
	}
`;

/**
 * Profile section that enables user to view, select or completely sign out from ballroom seasons.
 * @returns {Component} Table with seasons of ballroom dances
 */
export function MyBallroomCourses() {
	const { token } = useAuth();
	const decoded = token && jwt_decode(token);
	const [user_id] = useState(decoded?.user_id);

	const mobile = useMediaQuery("(max-width:600px)");

	const [openDialog, setOpenDialog] = useState(false);
	const [dialogID, setdialogID] = useState();
	const [seasonID, setSeasonID] = useState();
	const [deleteFunction, setDeleteFunction] = useState();

	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const ballroomSeasonsQuery = useQuery(MY_ACTIVE_BALLROOM_SEASONS, {
		variables: {
			user_id: parseInt(user_id),
		},
	});

	const [removeBallroomRegistrationRequest] = useMutation(
		REMOVE_BALLROOM_RESERVATION,
		{
			onCompleted: (data) => {
				ballroomSeasonsQuery.refetch();
				setSnackState("success");
				setSnackMsg(data.removeBallroomRegistration);
				setSnackOpen(true);
			},
			onError: (error) => {
				setSnackState("error");
				setSnackMsg(error.message);
				setSnackOpen(true);
			},
		}
	);

	const handleRemoveBallroomRegistration = useCallback(
		(reservation_id, user_id, season_id) => {
			removeBallroomRegistrationRequest({
				variables: {
					reservation_id: parseInt(reservation_id),
					user_id: parseInt(user_id),
					season_id: parseInt(season_id),
				},
			});
		},
		[removeBallroomRegistrationRequest]
	);

	return (
		<AdminPanel>
			<Box
				sx={{
					display: "flex",
					width: "100%",
					justifyContent: "space-evenly",
					flexDirection: "column",
				}}
			>
				<Typography
					variant="h2"
					color="initial"
					fontSize="28px"
					marginBottom="20px"
				>
					Mé hodiny společenských tanců
				</Typography>
				<Box
					sx={{
						overflow: "hidden",
						overflowY: "scroll",
						overflowX: "scroll",
						maxHeight: "600px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							width: "100%",
							justifyContent: "space-evenly",
							flexDirection: "column",
						}}
					>
						<Container maxWidth="xl">
							<TableContainer sx={{ maxHeight: "400px" }}>
								<Table
									sx={{
										borderSpacing: "0 15px",
										borderCollapse: "separate",
										px: "10px",
										overflow: "hidden",
										overflowY: "scroll",
										overflowX: "scroll",
									}}
									aria-label="simple table"
									size="small"
								>
									<TableHead>
										<TableRow>
											<TableCell align="left"></TableCell>
											<TableCell
												sx={{
													display: mobile
														? "none"
														: "table-cell",
												}}
											>
												Ročník
											</TableCell>
											<TableCell align="center">
												Termín
											</TableCell>
											<TableCell
												align="center"
												sx={{
													display: mobile
														? "none"
														: "table-cell",
												}}
											>
												Začátek kurzu
											</TableCell>
											<TableCell
												align="center"
												sx={{
													display: mobile
														? "none"
														: "table-cell",
												}}
											>
												Konec kurzu
											</TableCell>
											<TableCell
												align="center"
												sx={{
													display: mobile
														? "none"
														: "table-cell",
												}}
											>
												Kapacita Ženy
											</TableCell>
											<TableCell
												align="center"
												sx={{
													display: mobile
														? "none"
														: "table-cell",
												}}
											>
												Kapacita Muži
											</TableCell>
											<TableCell align="right"></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{ballroomSeasonsQuery.data &&
											ballroomSeasonsQuery.data
												.usersReservations !== null &&
											ballroomSeasonsQuery.data.userActiveBallroomSeasons.map(
												(registration) => (
													<TableRow
														key={
															registration.reservation_id
														}
													>
														<TableCell align="left">
															<IconButton
																aria-label="cancel-event"
																onClick={() => {
																	setOpenDialog(
																		true
																	);
																	setdialogID(
																		registration.reservation_id
																	);
																	setSeasonID(
																		registration.season_id
																	);
																	setDeleteFunction(
																		() =>
																			handleRemoveBallroomRegistration
																	);
																}}
																color="error"
															>
																<Cancel />
															</IconButton>
														</TableCell>
														<TableCell
															align="left"
															sx={{
																display: mobile
																	? "none"
																	: "table-cell",
															}}
														>
															{
																registration.season
															}
														</TableCell>
														<TableCell align="center">
															{
																DAY_MAP[
																	registration
																		.day
																]
															}
															<br></br>
															{
																registration.start_time
															}
															{" - "}
															{
																registration.end_time
															}
														</TableCell>
														<TableCell
															align="center"
															sx={{
																display: mobile
																	? "none"
																	: "table-cell",
															}}
														>
															{new Date(
																parseInt(
																	registration.start_date
																)
															).toLocaleDateString(
																"cs-CZ"
															)}
														</TableCell>
														<TableCell
															align="center"
															sx={{
																display: mobile
																	? "none"
																	: "table-cell",
															}}
														>
															{new Date(
																parseInt(
																	registration.end_date
																)
															).toLocaleDateString(
																"cs-CZ"
															)}
														</TableCell>
														<TableCell
															align="center"
															sx={{
																display: mobile
																	? "none"
																	: "table-cell",
															}}
														>
															{
																registration.women_count
															}
															/
															{
																registration.capacity
															}
														</TableCell>
														<TableCell
															align="center"
															sx={{
																display: mobile
																	? "none"
																	: "table-cell",
															}}
														>
															{
																registration.men_count
															}
															/
															{
																registration.capacity
															}
														</TableCell>
														<TableCell align="center">
															{!mobile ? (
																<Button
																	component={
																		Link
																	}
																	variant="contained"
																	endIcon={
																		<ArrowForwardIos />
																	}
																	aria-label="go to detail"
																	href={`/me-spolecenske-tance/${registration.season_id}/${registration.course_id}/${registration.reservation_id}`}
																>
																	Detail
																</Button>
															) : (
																<IconButton
																	component={
																		Link
																	}
																	variant="contained"
																	aria-label="go to detail"
																	href={`/me-spolecenske-tance/${registration.season_id}/${registration.course_id}/${registration.reservation_id}`}
																>
																	<ArrowForwardIos />
																</IconButton>
															)}
														</TableCell>
													</TableRow>
												)
											)}
									</TableBody>
								</Table>
							</TableContainer>
						</Container>
					</Box>
				</Box>
				<DeleteDialog
					open={openDialog}
					setOpen={setOpenDialog}
					id={dialogID}
					deleteFunction={deleteFunction}
					season_id={seasonID}
					user_id={user_id}
				/>
				<SnackBarHandler
					snackState={snackState}
					snackMsg={snackMsg}
					snackOpen={snackOpen}
					setSnackOpen={setSnackOpen}
				/>
			</Box>
		</AdminPanel>
	);
}
