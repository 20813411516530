import {
	Button,
	Link,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { AdminPanel } from "src/templates";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { ArrowForwardIos } from "@mui/icons-material";
import { convertFromRaw } from "draft-js";

const STUDIO_COURSES = gql`
	query studioCourses {
		studioCourses {
			course_id
			name
		}
	}
`;
/**
 * Profile section with list of dance studio courses to view details of.
 * @returns {Component} Table with dance studio courses.
 */
export function MyReservations() {
	const { data } = useQuery(STUDIO_COURSES);

	return (
		<AdminPanel>
			<Box
				sx={{
					display: "flex",
					width: "100%",
					justifyContent: "space-evenly",
					flexDirection: "column",
				}}
			>
				<Typography
					variant="h2"
					color="initial"
					fontSize="28px"
					marginBottom="20px"
				>
					Kurzy tanečního studia
				</Typography>
				<TableContainer sx={{ maxHeight: "400px" }}>
					<Table
						sx={{
							borderSpacing: "0 15px",
							px: "10px",
						}}
						size="medium"
					>
						<TableHead>
							<TableRow>
								<TableCell>Název</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data &&
								data.studioCourses.map((course) => (
									<TableRow
										key={course.course_id}
										
									>
										<TableCell align="left" sx={{fontSize: '1.2em'}}>
											{convertFromRaw(
												JSON.parse(course.name)
											).getPlainText()}
										</TableCell>
										<TableCell align="right">
											<Button
												component={Link}
												variant="contained"
												endIcon={<ArrowForwardIos />}
												aria-label="go to detail"
												href={`/kurzy-tanecniho-studia/${course.course_id}`}
											>
												Detail
											</Button>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</AdminPanel>
	);
}
