import PropTypes from "prop-types"
import { Select, Stack } from '@mui/material';
import React from 'react';

import { TextField, ErrorMessage, FormControl, Checkbox } from 'src/atoms/';


/**
 * Component which based on the input creates unified form field. 
 * @param {Int} id id of the form field
 * @param {String} label Label of the input field
 * @param {String} error Possible error message if incorrectly set information is filled in field
 * @param {String} as TextField, FormControl, Checkbox, Select
 * @param {Class} props other properties/classes defined in Component
 * @returns {Component} Stack => InputComponent (which changes based on 'as' input)
 */
export function Field({ id, label, error, as, ...props }) {
  const InputComponent =
    as === 'TextField' ? TextField : as === 'FormControl' ? FormControl : as === 'Checkbox' ? Checkbox : as === 'Select' ? Select : null;
  return (
    <Stack>
      <InputComponent id={id} label={label} error={!!error} {...props} />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Stack>
  );
}

Field.propTypes = {
  as: PropTypes.string,
  error: PropTypes.any,
  id: PropTypes.any,
  label: PropTypes.any
}
