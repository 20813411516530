import PropTypes from "prop-types"
import {
	Avatar,
	Badge,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import * as yup from "yup";
import { useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { CameraAlt } from "@mui/icons-material";
import { FormikField } from "src/molecules";
import { DeleteDialog } from "./DeleteDialog";

const schema = yup.object().shape({
	name: yup.string().required("Název je povinný údaj"),
	height: yup.string().required("Výška je povinný údaj"),
	link: yup.string().required("Link je povinný údaj"),
	img: yup.string().required("Logo je povinné"),
});

const CHANGE_SPONSOR = gql`
	mutation editSponsorData(
		$sponsor_id: Int!
		$name: String!
		$height: Int!
		$link: String
		$img: String!
	) {
		editSponsorData(
			sponsor_id: $sponsor_id
			name: $name
			height: $height
			link: $link
			img: $img
		)
	}
`;

const DELETE_SPONSOR = gql`
	mutation deleteSponsor($sponsor_id: Int!) {
		deleteSponsor(sponsor_id: $sponsor_id)
	}
`;
/**
 * Dialog to edit sponsors data like height, logo or link.
 * @param {Boolean} open opened/closed dialog
 * @param {Function} setOpen open/close dialog
 * @param {Function} setSnackState function to set snack state Success, Warning, Error, Info
 * @param {Function} setSnackMsg function to set snack msg
 * @param {Function} setSnackOpen function to open/close informational snack message
 * @param {Function} refetch refetch new data from database
 * @param {Object} sponsorData selected sponsor data
 * @returns {Component} Dialog
 */
export function EditSponsorDialog({
	open,
	setOpen,
	refetch,
	sponsorData,
	setSnackState,
	setSnackMsg,
	setSnackOpen,
}) {
	const [name, setName] = useState("");
	const [height, setHeight] = useState("");
	const [link, setLink] = useState("");
	const [img, setImg] = useState("");
	let filesBase64 = [];

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	useEffect(() => {
		if (sponsorData) {
			setName(sponsorData.name);
			setHeight(sponsorData.height);
			setLink(sponsorData.link);
			setImg(sponsorData.img);
		}
	}, [sponsorData]);

	const handleClose = () => {
		setOpen(false);
		refetch();
	};

	const [changeSponsorRequest] = useMutation(CHANGE_SPONSOR, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.editSponsorData);
			setSnackOpen(true);
			handleClose();
		},
		onError: (error) => {
			console.error(error);
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const [deleteSponsorRequest] = useMutation(DELETE_SPONSOR, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.deleteSponsor);
			setSnackOpen(true);
			handleClose();
		},
		onError: (error) => {
			console.error(error);
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleSubmit = useCallback(
		(values) => {
			changeSponsorRequest({
				variables: {
					sponsor_id: parseInt(sponsorData.sponsor_id),
					name: values.name,
					height: parseInt(values.height),
					link: values.link,
					img: img,
				},
			});
		},
		[changeSponsorRequest, img, sponsorData.sponsor_id]
	);

	const deleteSponsor = useCallback(() => {
		deleteSponsorRequest({
			variables: {
				sponsor_id: parseInt(sponsorData.sponsor_id),
			},
		});
	}, [deleteSponsorRequest, sponsorData.sponsor_id]);

	const handleImageUpload = (e) => {
		let allfiles = [];

		for (let i = 0; i < e.target.files.length; i++) {
			if (e.target.files[i].size > 5e5) {
				setSnackState("error");
				setSnackMsg(
					"Prosím nahrajte obrázek s velikostí menší než 0,5 MB."
				);
				setSnackOpen(true);
				return false;
			}
			allfiles.push(e.target.files[i]);
		}

		getBase64(e.target.files[0]);
	};

	const getBase64 = async (file) => {
		return new Promise((resolve) => {
			let baseURL = "";
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				baseURL = reader.result;
				filesBase64.push(baseURL);
				resolve(baseURL);
			};
		}).then(async (res) => {
			setImg(res);
		});
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="sm"
				fullWidth={true}
				transitionDuration={500}
			>
				<DialogTitle
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}
				>
					<Box>Upravit sponzora</Box>
					<Button
						variant="contained"
						color="error"
						size="small"
						onClick={() => {
							setOpenDeleteDialog(true);
						}}
					>
						Smazat
					</Button>
				</DialogTitle>
				<Formik
					onSubmit={handleSubmit}
					initialValues={{
						name: name,
						link: link,
						height: height,
						img: img,
					}}
					validationSchema={schema}
					validateOnBlur={true}
					enableReinitialize={true}
				>
					<Form>
						<Box sx={{ mx: "auto", width: "fit-content" }}>
							<Badge
								overlap="circular"
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
								badgeContent={
									<IconButton
										disableFocusRipple={true}
										aria-label="Nahrát fotografii"
										sx={{
											color: "#242424",
											bgcolor: "#bdbdbd",
										}}
									>
										<label
											style={{
												width: "36px",
												height: "36px",
												cursor: "pointer",
											}}
											onChange={handleImageUpload}
											htmlFor="editImg"
										>
											<input
												accept="image/*"
												id="editImg"
												type="file"
												hidden
												style={{
													width: "0px",
													height: "0px",
												}}
											/>
											<CameraAlt fontSize="large" />
										</label>
									</IconButton>
								}
							>
								<Avatar
									sx={{
										mx: "auto",
										width: 164,
										height: 164,
										mt: "20px",
									}}
									src={img}
								/>
							</Badge>
						</Box>
						<DialogContent>
							<FormikField
								id="name"
								type="text"
								variant="outlined"
								name="name"
								sx={{ my: "10px", mx: "20px" }}
								label="Název"
								as="TextField"
								color="secondary"
							/>
							<FormikField
								id="height"
								type="text"
								variant="outlined"
								name="height"
								sx={{ my: "10px", mx: "20px" }}
								label="Výška obrázku [px]"
								as="TextField"
								color="secondary"
							/>
							<FormikField
								id="link"
								type="text"
								variant="outlined"
								name="link"
								sx={{ my: "10px", mx: "20px" }}
								label="Link"
								as="TextField"
								color="secondary"
							/>
						</DialogContent>

						<DialogActions>
							<Button
								color="secondary"
								variant="contained"
								autoFocus
								type="submit"
							>
								Uložit
							</Button>
							<Button onClick={handleClose} color="secondary">
								Zrušit
							</Button>
						</DialogActions>
					</Form>
				</Formik>
			</Dialog>
			<DeleteDialog
				open={openDeleteDialog}
				setOpen={setOpenDeleteDialog}
				id={sponsorData.sponsor_id}
				deleteFunction={deleteSponsor}
			/>
		</>
	);
}

EditSponsorDialog.propTypes = {
	open: PropTypes.any,
	refetch: PropTypes.func,
	setOpen: PropTypes.func,
	setSnackMsg: PropTypes.func,
	setSnackOpen: PropTypes.func,
	setSnackState: PropTypes.func,
	sponsorData: PropTypes.shape({
		height: PropTypes.any,
		img: PropTypes.any,
		link: PropTypes.any,
		name: PropTypes.any,
		sponsor_id: PropTypes.any
	})
}
