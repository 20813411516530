import { useEffect } from "react";

import { Box, Container, Button, Paper } from "@mui/material";
import gql from "graphql-tag";
import { useParams } from "react-router";
import { useMutation } from "@apollo/client";

const UNSUBSCRIBE = gql`
	mutation unsubscribe($email: String!, $subscribed: Boolean!) {
		setSubscription(email: $email, subscribed: $subscribed)
	}
`;

/**
 * Link from e-mail address to unsubscribe from e-mail notifications.
 * Signs out only from the informational ones, not official ones with invoices etc.
 * Gets e-mail from URL and sends it to backend.
 * @returns {Component} Page to unsubscribe
 */
export function UnsubscribePage() {
	const { email } = useParams();

	const [unsubscribeRequest, { loading, data, error }] = useMutation(
		UNSUBSCRIBE,
		{
			onCompleted: (data) => {
				console.log(data.setSubscription);
			},
			onError: (error) => {
				console.log(error.message);
			},
		}
	);

	useEffect(() => {
		unsubscribeRequest({ variables: { email: email, subscribed: false } });
	}, [email, unsubscribeRequest]);

	return (
		<Paper
			elevation={24}
			sx={{
				width: "100%",
				maxWidth: "600px",
				p: "50px",
				px: "10px",
				borderRadius: "20px",
				alignSelf: "center",
				mx: "auto",
				bgcolor: "Snow",
				my: "80px",
			}}
		>
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<Container
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{loading && (
						<div>
							<h1>Ověřování...</h1>
						</div>
					)}

					{error && (
						<div>
							<h1>Žádost se nepodařilo přijmout</h1>
							<p>Neplatný odkaz nebo byla žádost již vyřízena</p>
							<Button
								variant="contained"
								size="large"
								color="primary"
								sx={{ mt: "1%" }}
								href="/"
							>
								Zpět na hlavní stránku
							</Button>
						</div>
					)}

					{data && (
						<div>
							<h1>Byl jste úspěšně odhlášen/a z odběru</h1>
							<p></p>
							<Button
								variant="contained"
								size="large"
								color="primary"
								sx={{ mt: "1%" }}
								href="/"
							>
								Zpět na hlavní stránku
							</Button>
						</div>
					)}
				</Container>
			</Box>
		</Paper>
	);
}
