import PropTypes from "prop-types"
import { Button, FormControl, FormControlLabel, Stack, Switch, useMediaQuery } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { ToggleButtons } from "src/atoms";
import { FormikField } from "src/molecules";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Save } from "@mui/icons-material";

const USER_INFO = gql`
	query user($user_id: Int!) {
		userById(user_id: $user_id) {
			user_id
			first_name
			last_name
			gender
			email
			street
			city
			zip_code
			phone_number
			phone_number_parent
			school
			legal_representative
			partners_name
			partners_email
			house_number
			subscribed
		}
	}
`;

const UPDATE_PROFILE = gql`
	mutation updateProfile(
		$user_id: Int!
		$email: String!
		$first_name: String!
		$last_name: String!
		$city: String
		$zip_code: Int
		$street: String
		$gender: String!
		$phone_number: Int
		$phone_number_parent: Int
		$school: String
		$legal_representative: String
		$partners_name: String
		$partners_email: String
		$house_number: Int
		$subscribed: Boolean
	) {
		updateUserInfo(
			user_id: $user_id
			first_name: $first_name
			last_name: $last_name
			email: $email
			city: $city
			street: $street
			zip_code: $zip_code
			gender: $gender
			phone_number: $phone_number
			phone_number_parent: $phone_number_parent
			school: $school
			legal_representative: $legal_representative
			partners_name: $partners_name
			partners_email: $partners_email
			house_number: $house_number
			subscribed: $subscribed
		) {
			user_id
			email
			first_name
			last_name
			city
			zip_code
			street
			gender
			phone_number
			phone_number_parent
			partners_name
			partners_email
			school
			legal_representative
			house_number
		}
	}
`;

const schema = yup.object().shape({
	first_name: yup.string().required("Povinné pole").label("Jméno"),
	last_name: yup.string().required("Povinné pole").label("Příjmení"),
	gender: yup.string().required("Povinné pole").label("Pohlaví"),
	street: yup.string().label("Ulice"),
	city: yup.string().label("Město"),
	zipCode: yup.string().label("PSČ"),
	phone_number: yup.string()
	.required("Nevyplněné pole, nebo chybný tvar")
	.length(9, "Číslo musí mít přesně 9 cifer")
	.matches(/^[0-9]{9}$/, "Zadejte číslo ve formátu 123456789, bez předvolby"),
	phone_number_parent: yup.string()
	.length(9, "Číslo musí mít přesně 9 cifer")
	.matches(/^[0-9]{9}$/, "Zadejte číslo ve formátu 123456789, bez předvolby").nullable(true),
	school: yup.string().label("Škola nebo Zaměstnání").nullable(true),
	legal_representative: yup.string().label("Zákonný zástupce").nullable(true),
	house_number: yup.number().integer().label("Číslo popisné").nullable(true),
	subscribed: yup.boolean().label("Odběr novinek"),
	email: yup
		.string()
		.email("Nevalidní e-mail")
		.required("Povinné pole")
		.label("E-mail"),
	partners_email: yup
		.string()
		.email("Nevalidní e-mail")
		.label("E-mail")
		.nullable(true),
});

/**
 * Form to change any users private data and profile.
 * @param {String} snackMsg text of the snack msg
 * @param {Boolean} snackOpen whether is snack msg opened/closed
 * @param {Function} setSnackOpen function to open/close informational snack message
 * @param {Number} userId user ID
 * @returns {Component} Form
 */
export function UserDataForm({
	setSnackState,
	setSnackMsg,
	setSnackOpen,
	userId,
}) {
	const mobile = useMediaQuery("(max-width:600px)");
	const { data, loading, refetch: refetchUser } = useQuery(USER_INFO, {
		variables: {
			user_id: parseInt(userId),
		},
	});

	const [first_name, setFirstName] = useState("");
	const [last_name, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [gender, setGender] = useState("male");
	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [phone_number, setPhoneNumber] = useState("");
	const [phone_number_parent, setPhoneNumberParent] = useState("");
	const [school, setSchool] = useState("");
	const [legal_representative, setLegalRepresentative] = useState("");
	const [partners_name, setPartnersName] = useState("");
	const [partners_email, setPartnersEmail] = useState("");
	const [house_number, setHouseNumber] = useState("");
	const [subscribed, setSubscribe] = useState(false);

	useEffect(() => {
		if (!loading && data) {
			setFirstName(data.userById.first_name);
			setLastName(data.userById.last_name);
			setEmail(data.userById.email);
			setGender(data.userById.gender);
			setStreet(data.userById.street);
			setCity(data.userById.city);
			setZipCode(data.userById.zip_code);
			setPhoneNumber(data.userById.phone_number);
			setPhoneNumberParent(data.userById.phone_number_parent);
			setSchool(data.userById.school);
			setLegalRepresentative(data.userById.legal_representative);
			setPartnersName(data.userById.partners_name);
			setPartnersEmail(data.userById.partners_email);
			setHouseNumber(data.userById.house_number);
			setSubscribe(data.userById.subscribed);
		}
	}, [loading, data]);

	const initialValues = {
		first_name: first_name,
		last_name: last_name,
		gender: gender,
		email: email,
		city: city,
		street: street,
		zipCode: zipCode,
		phone_number: phone_number,
		phone_number_parent: phone_number_parent,
		school: school,
		legal_representative: legal_representative,
		partners_name: partners_name,
		partners_email: partners_email,
		house_number: house_number,
		subscribed: subscribed,
	};

	const [updateUserInfoRequest] = useMutation(
		UPDATE_PROFILE,
		{
			onCompleted: (data) => {
				setSnackState("success");
				setSnackMsg("Data uložena");
				setSnackOpen(true);
				refetchUser();
			},
			onError: (error) => {
				setSnackState("error");
				setSnackMsg(error.message);
				setSnackOpen(true);
			},
		}
	);

	const handleSubmit = useCallback(
		(values) => {
			updateUserInfoRequest({
				variables: {
					user_id: userId,
					first_name: values.first_name,
					last_name: values.last_name,
					gender: values.gender,
					email: values.email,
					city: values.city,
					street: values.street,
					zip_code: parseInt(values.zipCode),
					phone_number: parseInt(values.phone_number),
					phone_number_parent: parseInt(values.phone_number_parent),
					house_number: parseInt(values.house_number),
					school: values.school,
					legal_representative: values.legal_representative,
					partners_name: values.partners_name,
					partners_email: values.partners_email,
					subscribed: values.subscribed,
				},
			});
		},
		[updateUserInfoRequest, userId]
	);

/* 	useEffect(() => {
		if (!loading && data) {
			handleSubmit();
		}
	}, [loading, data, gender, handleSubmit]);
 */
	return (
		<Formik
			onSubmit={handleSubmit}
			initialValues={initialValues}
			validationSchema={schema}
			validateOnBlur={false}
			enableReinitialize={true}
		>
			<Form
				style={{
					display: "flex",
					width: "100%",
					justifyContent: "space-evenly",
					maxWidth: "900px",
					marginLeft: "auto",
					marginRight: "auto",
				}}
				noValidate
			>
				<Stack
					direction={mobile ? "column" : "row"}
					spacing={4}
					justifyContent="center"
					width="100%"
					height="fit-content"
				>
					<Stack
						direction="column"
						spacing={2}
						justifyContent="flex-start"
						width="100%"
						height="fit-content"
					>
						<FormikField
							id="first_name"
							name="first_name"
							label="Jméno účastníka"
							type="text"
							variant="outlined"
							as="TextField"
							color="secondary"
						/>
						<FormikField
							id="last_name"
							name="last_name"
							label="Příjmení účastníka"
							type="text"
							variant="outlined"
							as="TextField"
							color="secondary"
						/>
						<FormikField
							id="email"
							name="email"
							label="E-mail"
							type="email"
							variant="outlined"
							as="TextField"
							color="secondary"
						/>
						<FormikField
							id="phone_number"
							name="phone_number"
							label="Telefonní číslo"
							type="text"
							variant="outlined"
							as="TextField"
							color="secondary"
						/>
						<FormikField
							id="phone_number_parent"
							name="phone_number_parent"
							label="Telefonní číslo - zákonný zástupce"
							type="text"
							variant="outlined"
							as="TextField"
							color="secondary"
						/>
						<FormikField
							id="partners_email"
							name="partners_email"
							label="E-mail partnera"
							type="text"
							variant="outlined"
							as="TextField"
							color="secondary"
							sx={{display: gender === "pair" ? "flex" : "none"}}
						/>
						<ToggleButtons
							id="gender"
							name="gender"
							label="Pohlaví"
							type="text"
							variant="outlined"
							setGender={setGender}
						/>
					</Stack>
					<Stack
						direction="column"
						spacing={2}
						width="100%"
						justifyContent="space-between"
					>
						<Stack
						direction="row"
						spacing={1}
						width="100%"
						justifyContent="space-between"
					>
						<FormikField
							id="street"
							name="street"
							label="Ulice"
							type="text"
							variant="outlined"
							as="TextField"
							color="secondary"
							width="100%"
						/>
						<FormikField
							id="house_number"
							name="house_number"
							label="Číslo popisné"
							type="number"
							variant="outlined"
							as="TextField"
							color="secondary"
						/>
						</Stack>
						<FormikField
							id="city"
							name="city"
							label="Město"
							type="text"
							variant="outlined"
							as="TextField"
							color="secondary"
						/>
						<FormikField
							id="zipCode"
							name="zipCode"
							label="PSČ"
							type="number"
							variant="outlined"
							as="TextField"
							color="secondary"
						/>
						<FormikField
							id="school"
							name="school"
							label="Škola nebo zaměstnání"
							type="text"
							variant="outlined"
							as="TextField"
							color="secondary"
						/>
						<FormikField
							id="legal_representative"
							name="legal_representative"
							label="Zákonný zástupce"
							type="text"
							variant="outlined"
							as="TextField"
							color="secondary"
						/>
						<FormikField
							id="partners_name"
							name="partners_name"
							label="Jméno a příjmení partnera"
							type="text"
							variant="outlined"
							as="TextField"
							color="secondary"
							sx={{display: gender === "pair" ? "flex" : "none"}}
						/>
						<FormControl>
								<FormControlLabel
									control={
										<Switch
											checked={subscribed}
											onChange={() => {
												setSubscribe(!subscribed);
											}}
										/>
									}
									label="Odebírat novinky"
								/>
							</FormControl>
						<Stack direction="row" justifyContent="space-between">
							<Button
								size="large"
								type="submit"
								variant="contained"
								color="secondary"
								startIcon={<Save />}
								sx={{ml: 'auto'}}
							>
								Uložit
							</Button>
						</Stack>
					</Stack>
				</Stack>
			</Form>
		</Formik>
	);
}

UserDataForm.propTypes = {
	setSnackMsg: PropTypes.func,
	setSnackOpen: PropTypes.func,
	setSnackState: PropTypes.func,
	userId: PropTypes.any
}
