import PropTypes from "prop-types"
import React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import ClassNames from 'classnames';

/**
 * Banner for showing error messages. 
 * Has red background and usually is used above forms to inform user of any information missing.
 * @param {String} className other classNames to style with css
 * @param {String} title text displayed in banner
 * @param {Element} children possibility to put other elements inside the banner
 * @param {String} severity info, success, warning, error
 * @param {Class} props other properties/classes defined in Component
 * @returns {Component} Stack->Alert
 */
export function Banner({ className, title, children, severity, ...props }) {
  return (
    <Stack
      className={ClassNames(className)}
      {...props}
      sx={{
        width: '100%',
      }}
    >
      <div className={ClassNames({ mb3: !!children })}>
        <Alert severity={severity}>
          <span>{title || (severity === 'error' && 'Unknown error')}</span>
        </Alert>
      </div>
      <div className="flex items-center justify-center">{children}</div>
    </Stack>
  );
}

Banner.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  title: PropTypes.string
}
