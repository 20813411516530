import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import jwt_decode from "jwt-decode";
import { useAuth } from "src/utils/auth";
import { AdminPanel } from "src/templates";
import { SnackBarHandler } from "src/atoms";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { ReservationDatePicker, UsersSeasonEventsTable } from "src/molecules";
import { convertFromRaw } from "draft-js";
import moment from "moment";

const EVENTS_LIST = gql`
	query usersEventsByCourseId($user_id: Int!, $course_id: Int!) {
		usersEventsByCourseId(user_id: $user_id, course_id: $course_id) {
			event_id
      reservation_id
			event_start
			event_end
			event_date
			duration
			capacity
			min_participation
			user_id
			reservation_count
			attended
			signed_out
			held
			isIn7Hours
			comment
		}
	}
`;

const COURSE_INFORMATION = gql`
	query courseById($course_id: Int!) {
		courseById(course_id: $course_id) {
			name
		}
	}
`;

const EVENT_DAYS = gql`
	query days($date: String!, $course_id: Int!) {
		daysOfEventsInMonth(date: $date, course_id: $course_id) {
			event_days
		}
	}
`;

const SIGNOUTS_NUMBER = gql`
	query signoutsNumber($user_id: Int!, $course_id: Int!) {
		numberOfUsersFreeTickets(user_id: $user_id, course_id: $course_id)
	}
`;

/**
 * Profile section of selected registered dance studio course season.
 * User can excuse him/herself from an event, sign in to any other date of the same course, even in different season as long as he/she has enough free entries.
 * Also he can see capacity, comments and other information about the events.
 * @returns {Component} Table with all seasons events and Calendar
 */
export function MyCourseReservationsDetail() {
	const { token } = useAuth();
	const decoded = token && jwt_decode(token);
	const [user_id] = useState(decoded?.user_id);
	const { course_id } = useParams();
	const tablet = useMediaQuery("(max-width:1560px)");

	const [dateValue, setDateValue] = useState(new Date());
	const [month, setMonthDate] = useState(new Date());

	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const courseInfoQuery = useQuery(
		COURSE_INFORMATION,
		{
			variables: {
				course_id: parseInt(course_id),
			},
		},
		[]
	);

	const signoutsNumber = useQuery(
		SIGNOUTS_NUMBER,
		{
			variables: {
				course_id: parseInt(course_id),
				user_id: parseInt(user_id),
			},
		},
		[]
	);

	const getDaysOfEvents = useQuery(EVENT_DAYS, {
		variables: {
			course_id: parseInt(course_id),
			date: `${month.getFullYear()}-${
				month.getMonth() + 1
			}-${month.getDate()}`,
		},
	});

	const eventsQuery = useQuery(EVENTS_LIST, {
		variables: {
			course_id: parseInt(course_id),
			user_id: parseInt(user_id),
		},
	});

	const refetch = function () {
		eventsQuery.refetch();
		signoutsNumber.refetch();
	};

	let eventsList = [];

	if (eventsQuery.data) {
		eventsList = eventsQuery.data.usersEventsByCourseId.filter((event) => {
			if (
				!moment(new Date(parseInt(event.event_date))).isSameOrAfter(
					new Date(),
					"day"
				)
			) {
				return false;
			}
			return true;
		});
	}

	return (
		<AdminPanel>
			<Box
				sx={{
					display: "flex",
					width: "100%",
					justifyContent: "space-evenly",
					flexDirection: "column",
				}}
			>
				{courseInfoQuery.data && (
					<Typography
						variant="h2"
						color="initial"
						fontSize="1.5em"
						marginBottom="20px"
					>
						{convertFromRaw(
							JSON.parse(courseInfoQuery.data.courseById.name)
						).getPlainText()}
					</Typography>
				)}
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
						}}
					>
						<Box sx={{ display: tablet ? "none" : "box" }}>
							<ReservationDatePicker
								dateValue={dateValue}
								setDateValue={setDateValue}
								month={month}
								setMonthDate={setMonthDate}
								getDaysOfEvents={getDaysOfEvents}
								queryName="daysOfEventsInMonth"
							/>
						</Box>

						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
							}}
						>
							<Grid
								container
								sx={{
									width: "100%",
									mb: "5px",
								}}
							>
								{signoutsNumber.data && (
									<>
										<Grid item xs={12} lg={6}>
											<Typography
												variant="body1"
												color="initial"
											>
												{"Volných vstupů: "}
												{
													signoutsNumber.data
														.numberOfUsersFreeTickets
												}
											</Typography>
										</Grid>
									</>
								)}
							</Grid>
							<UsersSeasonEventsTable
								query={eventsList}
								setSnackMsg={setSnackMsg}
								setSnackOpen={setSnackOpen}
								setSnackState={setSnackState}
								refetch={refetch}
								signOutsNumber={signoutsNumber.data}
								freeEntriesQueryName="numberOfUsersFreeTickets"
								queryName="usersEventsByCourseId"
                user_id={user_id}
								capacityCounterName="reservation_count"
							/>
						</Box>
					</Box>
				</Box>
				<SnackBarHandler
					snackState={snackState}
					snackMsg={snackMsg}
					snackOpen={snackOpen}
					setSnackOpen={setSnackOpen}
				/>
			</Box>
		</AdminPanel>
	);
}
