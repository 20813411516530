import PropTypes from "prop-types"
import { ArrowDropDown } from '@mui/icons-material';
import { Button, ButtonGroup, Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DropDownMenu } from 'src/molecules';
import { route } from 'src/Routes';
import jwt_decode from 'jwt-decode';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const USER_INFO = gql`
  query user($user_id: Int!) {
    userById(user_id: $user_id) {
      user_id
      first_name
      last_name
      role
    }
  }
`;

/**
 * Button group to display name of a user and enable drop down menu.
 * @param {String} anchorRef React.useRef()
 * @param {String} token its existence decides whether user is signed in
 * @param {Function} signout Function to sign user out
 * @returns {Component} Button group with drop down menu
 */
export function NavDropDownUser({ anchorRef, token, signout }) {
  const decoded = token && jwt_decode(token);

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  let skip;
  if (token) {
    skip = false;
  } else {
    skip = true;
  }

  const getUserInfo = useQuery(USER_INFO, {
    variables: { user_id: parseInt(decoded?.user_id) },
    skip: skip,
  });

  let user = {
    first_name: '',
    last_name: '',
    role: ''
  };

  if (getUserInfo.data) {
    Object.keys(user).forEach((key) => {
      user[key] = getUserInfo.data.userById[key];
    });
  }

  const handleClick = () => {
    navigate(route.profile());
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleLogout = () => {
    signout();
    navigate(route.home());
    window.location.reload();
  };

  return (
    <Grid
      item
      sx={{
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <React.Fragment>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button color="secondary" onClick={handleClick}>
            {user.first_name.charAt(0)}
            {'. '}
            {user.last_name}
          </Button>
          <Button
            color="secondary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? true : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDown />
          </Button>
        </ButtonGroup>
        <DropDownMenu
          open={open}
          anchorRef={anchorRef}
          handleClose={handleClose}
          handleLogout={handleLogout}
          user={user}
        />
      </React.Fragment>
    </Grid>
  );
}

NavDropDownUser.propTypes = {
  anchorRef: PropTypes.shape({
    current: PropTypes.shape({
      contains: PropTypes.func
    })
  }),
  signout: PropTypes.func,
  token: PropTypes.string
}
