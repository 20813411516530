import { List, Collapse, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { AdminMenuItem, DanceIcon, DressIcon } from "src/atoms";
import {
	AccessibilityNew,
	AccessTime,
	AccountCircle,
	AdminPanelSettings,
	Badge,
	Collections,
	Email,
	EventNote,
	EventSeat,
	ExpandLessTwoTone,
	ExpandMoreTwoTone,
	Keyboard,
	Lock,
	Newspaper,
	Savings,
	School,
	SupervisedUserCircle,
	Web,
} from "@mui/icons-material";
import { useAuth } from "src/utils/auth";
import jwt_decode from "jwt-decode";
/**
 * Navigation menu in profile/admin center page
 * @return {Component} List of AdminMenuItems 
 */
export function NavAdminCenterMenu() {
	const { token } = useAuth();
	const decoded = token && jwt_decode(token);

	const page = window.location.pathname.replace(/\//g, "");

	const tablet = useMediaQuery("(max-width:1200px)");

	const [menuOpen, setMenuOpen] = useState(true);
	const [adminMenuOpen, setAdminMenuOpen] = useState(true);
	const [contentMenuOpen, setContentMenuOpen] = useState(false);

	const openCloseMenu = () => {
		setMenuOpen(!menuOpen);
	};

	const openCloseAdminMenu = () => {
		setAdminMenuOpen(!adminMenuOpen);
	};

	const openCloseContentMenu = () => {
		setContentMenuOpen(!contentMenuOpen);
	};

	return (
		<List
			sx={{
				width: "100%",
				maxWidth: tablet ? "100%" : 320,
				mr: "20px",
			}}
			component="nav"
			aria-labelledby="nested-list-subheader"
		>
			<AdminMenuItem
				onClick={openCloseMenu}
				icon={<AccountCircle />}
				primary="Profil"
				sx={{
					borderRadius: "5px",
					border: "1px solid",
					bgcolor: "primary.main",
				}}
			>
				{menuOpen ? <ExpandLessTwoTone /> : <ExpandMoreTwoTone />}
			</AdminMenuItem>
			<Collapse in={menuOpen} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					<AdminMenuItem
						item="profil"
						sx={{ ml: 3, borderRadius: "5px" }}
						icon={<Badge />}
						primary="Osobní údaje"
						selected={page.includes("profil")}
					/>
					<AdminMenuItem
						item="zmena-hesla"
						sx={{ ml: 3, borderRadius: "5px" }}
						icon={<Lock />}
						primary="Změna hesla"
						selected={page.includes("zmena-hesla")}
					/>
					<AdminMenuItem
						item="kurzy-tanecniho-studia"
						sx={{ ml: 3, borderRadius: "5px" }}
						icon={<AccessTime />}
						primary="Kurzy tanečního studia"
						selected={page.includes("kurzy-tanecniho-studia")}
					/>
					<AdminMenuItem
						item="me-spolecenske-tance"
						sx={{ ml: 3, borderRadius: "5px" }}
						icon={<DressIcon fontSize="1.5rem" />}
						primary="Kurzy párových tanců"
						selected={page.includes("me-spolecenske-tance")}
					/>
				</List>
			</Collapse>
			{(decoded?.role === "admin" || decoded?.role === "lecturer") && (
				<>
					<AdminMenuItem
						onClick={openCloseAdminMenu}
						icon={<AdminPanelSettings />}
						primary="Admin"
						sx={{
							borderRadius: "5px",
							border: "1px solid",
							bgcolor: "primary.main",
						}}
					>
						{adminMenuOpen ? (
							<ExpandLessTwoTone />
						) : (
							<ExpandMoreTwoTone />
						)}
					</AdminMenuItem>
					<Collapse in={adminMenuOpen} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							<AdminMenuItem
								item="sprava-rezervaci"
								sx={{ ml: 3, borderRadius: "5px" }}
								icon={<EventSeat />}
								primary="Výpis rezervací"
								selected={page.includes("sprava-rezervaci")}
							/>
							<AdminMenuItem
								item="prihlasky-spolecenske-tance"
								sx={{ ml: 3, borderRadius: "5px" }}
								icon={<DanceIcon />}
								primary="Přihlášky společenské tance"
								selected={page.includes(
									"prihlasky-spolecenske-tance"
								)}
							/>
							<AdminMenuItem
								item="objednavky-tanecni-studio"
								sx={{ ml: 3, borderRadius: "5px" }}
								icon={<DanceIcon />}
								primary="Objednávky taneční studio"
								selected={page.includes(
									"objednavky-tanecni-studio"
								)}
							/>
							<AdminMenuItem
								item="sprava-uzivatelu"
								sx={{ ml: 3, borderRadius: "5px" }}
								icon={<SupervisedUserCircle />}
								primary="Uživatelé"
								selected={page.includes("sprava-uzivatelu")}
							/>
							<AdminMenuItem
								item="sprava-kurzu"
								sx={{ ml: 3, borderRadius: "5px" }}
								icon={<EventNote />}
								primary="Správa kurzů"
								selected={page.includes("sprava-kurzu")}
							/>
							{decoded?.role === "admin" && (
								<>
									<AdminMenuItem
										onClick={openCloseContentMenu}
										icon={<Keyboard />}
										primary="Obsah"
										sx={{
											borderRadius: "5px",
											border: "1px solid",
											bgcolor: "primary.main",
											ml: 3,
										}}
									>
										{contentMenuOpen ? (
											<ExpandLessTwoTone />
										) : (
											<ExpandMoreTwoTone />
										)}
									</AdminMenuItem>
									<Collapse
										in={contentMenuOpen}
										timeout="auto"
										unmountOnExit
									>
										<List component="div" disablePadding>
											<AdminMenuItem
												item="obsah-kurzy"
												sx={{
													ml: 6,
													borderRadius: "5px",
												}}
												icon={<School />}
												primary="Kurzy"
												selected={
													page.includes(
														"obsah-kurzy"
													) ||
													page.includes(
														"upravit-kurz"
													)
												}
											/>
											<AdminMenuItem
												item="webovy-obsah"
												sx={{
													ml: 6,
													borderRadius: "5px",
												}}
												icon={<Web />}
												primary="Web"
												selected={page.includes(
													"webovy-obsah"
												)}
											/>
											<AdminMenuItem
												item="obsah-lektori"
												sx={{
													ml: 6,
													borderRadius: "5px",
												}}
												icon={<AccessibilityNew />}
												primary="Lektoři"
												selected={page.includes(
													"obsah-lektori"
												)}
											/>
											<AdminMenuItem
												item="obsah-sponzori"
												sx={{
													ml: 6,
													borderRadius: "5px",
												}}
												icon={<Savings />}
												primary="Sponzoři"
												selected={page.includes(
													"obsah-sponzori"
												)}
											/>
										</List>
									</Collapse>
								</>
							)}
							<AdminMenuItem
								item="sprava-aktualit"
								sx={{ ml: 3, borderRadius: "5px" }}
								icon={<Newspaper />}
								primary="Aktuality"
								selected={page.includes("sprava-aktualit")}
							/>
							<AdminMenuItem
								item="centrum-oznameni"
								sx={{ ml: 3, borderRadius: "5px" }}
								icon={<Email />}
								primary="Centrum oznámení"
								selected={page.includes("centrum-oznameni")}
							/>
							<AdminMenuItem
								item="sprava-galerie"
								sx={{ ml: 3, borderRadius: "5px" }}
								icon={<Collections />}
								primary="Galerie"
								selected={page.includes("sprava-galerie")}
							/>
						</List>
					</Collapse>
				</>
			)}
		</List>
	);
}
