import PropTypes from "prop-types"
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useCallback } from "react";

/**
 * Editor from react-draft-wysiwyg library. Helps to use Rich text editor, save the content and handles convertion for SQL storage in parent component.
 * @param {Object} editorState special object for Rich text containing its content and formatting
 * @param {Function} setEditorState useState setter from Editor libraries for Rich text
 * @param {Class} props other properties/classes defined in Component 
 * @returns {Component} Editor
 */
export function RichEditor({ editorState, setEditorState, ...props }) {
	const handleEditorChange = useCallback(
		(state) => {
			setEditorState(state);
		},
		[setEditorState]
	);

	return (
		<>
			{editorState !== null && editorState !== undefined && (
				<Editor
					editorState={editorState}
					onEditorStateChange={handleEditorChange}
					wrapperClassName="wrapper-class"
					editorClassName="editor-class"
					toolbarClassName="toolbar-class"
					{...props}
				/>
			)}
		</>
	);
}

RichEditor.propTypes = {
	editorState: PropTypes.any,
	setEditorState: PropTypes.func
}
