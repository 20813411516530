import { useMutation } from "@apollo/client";
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Link,
	Stack,
	Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToggleButtons, SnackBarHandler } from "src/atoms";
import { FormikField } from "src/molecules";
import { route } from "src/Routes";
import { useAuth } from "src/utils/auth";
import * as yup from "yup";

const SIGNUP_MUTATION = gql`
	mutation SignUp(
		$first_name: String!
		$last_name: String!
		$email: String!
		$gender: String!
		$password: String!
		$street: String
		$house_number: Int
		$city: String
		$zip_code: Int
		$phone_number: Int
		$phone_number_parent: Int
		$school: String
		$legal_representative: String
		$partners_name: String
		$partners_email: String
	) {
		signup(
			first_name: $first_name
			last_name: $last_name
			email: $email
			gender: $gender
			password: $password
			street: $street
			house_number: $house_number
			city: $city
			zip_code: $zip_code
			phone_number: $phone_number
			phone_number_parent: $phone_number_parent
			school: $school
			legal_representative: $legal_representative
			partners_name: $partners_name
			partners_email: $partners_email
		) {
			user {
				user_id
			}
			token
		}
	}
`;

const schema = yup.object().shape({
	first_name: yup.string().required("Jméno je povinný údaj"),
	last_name: yup.string().required("Příjmení je povinný údaj"),
	gender: yup
		.string()
		.oneOf(["male", "female", "pair"], "Pohlaví je povinný údaj")
		.required("Pohlaví je povinný údaj"),
	email: yup.string().email("Zadejte validní e-mailovou adresu.").required("E-mail je povinný údaj"),
	partners_email: yup.string().email("Zadejte validní e-mailovou adresu.").nullable(true),
	password: yup.string().required("Heslo je povinný údaj"),
	passwordAgain: yup
		.string()
		.oneOf([yup.ref("password"), null], "Hesla se musí shodovat"),
	agreement: yup
		.bool()
		.oneOf(
			[true],
			"Pro registraci musíte souhlasit s podmínkami Tanečního studia Ká."
		),
	age: yup
		.bool()
		.oneOf(
			[true],
			"Pro registraci musíte souhlasit s podmínkami Tanečního studia Ká."
		),
	street: yup.string().required("Ulice je povinný údaj"),
	house_number: yup.string().required("Číslo popisné je povinný údaj"),
	city: yup.string().required("Město je povinný údaj"),
	zip_code: yup.string().required("PSČ je povinný údaj"),
	phone_number: yup.string()
	.required("Nevyplněné pole, nebo chybný tvar")
	.length(9, "Číslo musí mít přesně 9 cifer")
	.matches(/^[0-9]{9}$/, "Zadejte číslo ve formátu 123456789, bez předvolby"),
	phone_number_parent: yup.string()
	.length(9, "Číslo musí mít přesně 9 cifer")
	.matches(/^[0-9]{9}$/, "Zadejte číslo ve formátu 123456789, bez předvolby"),
});

/**
 * Page for creating account
 * @returns {Component} Page for creating account
 */
export function SignUpPage() {
	const [first_name] = useState("");
	const [last_name] = useState("");
	const [email] = useState("");
	const [gender, setGender] = useState("");
	const [password] = useState("");
	const [passwordAgain] = useState("");
	const [street] = useState("");
	const [house_number] = useState("");
	const [city] = useState("");
	const [zip_code] = useState("");
	const [phone_number] = useState("");
	const [phone_number_parent] = useState("");
	const [school] = useState("");
	const [legal_representative] = useState("");
	const [partners_name] = useState("");
	const [partners_email] = useState("");

	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const initialValues = {
		first_name: first_name,
		last_name: last_name,
		gender: gender,
		email: email,
		password: password,
		password_again: passwordAgain,
		street: street,
		house_number: house_number,
		city: city,
		zip_code: zip_code,
		phone_number: phone_number,
		phone_number_parent: phone_number_parent,
		school: school,
		legal_representative: legal_representative,
		partners_name: partners_name,
		partners_email: partners_email,
		agreement: false,
		age: false,
	};

	const navigate = useNavigate();
	const auth = useAuth();

	const [signupRequest] = useMutation(SIGNUP_MUTATION, {
		onCompleted: ({ signup: { user, token } }) => {
			auth.signin({ user, token });
			navigate("/", { replace: true });
		},
		onError: (error) => {
			console.error(error);
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleSubmit = useCallback(
		(values) => {
			signupRequest({
				variables: {
					first_name: values.first_name,
					last_name: values.last_name,
					email: values.email,
					password: values.password,
					gender: values.gender,
					street: values.street,
					house_number: parseInt(values.house_number),
					city: values.city,
					zip_code: parseInt(values.zip_code),
					phone_number: parseInt(values.phone_number),
					phone_number_parent: parseInt(values.phone_number_parent),
					school: values.school,
					legal_representative: values.legal_representative,
					partners_email: values.partners_email,
					partners_name: values.partners_name,
				},
			});
		},
		[signupRequest]
	);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					py: "50px",
					px: "20px",
					maxWidth: "900px",
					width: "90%",
					backgroundImage: "url(/img/dance_in_light.jpeg)",
					filter: "drop-shadow(25px 25px 25px rgba(0, 0, 0, 0.25))",
					borderRadius: "15px",
					mx: "auto",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center",
					my: "50px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						padding: "0px",
					}}
				>
					<Box
						component="img"
						alt="logo"
						src="/img/logo_white.svg"
					></Box>
					<Box>
						<Typography variant="h3" color="white">
							Založit účet
						</Typography>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-evenly",
						alignItems: "center",
						position: "static",
						px: "10px",
						background: "rgba(255, 255, 255, 0.8)",
						borderRadius: "25px",
					}}
				>
					<Formik
						onSubmit={handleSubmit}
						initialValues={initialValues}
						validationSchema={schema}
						validateOnBlur={false}
					>
						<Form
							style={{
								width: "inherit",
								height: "100%",
								justifyContent: "space-evenly",
								alignItems: "center",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Stack
								direction="row"
								spacing={4}
								justifyContent="center"
								width="100%"
								marginTop="20px"
							>
								<Stack
									direction="column"
									justifyContent="center"
									width="100%"
									height="fit-content"
								>
									<Typography
										variant="body1"
										color="initial"
										sx={{
											textAlign: "left",
											fontSize: "24px",
											width: "100%",
										}}
									>
										Přihlašovací údaje
									</Typography>
									<FormikField
										id="email"
										name="email"
										label="E-mail"
										type="email"
										variant="standard"
										as="TextField"
										color="secondary"
									/>
									<FormikField
										id="password"
										name="password"
										label="Heslo"
										type="password"
										variant="standard"
										as="TextField"
										color="secondary"
									/>
									<FormikField
										id="passwordAgain"
										name="passwordAgain"
										label="Heslo znovu"
										type="password"
										variant="standard"
										as="TextField"
										color="secondary"
									/>
								</Stack>
								<Stack
									direction="column"
									justifyContent="center"
									width="100%"
								>
									<Typography
										variant="body1"
										color="initial"
										sx={{
											textAlign: "left",
											fontSize: "24px",
											width: "100%",
										}}
									>
										Osobní údaje
									</Typography>
									<FormikField
										id="first_name"
										name="first_name"
										label="Jméno účastníka"
										type="text"
										variant="standard"
										as="TextField"
										color="secondary"
									/>
									<FormikField
										id="last_name"
										name="last_name"
										label="Příjmení účastníka"
										type="text"
										variant="standard"
										as="TextField"
										color="secondary"
									/>
									<FormikField
										id="phone_number"
										name="phone_number"
										label="Telefonní číslo"
										type="text"
										variant="standard"
										as="TextField"
										color="secondary"
									/>
									<ToggleButtons
										id="gender"
										name="gender"
										label="Pohlaví"
										type="text"
										variant="standard"
										setGender={setGender}
									/>
								</Stack>
							</Stack>
							<Stack
								direction="row"
								spacing={4}
								justifyContent="center"
								width="100%"
								marginTop="20px"
							>
								<Stack
									direction="column"
									justifyContent="center"
									width="100%"
									height="fit-content"
								>
									<Typography
										variant="body1"
										color="initial"
										sx={{
											textAlign: "left",
											fontSize: "24px",
											width: "100%",
										}}
									>
										Fakturační údaje
									</Typography>
									<FormikField
										id="street"
										name="street"
										label="Ulice"
										type="text"
										variant="standard"
										as="TextField"
										color="secondary"
										width="100%"
									/>
									<FormikField
										id="house_number"
										name="house_number"
										label="Číslo popisné"
										type="number"
										variant="standard"
										as="TextField"
										color="secondary"
									/>
									<FormikField
										id="city"
										name="city"
										label="Město"
										type="text"
										variant="standard"
										as="TextField"
										color="secondary"
									/>
									<FormikField
										id="zip_code"
										name="zip_code"
										label="PSČ"
										type="number"
										variant="standard"
										as="TextField"
										color="secondary"
									/>
								</Stack>
								<Stack direction="column" width="100%">
									<Typography
										variant="body1"
										color="initial"
										sx={{
											textAlign: "left",
											fontSize: "24px",
											width: "100%",
										}}
									>
										Doplňující údaje
									</Typography>
									<FormikField
										id="school"
										name="school"
										label="Škola nebo zaměstnání"
										type="text"
										variant="standard"
										as="TextField"
										color="secondary"
									/>
									<FormikField
										id="legal_representative"
										name="legal_representative"
										label="Zákonný zástupce"
										type="text"
										variant="standard"
										as="TextField"
										color="secondary"
									/>
									<FormikField
										id="phone_number_parent"
										name="phone_number_parent"
										label="Telefonní číslo - zákonný zástupce"
										type="text"
										variant="standard"
										as="TextField"
										color="secondary"
									/>
									<FormikField
										id="partners_name"
										name="partners_name"
										label="Jméno a příjmení partnera"
										type="text"
										variant="standard"
										as="TextField"
										color="secondary"
										sx={{display: gender === "pair" ? "flex" : "none"}}
									/>
									<FormikField
										id="partners_email"
										name="partners_email"
										label="E-mail partnera"
										type="text"
										variant="standard"
										as="TextField"
										color="secondary"
										sx={{display: gender === "pair" ? "flex" : "none"}}
									/>
								</Stack>
							</Stack>

							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									alignSelf: "center",
									mb: "20px",
								}}
							>
								<Box>
									<Field
										as={FormControlLabel}
										type="checkbox"
										name="agreement"
										id="agreement"
										control={<Checkbox color="secondary" />}
										label={
											<Typography
												variant="body1"
												color="initial"
												fontSize="0.9em"
											>
												Souhlasím s{" "}
												<Link
													sx={{
														color: "secondary.main",
														textDecoration: "none",
														fontWeight: "bold",
													}}
													target="_blank"
													href={route.podminkyUcasti()}
												>
													podmínkami účasti
												</Link>{" "}
												a{" "}
												<Link
													sx={{
														color: "secondary.main",
														textDecoration: "none",
														fontWeight: "bold",
													}}
													href={route.provozniRad()}
													target="_blank"
												>
													provozním řádem
												</Link>{" "}
												Tanečního studia Ká{" "}
											</Typography>
										}
									/>
									<ErrorMessage
										name="agreement"
										component="div"
										className="invalid-feedback"
									/>
									<Field
										as={FormControlLabel}
										type="checkbox"
										name="age"
										id="age"
										control={<Checkbox color="secondary" />}
										label={
											<Typography
												variant="body1"
												color="initial"
												fontSize="0.9em"
											>
												Prohlašuji, že pokud jsem
												ponechal(a) pole "Zákonný
												zástupce" prázdné, dosáhl(a)
												jsem 18. roku života.
											</Typography>
										}
									/>
									<ErrorMessage
										name="age"
										component="div"
										className="invalid-feedback"
									/>
								</Box>
								<Button
									sx={{ mb: "10px" }}
									size="large"
									type="submit"
									variant="contained"
									color="secondary"
								>
									Odeslat
								</Button>
								<Typography variant="body1" color="initial">
									{" "}
									Již máte účet?{" "}
									<a href={route.signIn()}>Přihlaste se.</a>
								</Typography>
							</Box>
						</Form>
					</Formik>
				</Box>
			</Box>
			<SnackBarHandler
				snackState={snackState}
				snackMsg={snackMsg}
				snackOpen={snackOpen}
				setSnackOpen={setSnackOpen}
			/>
		</>
	);
}
