import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import DOMPurify from "dompurify";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import draftToHtml from "draftjs-to-html";

const CONTENT_INFO = gql`
	query contentById($content_id: Int!) {
		contentById(content_id: $content_id) {
			content_id
			name
			text
		}
	}
`;

/**
 * Homepage section with short text and a picture on a background
 * @returns {Component} Set of boxes - About us section
 */
export function HomepageAboutUs() {
	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	const {data } = useQuery(CONTENT_INFO, {
		variables: {
			content_id: 5,
		},
	});

	return (
		<Box
			sx={{
				backgroundImage: "url(/img/dance_couple_ball.jpg)",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				backgroundPosition: "center",
				my: "auto",
				mx: "auto",
				display: "flex",
				width: "75%",
				alignSelf: "center",
				borderRadius: "25px",
				scrollMarginTop: "120px",
				py: "100px",
			}}
			id="O nas"
			className="spreadWidthOnSmall hideBG lowerYPadding"
		>
			<Box
				bgcolor="rgba(0, 0, 0, 0.92)"
				sx={{
					width: "80%",
					my: "auto",
					mx: "auto",
					display: "flex",
					flexDirection: "row",
					borderRadius: "25px",
					px: "15px",
					py: "40px",
				}}
				className="spreadWidthOnSmall revertBGColor lowerYPadding"
			>
				<Box
					className="hideOnSmall"
					component="img"
					sx={{ height: "200px", my: "auto" }}
					alt="logo"
					src="/img/logo_white.svg"
				></Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						pr: "10px",
						pt: "40px",
					}}
					className="lowerYPadding"
				>
					<Typography
						variant="h3"
						fontSize="2rem"
						color="white"
						className="revertBGColor"
						textAlign="center"
					>
						Taneční studio "Ká"
					</Typography>
					{data && (
						<Typography
							variant="body1"
							fontSize="18px"
							color="white"
							className="revertBGColor"
							sx={{ textAlign: "justify" }}
							dangerouslySetInnerHTML={createMarkup(
								draftToHtml(JSON.parse(data.contentById.text))
							)}
						></Typography>
					)}
				</Box>
			</Box>
		</Box>
	);
}
