import PropTypes from "prop-types"
import React from 'react';
import ClassNames from 'classnames';
/**
 * Simple red text used in Forms under input fields
 * @param {Class} classNames other classNames to style with css
 * @param {Class} props other properties defined in Component
 * @returns {Element} div with css
 */
export function ErrorMessage({ className, ...props }) {
  return <div className={ClassNames('dark-red f5', className)} {...props} />;
}

ErrorMessage.propTypes = {
  className: PropTypes.any
}
