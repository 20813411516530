import { Box } from "@mui/system";
import { LecturerCard } from "src/molecules";
import { Typography } from "@mui/material";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";

const GET_LECTURERS = gql`
	query lecturersTexts {
		lecturersTexts {
			user_id
			first_name
			last_name
			user_description
			photo
		}
	}
`;

/**
 * Homepage section with list of public lecturers.
 * Each lecturer has photo bubble and rich text description.
 * @returns {Component} Flex box of lecturer cards - Homepage lecturers
 */
export function HomepageLecturers() {
	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	const contentQuery = useQuery(GET_LECTURERS);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				m: "50px",
				alignItems: "center",
				scrollMarginTop: "150px",
			}}
			id="Lektori"
		>
			<Typography variant="h2" color="initial" sx={{ mb: "50px", textAlign: 'center' }}>
				Naši lektoři
			</Typography>
			<Box
				id="HomePageLecturersCards"
				sx={{
					display: "flex",
					justifyContent: "space-evenly",
					width: "100%",
					flexWrap: 'wrap'
				}}
			>
			{contentQuery.data &&
					contentQuery.data.lecturersTexts.map((lecturer) => (
						<LecturerCard
							key={lecturer.user_id}
							image={lecturer.photo}
							name={`${lecturer.first_name}${" "}${lecturer.last_name}`}
							description={createMarkup(
								draftToHtml(JSON.parse(lecturer.user_description))
							)}
						/>
					))}
			</Box>
		</Box>
	);
}
