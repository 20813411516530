import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import jwt_decode from "jwt-decode";
import { useAuth } from "src/utils/auth";
import { AdminPanel, UserDataForm } from "src/templates";
import QRCode from "react-qr-code";


/**
 * Profile page to view and edit all personal information about user.
 * @returns {Component} QRCode and UserDataForm
 */
export function Profile() {
	const { token } = useAuth();
	const decoded = token && jwt_decode(token);
	const [userId] = useState(decoded?.user_id);

	const [snackState, setSnackState] = useState("");
	const [snackMsg, setSnackMsg] = useState("");
	const [snackOpen, setSnackOpen] = useState(false);

	return (
		<AdminPanel
			snackState={snackState}
			snackMsg={snackMsg}
			snackOpen={snackOpen}
			setSnackOpen={setSnackOpen}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "flex-start",
				}}
			></Box>
			<Box
				sx={{
					display: "flex",
					width: "100%",
					justifyContent: "space-evenly",
					flexDirection: "column",
				}}
			>
				<Typography
					variant="h2"
					color="initial"
					fontSize="28px"
					marginBottom="20px"
				>
					Osobní údaje
				</Typography>
				<Paper sx={{p: '15px', mb: '20px', mx: 'auto', alignItems: 'center', display: 'flex'}}>
					<QRCode value={userId ? userId.toString(): ""} size={125} />
				</Paper>
				<UserDataForm
					userId={userId}
					setSnackOpen={setSnackOpen}
					setSnackMsg={setSnackMsg}
					setSnackState={setSnackState}
				/>
			</Box>
		</AdminPanel>
	);
}
