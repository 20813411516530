import { Button, IconButton, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { AdminPanel } from "src/templates";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
	ContentState,
	convertFromRaw,
	convertToRaw,
	EditorState,
} from "draft-js";
import "draft-js/dist/Draft.css";
import { RichEditor } from "src/molecules";
import * as yup from "yup";
import { Form, Formik } from "formik";
import draftToHtml from "draftjs-to-html";
import DOMPurify from "dompurify";
import { ArrowBackIos } from "@mui/icons-material";
import { route } from "src/Routes";

const CONTENT_INFO = gql`
	query contentById($content_id: Int!) {
		contentById(content_id: $content_id) {
			content_id
			name
			text
		}
	}
`;

const CHANGE_CONTENT = gql`
	mutation updateContent($content_id: Int!, $text: String!) {
		updateContent(content_id: $content_id, text: $text)
	}
`;

const schema = yup.object().shape({});

/**
 * Admin section with specific content to be edited.
 * Uses react-draft-wysiwyg to edit Rich (formatted) texts.
 * @return {Component} Form with rich text editable web page content
 */
export function EditWebContent() {
	const { content_id } = useParams();

	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const [text, setText] = useState(() => EditorState.createEmpty());
	const [name] = useState(() => EditorState.createEmpty());

	const { loading, data, refetch } = useQuery(CONTENT_INFO, {
		variables: {
			content_id: parseInt(content_id),
		},
	});

	const initialValues = {
		content_id: parseInt(content_id),
		name: name,
		text: text,
	};

	useEffect(() => {
		if (!loading && data) {
			setText(
				EditorState.createWithContent(
					ContentState(
						convertFromRaw(JSON.parse(data.contentById.text))
					)
				)
			);
		}
	}, [loading, data]);

	const [changeContentRequest] = useMutation(CHANGE_CONTENT, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.updateContent);
			setSnackOpen(true);
			refetch();
		},
		onError: (error) => {
			console.error(error);
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleSubmit = useCallback(() => {
		changeContentRequest({
			variables: {
				content_id: parseInt(content_id),
				name: JSON.stringify(convertToRaw(name.getCurrentContent())),
				text: JSON.stringify(convertToRaw(text.getCurrentContent())),
			},
		});
	}, [changeContentRequest, content_id, text, name]);

	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	return (
		<AdminPanel
			snackState={snackState}
			snackMsg={snackMsg}
			snackOpen={snackOpen}
			setSnackOpen={setSnackOpen}
			adminsOnly={true}
		>
			<Box sx={{ display: "flex", flexDirection: "row" }}>
				<IconButton
					sx={{ my: "auto" }}
					size="small"
					disableRipple={true}
					component={Link}
					href={route.contentManagement()}
				>
					<ArrowBackIos />
				</IconButton>
				{data && (
					<Typography
						variant="h2"
						fontSize="1.5em"
						color="initial"
						dangerouslySetInnerHTML={createMarkup(
							draftToHtml(JSON.parse(data.contentById.name))
						)}
					></Typography>
				)}
			</Box>

			<Formik
				onSubmit={handleSubmit}
				initialValues={initialValues}
				validationSchema={schema}
				validateOnBlur={false}
				enableReinitialize={true}
			>
				<Form>
					<Box>
						<Typography
							variant="overline"
							fontWeight="bold"
							color="initial"
						>
							Text
						</Typography>
						<RichEditor
							id="text"
							name="text"
							label="Dlouhý popis"
							type="text"
							editorState={text}
							setEditorState={setText}
							toolbar={{
								options: [
									"inline",
									"blockType",
									"fontSize",
									"list",
									"textAlign",
									"link",
									"colorPicker",
									"emoji",
									"history",
								],
								inline: {
									superscript: false,
								},
							}}
						/>
					</Box>
					<Button
						type="submit"
						variant="contained"
						sx={{ mt: "5px", ml: "auto", display: "flex" }}
					>
						Uložit
					</Button>
				</Form>
			</Formik>
		</AdminPanel>
	);
}
