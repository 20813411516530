import PropTypes from "prop-types"
import {
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	useMediaQuery,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";

const DAY_MAP = {
	monday: "pondělí",
	tuesday: "úterý",
	wednesday: "středa",
	thursday: "čtvrtek",
	friday: "pátek",
	saturday: "sobota",
	sunday: "neděle",
};

/**
 * Table for picking alternative season to sign in compensatory ballroom event
 * @param {Object} data alternative ballroom seasons 
 * @param {Function} handleNext function to go to the next step of the form wizzard
 * @param {Function} setPickedSeasonID useState for setting picked season id
 * @returns {Component} TableContainer
 */
export function AlternativeBallroomCoursesTable({
	data,
	handleNext,
	setPickedSeasonID
}) {
	const mobile = useMediaQuery("(max-width:600px)");

	return (
		<TableContainer sx={{ maxHeight: "400px" }}>
			<Table
				sx={{
					borderSpacing: "0 15px",
					borderCollapse: "separate",
					px: "10px",
					overflow: "hidden",
					overflowY: "scroll",
					overflowX: "scroll",
				}}
				aria-label="simple table"
			>
				<TableHead>
					<TableRow>
						<TableCell>Ročník</TableCell>
						<TableCell align="center">Termín</TableCell>
						<TableCell align="center" sx={{display: mobile ? 'none' : 'table-cell'}}>Začátek kurzu</TableCell>
						<TableCell align="center" sx={{display: mobile ? 'none' : 'table-cell'}}>Konec kurzu</TableCell>
						<TableCell align="center">Kapacita Ženy</TableCell>
						<TableCell align="center">Kapacita Muži</TableCell>
						<TableCell align="right"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data &&
						data.usersReservations !== null &&
						data.alternativeBallroomSeasons.map(
							(registration, index) => (
								<TableRow key={index}>
									<TableCell align="left">
										{registration.season}
									</TableCell>
									<TableCell align="center">
										{DAY_MAP[registration.day]}
										<br></br>
										{registration.start_time}
										{" - "}
										{registration.end_time}
									</TableCell>
									<TableCell align="center" sx={{display: mobile ? 'none' : 'table-cell'}}>
										{new Date(
											parseInt(registration.start_date)
										).toLocaleDateString("cs-CZ")}
									</TableCell>
									<TableCell align="center" sx={{display: mobile ? 'none' : 'table-cell'}}>
										{new Date(
											parseInt(registration.end_date)
										).toLocaleDateString("cs-CZ")}
									</TableCell>
									<TableCell align="center">
										{registration.women_count}
									</TableCell>
									<TableCell align="center">
										{registration.men_count}
									</TableCell>
									<TableCell align="center">
										<Button
											variant="contained"
											size="small"
											endIcon={<ArrowForwardIos />}
											aria-label="go to detail"
											onClick={() => {
												handleNext();
												setPickedSeasonID(registration.season_id);
											}}
										>
											Vybrat den
										</Button>
									</TableCell>
								</TableRow>
							)
						)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

AlternativeBallroomCoursesTable.propTypes = {
  data: PropTypes.shape({
    alternativeBallroomSeasons: PropTypes.shape({
      map: PropTypes.func
    }),
    usersReservations: PropTypes.any
  }),
  handleNext: PropTypes.func,
  setPickedSeasonID: PropTypes.func
}
