import { AdminPanel } from "src/templates";
import {
	Container,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Button,
	TableHead,
	Avatar,
	IconButton,
} from "@mui/material";
import { CheckCircleOutline, HighlightOff } from "@mui/icons-material";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { convertFromRaw } from "draft-js";
import { useCallback } from "react";
import { EditLecturerDialog } from "../Dialogs/EditLecturerDialog";
import { AddLecturerDialog } from "../Dialogs/AddLecturerDialog";

const LECTURERS_QUERY = gql`
	query usersByRole($role: Int!) {
		usersByRole(role: $role) {
			user_id
			first_name
			last_name
			role
			user_description
			photo
			published
		}
	}
`;

const SWITCH_PUBLIC = gql`
	mutation switchPublishLecturer($user_id: Int!, $published: Boolean!) {
		switchPublishLecturer(user_id: $user_id, published: $published)
	}
`;

const ROLE_MAP = {
	admin: "Správce",
	lecturer: "Lektor",
	user: "Uživatel",
};

/**
 * Admin section table of lecturers to be edited.
 * If lecturer is public, he is displayed on homepage (click on public icon in table).
 * Can add new lecturer by changing role of existing user. Cannot add non-existent user as lecturer.
 * Can assign photos to users.
 * @return {Component} Table with lecturers to open edit dialog form.
 */
export function LecturerManagement() {
	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const [editLecturerDialog, setEditLecturerDialog] = useState(false);
	const [editLecturerData, setEditLecturerData] = useState({});

	const [addLecturerDialog, setAddLecturerDialog] = useState(false);

	const { data, refetch: refetchLecturers } = useQuery(LECTURERS_QUERY, {
		variables: {
			role: 2,
		},
	});

	const [publicRequest] = useMutation(SWITCH_PUBLIC, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.switchPublishLecturer);
			setSnackOpen(true);
			refetchLecturers();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handlePublic = useCallback(
		(user_id, LecturerPublic) => {
			publicRequest({
				variables: {
					user_id: user_id,
					published: LecturerPublic,
				},
			});
		},
		[publicRequest]
	);

	return (
		<AdminPanel
			title="Správa lektorů"
			snackState={snackState}
			snackMsg={snackMsg}
			snackOpen={snackOpen}
			setSnackOpen={setSnackOpen}
			adminsOnly={true}
		>
			<Container maxWidth="xl" sx={{ mt: "auto", mb: "30px" }}>
				<TableContainer sx={{ height: "100%" }}>
					<Button
						variant="contained"
						color="secondary"
						sx={{
							borderRadius: "5px",
							ml: "auto",
							mr: "10px",
							display: "flex",
						}}
						onClick={() => {
							setAddLecturerDialog(true);
						}}
					>
						Přidat lektora
					</Button>
					<Table
						sx={{
							borderCollapse: "separate",
							overflow: "hidden",
							overflowY: "scroll",
							overflowX: "scroll",
						}}
						aria-label="simple table"
						size="small"
					>
						<TableHead>
							<TableRow>
								<TableCell
									align="left"
									sx={{
										width: "5%",
									}}
								></TableCell>
								<TableCell
									align="left"
									sx={{
										width: "20%",
									}}
								>
									Lektor
								</TableCell>
								<TableCell
									align="center"
									sx={{
										width: "5%",
									}}
								>
									Role
								</TableCell>
								<TableCell align="left" sx={{ width: "60%" }}>
									Popis
								</TableCell>
								<TableCell align="center" sx={{ width: "5%" }}>
									Veřejný
								</TableCell>
								<TableCell
									align="right"
									sx={{ width: "5%" }}
								></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data &&
								data.usersByRole.map((lecturer, index) => (
									<TableRow
										key={index}
										sx={{
											verticalAlign: "middle",
										}}
									>
										<TableCell
											align="left"
											sx={{
												width: "5%",
											}}
										>
											<Avatar src={lecturer.photo} />
										</TableCell>
										<TableCell align="left">
											{lecturer.last_name}{" "}
											{lecturer.first_name}
										</TableCell>
										<TableCell align="center">
											{ROLE_MAP[lecturer.role]}
										</TableCell>
										<TableCell
											align="left"
											sx={{
												whiteSpace: "nowrap",
												maxWidth: "400px",
												overflow: "hidden",
												textOverflow: "ellipsis",
												width: "65%",
											}}
										>
											{lecturer.user_description &&
												convertFromRaw(
													JSON.parse(
														lecturer.user_description
													)
												).getPlainText()}
										</TableCell>
										<TableCell
											align="center"
											sx={{ width: "5%" }}
										>
											{lecturer.published ? (
												<IconButton
													onClick={() => {
														handlePublic(
															lecturer.user_id,
															false
														);
													}}
												>
													<CheckCircleOutline
														sx={{
															color: "response.success",
														}}
													/>
												</IconButton>
											) : (
												<IconButton
													onClick={() => {
														handlePublic(
															lecturer.user_id,
															true
														);
													}}
												>
													<HighlightOff
														sx={{
															color: "response.error",
														}}
													/>
												</IconButton>
											)}
										</TableCell>
										<TableCell
											align="right"
											sx={{ width: "5%" }}
										>
											<Button
												id={`${lecturer.user_id}_setLecturerDataBtn`}
												variant="contained"
												size="small"
												onClick={() => {
													setEditLecturerDialog(true);
													setEditLecturerData(
														lecturer
													);
												}}
											>
												Upravit
											</Button>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Container>
			<EditLecturerDialog
				open={editLecturerDialog}
				setOpen={setEditLecturerDialog}
				lecturerData={editLecturerData}
				refetch={refetchLecturers}
				setSnackMsg={setSnackMsg}
				setSnackState={setSnackState}
				setSnackOpen={setSnackOpen}
			/>
			<AddLecturerDialog
				open={addLecturerDialog}
				setOpen={setAddLecturerDialog}
				refetch={refetchLecturers}
				setSnackMsg={setSnackMsg}
				setSnackState={setSnackState}
				setSnackOpen={setSnackOpen}
			/>
		</AdminPanel>
	);
}
