import PropTypes from "prop-types"
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useCallback } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { ArrowBackIos } from "@mui/icons-material";
import { AlternativeBallroomCoursesTable, AlternativeDatePickedSummary, AlternativeSeasonDatesTable } from "src/molecules";

const ACTIVE_BALLROOM_SEASONS = gql`
	query ballroomSeasons($season_id: Int!) {
		alternativeBallroomSeasons(season_id: $season_id) {
			season_id
			season
			start_date
			end_date
			day
			time
			capacity
			duration
			active
			start_time
			end_time
			women_count
			men_count
		}
	}
`;

const APPLY_FOR_ANOTHER_HOUR = gql`
	mutation applyForAlternativeReservation($user_id: Int!, $event_id: Int!, $alternative_to_season: Int!) {
		applyForAlternativeReservation(user_id: $user_id, event_id: $event_id, alternative_to_season: $alternative_to_season)
	}
`;

/**
 * Dialog step wizzard to apply for alternative event of ballroom dancing in different season.
 * @param {String} title title of the dialog
 * @param {Boolean} open opened/closed dialog
 * @param {Function} setOpen open/close dialog
 * @param {Number} season_id season id
 * @param {Number} user_id user id
 * @param {Function} setSnackState function to set snack state Success, Warning, Error, Info
 * @param {Function} setSnackMsg function to set snack msg
 * @param {Function} setSnackOpen function to open/close informational snack message
 * @returns {Component} Dialog
 */
export function AlternativeDateDialog({
	title,
	open,
	setOpen,
	season_id,
	user_id,
	setSnackState,
	setSnackOpen,
	setSnackMsg,
}) {
	const ballroomSeasonsQuery = useQuery(ACTIVE_BALLROOM_SEASONS, {
		variables: {
			season_id: parseInt(season_id),
		},
	});
	const [activeStep, setActiveStep] = useState(0);
	const [pickedSeasonID, setPickedSeasonID] = useState();
	const [pickedEventID, setPickedEventID] = useState();

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const [applyForAlternativeDateQuery] = useMutation(APPLY_FOR_ANOTHER_HOUR, {
		onCompleted: (data) => {
      setSnackState("success");
			setSnackMsg(data.applyForAlternativeReservation);
			setSnackOpen(true);
      handleClose();
    },
		onError: (error) => {
      setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
    },
	});

	const handleSubmit = useCallback(
		(user_id, pickedEventID, season_id) => {
			applyForAlternativeDateQuery({
				variables: {
					event_id: parseInt(pickedEventID),
					user_id: parseInt(user_id),
					alternative_to_season: parseInt(season_id),
				},
			});
		},
		[applyForAlternativeDateQuery]
	);

	const handleClose = useCallback(() => {
		setOpen(false);
    setPickedSeasonID()
    setPickedEventID()
    setActiveStep(0);
	}, [setOpen]);

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="lg"
			>
				<DialogTitle
					sx={{ whiteSpace: "pre-line" }}
				>
					{title}
				</DialogTitle>
				<DialogContent>
					{activeStep !== 0 && (
						<Button
							startIcon={<ArrowBackIos />}
							variant="outlined"
							color="secondary"
							size="small"
							onClick={() => {
								handleBack();
							}}
						>
							Zpět
						</Button>
					)}
					{(() => {
						switch (activeStep) {
							case 0:
								return (
									<AlternativeBallroomCoursesTable
										data={ballroomSeasonsQuery.data}
										handleNext={handleNext}
										setPickedSeasonID={setPickedSeasonID}
									/>
								);
							case 1:
								return (
									<AlternativeSeasonDatesTable
										season_id={pickedSeasonID}
										handleNext={handleNext}
										setPickedEventID={setPickedEventID}
									/>
								);
							case 2:
								return (
									<AlternativeDatePickedSummary
										pickedEventID={pickedEventID}
									/>
								);
							default:
								return null;
						}
					})()}
				</DialogContent>
				<DialogActions>
					{activeStep === 2 && (
						<Button
							onClick={() => {
								handleSubmit(user_id, pickedEventID, season_id);
							}}
							color="secondary"
							variant="contained"
						>
							Potvrdit
						</Button>
					)}
					<Button onClick={handleClose} color="secondary">
						Zrušit
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

AlternativeDateDialog.propTypes = {
	open: PropTypes.any,
	season_id: PropTypes.any,
	setOpen: PropTypes.func,
	setSnackMsg: PropTypes.func,
	setSnackOpen: PropTypes.func,
	setSnackState: PropTypes.func,
	title: PropTypes.any,
	user_id: PropTypes.any
}
