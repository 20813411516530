import PropTypes from "prop-types"
import { SvgIcon } from "@mui/material";

/**
 * Simple custom SVG for dress icon
 * @param {String} fontSize 
 * @returns {Component} SvgIcon
 */
export function DressIcon({fontSize}) {
	return (
		<SvgIcon
			width="25"
			height="25"
			viewBox="0 0 1000 1000"
			enableBackground="new 0 0 1000 1000"
			x="0px"
			y="0px"
			fill="none"
      sx={fontSize ? {fontSize: fontSize} : {fontSize: '48px'}}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M74.5,883.2c0,0,79.9,106.8,425.5,106.8s425.5-106.8,425.5-106.8L614.6,353v-31.8l49.1-182.4V10h-49.1v85.8C532.7,132.5,500,165,500,165s-32.7-32.5-114.6-69.2V10h-49.1v127.3l49.1,183.9V353L74.5,883.2z" />
		</SvgIcon>
	);
}

DressIcon.propTypes = {
	fontSize: PropTypes.any
}
