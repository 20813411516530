import PropTypes from "prop-types"
import {
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const SEASON_DATES = gql`
	query eventsBySeasonId($season_id: Int!) {
		eventsBySeasonId(season_id: $season_id) {
			capacity
			event_id
			event_start
			event_date
			event_end
			duration
			reservation_count
			comment
			season_id
		}
	}
`;

const DAY_MAP = [
  "neděle",
	"pondělí",
	"úterý",
	"středa",
	"čtvrtek",
	"pátek",
	"sobota",
];

/**
 * Table in wizzard for the user to pick alternative event of the ballroom dancing
 * @param {Number} season_id id of picked season. 
 * @param {Function} handleNext function to go to the next step of the form wizzard
 * @param {Function} setPickedEventID useState to set picked alternative ballroom dance event id
 * @returns {Component} TableContainer
 */
export function AlternativeSeasonDatesTable({
	season_id,
	handleNext,
	setPickedEventID
}) {
	const seasonDatesQuery = useQuery(SEASON_DATES, {
		variables: {
			season_id: parseInt(season_id),
		},
	});

	return (
		<TableContainer sx={{ maxHeight: "400px", pr: "20px" }}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell align="center">Datum</TableCell>
						<TableCell align="center">Začátek</TableCell>
						<TableCell align="center">Konec</TableCell>
						<TableCell align="left">Poznámka</TableCell>
						<TableCell padding="none"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{seasonDatesQuery.data &&
						seasonDatesQuery.data.eventsBySeasonId.map(
							(event, index) => (
								<TableRow key={index}>
									<TableCell align="left">
									{DAY_MAP[(new Date(parseInt(event.event_date))).getUTCDay()]}{" "}
										{event.event_start.match(
											/\d{0,2}\.\s\d{0,2}\.\s\d{0,4}/
										)}
									</TableCell>
									<TableCell align="center">
										{
											event.event_start.match(
												/\s(\d{0,2}:\d{0,2})/
											)[0]
										}
									</TableCell>
									<TableCell align="center">
										{
											event.event_end.match(
												/\s(\d{0,2}:\d{0,2})/
											)[0]
										}
									</TableCell>

									<TableCell align="left">
										{event.comment}
									</TableCell>
									<TableCell align="right" padding="none">
										<Button
											variant="contained"
											color="secondary"
											size="small"
											onClick={() => {
												handleNext();
												setPickedEventID(event.event_id)
											}}
										>
											Požádat
										</Button>
									</TableCell>
								</TableRow>
							)
						)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

AlternativeSeasonDatesTable.propTypes = {
	handleNext: PropTypes.func,
	season_id: PropTypes.any,
	setPickedEventID: PropTypes.func
}
