import PropTypes from "prop-types"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ToggleButton } from "@mui/material";
import { useField } from "formik";
import { AccountBalance, Payments } from "@mui/icons-material";

/**
 * Custom toggle buttons to choose between different text/values.
 * Text/Values mapped via json object, which is not effective for future scaling or more versatile usage, so might need a mapping rework later.
 * @param {Class} props other properties defined in Component
 * @returns {Component} ToggleButtonGroup => ToggleButtons
 */
export function ToggleButtons(props) {
	const [, meta, helpers] = useField(props.name);

	const { value } = meta;
	const { setValue } = helpers;

	const handleValueChange = (event, newValue) => {
		if (newValue !== null) {
			setValue(newValue);
			props.setGender && props.setGender(newValue);
		}
	};

	let names = {
		gender: {
			text1: "Muž",
			text2: "Žena",
			text3: "Pár",
			value1: "male",
			value2: "female",
			value3: "pair",
			icon1: null,
			icon2: null,
			icon3: null,
		},
		payment: {
			text1: "Bankovním převodem",
			text2: "Hotově",
			value1: "bank_transfer",
			value2: "cash",
			icon1: <AccountBalance />,
			icon2: <Payments />,
		},
	};

	return (
		<>
			<ToggleButtonGroup
				exclusive
				fullWidth={true}
				size="small"
				value={value}
				onChange={handleValueChange}
			>
				<ToggleButton
					sx={{
						borderRadius: "5px !important",
						mr: "1px !important",
						mt: "10px",
						px: "5px",
					}}
					value={names[props.name].value1}
				>
					{names[props.name].icon1}
					{names[props.name].icon1 !== null && <div>&nbsp;</div>}
					{names[props.name].text1}
				</ToggleButton>
				<ToggleButton
					sx={{
						borderRadius: "5px !important",
						ml: "1px !important",
						mt: "10px",
						px: "5px",
					}}
					value={names[props.name].value2}
				>
					{names[props.name].icon2}
					{names[props.name].icon2 !== null && <div>&nbsp;</div>}
					{names[props.name].text2}
				</ToggleButton>
				{props.name === "gender" && (
					<ToggleButton
						sx={{
							borderRadius: "5px !important",
							ml: "1px !important",
							mt: "10px",
							px: "5px",
						}}
						value={names[props.name].value3}
					>
						{names[props.name].icon3}
						{names[props.name].icon3 !== null && <div>&nbsp;</div>}
						{names[props.name].text3}
					</ToggleButton>
				)}
			</ToggleButtonGroup>
			{meta.touched && meta.error ? (
				<div className="error">{meta.error}</div>
			) : null}
		</>
	);
}

ToggleButtons.propTypes = {
  name: PropTypes.string,
  setGender: PropTypes.func
}
