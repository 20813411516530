import { useQuery } from "@apollo/client";
import { Box } from "@mui/system";
import gql from "graphql-tag";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BallDanceContainer, CourseContainer } from "src/organisms";

const COURSE_LIST = gql`
	query getCourses {
		courses {
			course_id
			name
			description
			cover
		}
	}
`;

/**
 * Page with list of all courses. Some are ball dance, some are dance studio.
 * The First one has listed seasons to sign in and the latter one opens dialog to buy certain amount of entries to other list of seasons.
 * @returns {Component} Page with BallDance and Course Containers
 */
export function CoursesPage() {
	let { state } = useLocation();
	const { data, loading } = useQuery(COURSE_LIST);

	useEffect(() => {
		setTimeout(() => {
			if (state && state.ref !== null && !loading) {
				const pointToScroll = document.getElementById(state.ref);
				pointToScroll.scrollIntoView();
				window.history.replaceState({}, document.title);
			}
		},300);
	}, [data, loading, state]);

	return (
		<Box>
			{data &&
				data.courses.map((course) => (
					<div key={course.course_id}>
						{course.course_id < 3 ? (
							<BallDanceContainer
								id={course.course_id}
								description={course.description}
								name={course.name}
								img={course.cover}
								courseId={course.course_id}
								key={course.course_id}
							/>
						) : (
							<CourseContainer
								id={course.course_id}
								description={course.description}
								name={course.name}
								img={course.cover}
								courseId={course.course_id}
								key={course.course_id}
							/>
						)}
					</div>
				))}
		</Box>
	);
}
