import {
	Paper,
	Typography,
	Stack,
	IconButton,
	Card,
	CardContent,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { AdminPanel } from "src/templates";
import { ArrowForwardIos, Delete } from "@mui/icons-material";
import gql from "graphql-tag";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";
import draftToHtml from "draftjs-to-html";
import DOMPurify from "dompurify";
import { AnnouncementForm } from "src/templates/forms/AnnouncementForm";
import { DeleteDialog } from "../Dialogs/DeleteDialog";

const HISTORY_LIST = gql`
	query notifications {
		notifications {
			notification_id
			subject
			date
		}
	}
`;

const SELECTED_NOTIFICATION = gql`
	query notificationById($notification_id: Int!) {
		notificationById(notification_id: $notification_id) {
			notification_id
			subject
			message
			date
			notification_group
		}
	}
`;

const DELETE_NOTIFICATION = gql`
	mutation deleteNotification($notification_id: Int!) {
		deleteNotification(notification_id: $notification_id)
	}
`;

/**
 * Announcement center page for sending e-mails to different groups and looking in history.
 * @returns {Component} Admin panel Announcement center content
 */
export function AnnouncementCenter() {
	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	const [selectedNotification, setSelectedNotification] = useState();

	const historyListQuery = useQuery(HISTORY_LIST);

	const [selectedNotificationQuery, { data }] = useLazyQuery(
		SELECTED_NOTIFICATION
	);

	const handleNotificationPick = useCallback(
		(notification_id) => {
			setSelectedNotification(notification_id);
			selectedNotificationQuery({
				variables: {
					notification_id: notification_id,
				},
			});
		},
		[selectedNotificationQuery]
	);

	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	const [deleteNotificationRequest] = useMutation(DELETE_NOTIFICATION, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.deleteNotification);
			setSnackOpen(true);
			historyListQuery.refetch();
			setTimeout(
				handleNotificationPick(
					historyListQuery.data.notifications[0].notification_id
				),
				50
			);
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const deleteArticle = useCallback(
		(notification_id) => {
			deleteNotificationRequest({
				variables: {
					notification_id: notification_id,
				},
			});
		},
		[deleteNotificationRequest]
	);

	return (
		<AdminPanel
			title="Centrum oznámení"
			snackState={snackState}
			snackMsg={snackMsg}
			snackOpen={snackOpen}
			setSnackOpen={setSnackOpen}
			adminsOnly={true}
		>
			<Box sx={{ display: "flex", flexDirection: "row" }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						width: "50%",
					}}
				>
					<Box>
						<AnnouncementForm />
					</Box>
					<Box>
						<Typography variant="h6" color="initial">
							Odeslané emaily
						</Typography>
						<Stack
							spacing={2}
							sx={{
								ml: "10px",
								overflow: "hidden",
								overflowY: "scroll",
								maxHeight: "200px",
								p: "5px",
							}}
						>
							{historyListQuery.data &&
								historyListQuery.data.notifications.map(
									(notification, index) => (
										<Paper
											key={index}
											elevation={4}
											sx={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												bgcolor:
													selectedNotification ===
														notification.notification_id &&
													"primary.main",
											}}
										>
											<Typography
												variant="subtitle1"
												color="initial"
												sx={{
													ml: "10px",
													minWidth: "90px",
												}}
											>
												{notification.date}
											</Typography>
											<Typography
												variant="body1"
												color="initial"
												sx={{ ml: "10px" }}
											>
												{notification.subject}
											</Typography>
											<IconButton
												sx={{ ml: "auto" }}
												onClick={() => {
													handleNotificationPick(
														notification.notification_id
													);
												}}
											>
												<ArrowForwardIos />
											</IconButton>
										</Paper>
									)
								)}
						</Stack>
					</Box>
				</Box>
				{data && (
					<Card sx={{ width: "45%", ml: "5%", mb: "auto" }}>
						<IconButton
							sx={{
								bgcolor: "secondary.main",
								color: "white",
								borderRadius: "5px",
								mr: "10px",
								mt: "10px",
								ml: "auto",
								display: "flex",
							}}
							size="small"
							onClick={() => {
								setOpenDeleteDialog(true);
							}}
						>
							<Delete />
						</IconButton>

						<CardContent>
							<Typography variant="h5" color="initial">
								{data.notificationById.subject}
							</Typography>
							<Typography variant="subtitle1" color="initial">
								{data.notificationById.date}
							</Typography>
							<Typography variant="subtitle1" color="initial">
								Odesláno skupině:{" "}
								{data.notificationById.notification_group}
							</Typography>
							<Typography
								variant="body1"
								color="initial"
								dangerouslySetInnerHTML={createMarkup(
									draftToHtml(
										JSON.parse(
											data.notificationById.message
										)
									)
								)}
							></Typography>
						</CardContent>
					</Card>
				)}
			</Box>
			{data && (
				<DeleteDialog
					open={openDeleteDialog}
					setOpen={setOpenDeleteDialog}
					id={selectedNotification}
					deleteFunction={() => {
						deleteArticle(data.notificationById.notification_id);
					}}
				/>
			)}
		</AdminPanel>
	);
}
