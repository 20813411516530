import { gql, useMutation } from "@apollo/client";
import { Box, Button } from "@mui/material";
import { convertToRaw, EditorState } from "draft-js";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useCallback } from "react";
import { ArticleFormFields, ImageUploader, SnackBarHandler } from "src/atoms";

const ADD_ARTICLE = gql`
	mutation newArticle(
		$date: String
		$title: String!
		$article: String!
		$cover: String
	) {
		addArticle(date: $date, title: $title, article: $article, cover: $cover)
	}
`;

/**
 * Form to create new article
 * @returns {Component} Form
 */
export function NewArticleForm() {
	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const [newArticleDate, setNewArticleDate] = useState(null);
	const [newArticleTitle, setNewArticleTitle] = useState("");
	const [cover, setCover] = useState("");
	const [newArticle, setNewArticle] = useState(() =>
		EditorState.createEmpty()
	);

	const initialValues = {
		newArticleDate: newArticleDate,
		newArticle: newArticle,
		newArticleTitle: newArticleTitle,
		cover: cover,
	};

	const [addNewArticleRequest] = useMutation(ADD_ARTICLE, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.addArticle);
			setSnackOpen(true);
			window.location.reload();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleNewArticleSubmit = useCallback(
		(values) => {
			addNewArticleRequest({
				variables: {
					date: newArticleDate
						? `${newArticleDate.getFullYear()}-${
								newArticleDate.getMonth() + 1
						  }-${newArticleDate.getDate()}`
						: null,
					title: values.newArticleTitle,
					article: JSON.stringify(
						convertToRaw(newArticle.getCurrentContent())
					),
					cover: cover,
				},
			});
		},
		[addNewArticleRequest, cover, newArticle, newArticleDate]
	);

	return (
		<>
			<Formik
				onSubmit={handleNewArticleSubmit}
				initialValues={initialValues}
				validateOnBlur={false}
				enableReinitialize={true}
			>
				<Form>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<ImageUploader
							id="article-cover-uploader"
							name="cover"
							setImage={setCover}
						/>
						<ArticleFormFields
							setNewArticle={setNewArticle}
							setNewArticleTitle={setNewArticleTitle}
							setNewArticleDate={setNewArticleDate}
							newArticleTitle={newArticleTitle}
							newArticleDate={newArticleDate}
							newArticle={newArticle}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							ml: "auto",
						}}
					>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							sx={{ml: 'auto'}}
						>
							Uložit
						</Button>
					</Box>
				</Form>
			</Formik>

			<SnackBarHandler
				snackState={snackState}
				snackMsg={snackMsg}
				snackOpen={snackOpen}
				setSnackOpen={setSnackOpen}
			/>
		</>
	);
}
