import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { PropTypes } from "prop-types";
import { useNavigate } from "react-router-dom";

/**
 * 
 * Button in administration navigation
 * @public
 * @param {String} item identifier and location. If undefined function as open/close list trigger.
 * @param {Object} children to add any other parts of component inside, like text or icons
 * @param {Object} props other properties defined in component like selected, sx, etc.
 * @returns {Component} ListItemButton
 */

export function AdminMenuItem({ item, children, ...props }) {
	const navigate = useNavigate();

	return (
		<>
			{item ? (
				<ListItemButton
					selected={props.selected}
					sx={props.sx}
					onClick={() => {
						navigate(`/${item}`);
					}}
				>
					<ListItemIcon>{props.icon}</ListItemIcon>
					<ListItemText primary={props.primary} />
					{children}
				</ListItemButton>
			) : (
				<ListItemButton
					selected={props.selected}
					sx={props.sx}
					onClick={props.onClick}
				>
					<ListItemIcon>{props.icon}</ListItemIcon>
					<ListItemText primary={props.primary} />
					{children}
				</ListItemButton>
			)}
		</>
	);
}

AdminMenuItem.displayName = 'AdminMenuItem';

AdminMenuItem.propTypes = {
	/**
	 * identifier and location. If undefined function as open/close list trigger.
	 */
	item: PropTypes.string,
	/**
	 * to add any other parts of component inside, like text or icons
	 */
	children: PropTypes.element,
	/**
	 * other properties defined in component like selected, sx, etc.
	 */
	props: PropTypes.object
}