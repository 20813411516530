import { useCallback, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Box, Container } from '@mui/material';
import { RenewPasswordMailForm } from './forms/RenewPasswordMailForm';

const RENEW_PASSWORD_MUTATION = gql`
  mutation renewPassword($email: String!) {
    renewPassword(email: $email) {
      email
    }
  }
`;

/**
 * template for forms to send e-mail for password change 
 * @returns {Component} Form
 */
export function RenewPasswordMailTemplate() {
  const [successMessage, setSuccessMessage] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);

  const [renewPasswordRequest, renewPasswordRequestState] = useMutation(
    RENEW_PASSWORD_MUTATION,
    {
      onCompleted: () => {
        setSuccessMessage('Odkaz pro změnu hesla byl odeslán na Váš e-mail.');
      },
      onError: (error) => {
        setErrorMessage(error);
      },
    },
  );

  const handleRenewPasswordFormSubmit = useCallback(
    (formVariables) => {
      renewPasswordRequest({
        variables: {
          email: formVariables.email,
        },
      });
    },
    [renewPasswordRequest],
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RenewPasswordMailForm
          isLoading={renewPasswordRequestState.loading}
          errorMessage={
            renewPasswordRequestState.error &&
            errorMessage
          }
          successMessage={successMessage}
          onSubmit={handleRenewPasswordFormSubmit}
        />
      </Container>
    </Box>
  );
}
